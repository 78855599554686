import { useEffect, useRef } from "react";

/**
 * Returns the previous value of a value. Useful for comparing the previous and current value of a prop or state.
 * @param value The value to store in the ref.
 * @returns The previous value of the ref.
 */
export const usePrevious = <T>(value: T) => {
    const ref = useRef<T>();
    // Uwc-debug
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};
