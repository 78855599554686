import type { BrandVariants } from "@fluentui/react-components";

/**
 * Color ramp to be used for the brand color.
 */
export const brandColorRamp: BrandVariants = {
    "10": "#000A0F",
    "20": "#03222F",
    "30": "#04374D",
    "40": "#014A6A",
    "50": "#03628A",
    "60": "#0878A8",
    "70": "#0A8DC5",
    "80": "#0FA5E6",
    "90": "#14B8FF",
    "100": "#2DC0FF",
    "110": "#4AC8FF",
    "120": "#6DD3FF",
    "130": "#8DDDFF",
    "140": "#A8E5FF",
    "150": "#C8EEFF",
    "160": "#E9F8FF"
};
