import { z } from "zod";
import { DateTimeOffsetSchema } from "./DateTimeOffset";

/**
 * Schema for the metadata object
 */
export const MetadataSchema = z.object({
    /**
     * The date the entity was created.
     */
    createdAt: DateTimeOffsetSchema,

    /**
     * The date the entity was last modified.
     */
    modifiedAt: DateTimeOffsetSchema,

    /**
     * The id of the user who created the entity.
     */
    createdBy: z.string().uuid()
});
