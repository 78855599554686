// Export { UserAvatarGroupContainer as AvatarGroupContainer } from "./UserAvatarGroupContainer";
// export { UserAvatarGroupRenderer as AvatarGroup } from "./UserAvatarGroupRenderer";

import loadable from "@loadable/component";

export const AvatarGroupContainer = loadable(
    () => import(/* webpackChunkName: "AvatarGroupContainer" */ "./UserAvatarGroupContainer"),
    {
        resolveComponent: (components) => components.UserAvatarGroupContainer
    }
);

export const AvatarGroup = loadable(() => import(/* webpackChunkName: "AvatarGroup" */ "./UserAvatarGroupRenderer"), {
    resolveComponent: (components) => components.UserAvatarGroupRenderer
});
