import { IMAGE_RETRIEVAL_TIMEOUT } from "@weddinggram/common";
import { NOT_FOUND_EXCEPTION_NAME } from "@weddinggram/exceptions";
import { unknownToError } from "@weddinggram/exceptions/utilities";
import { useTranslation } from "@weddinggram/i18n";
import { getMediaType } from "@weddinggram/model/utilities";
import { Logger } from "@weddinggram/telemetry-core";
import { useAppInsights, useAppInsightsPerformance } from "@weddinggram/telemetry-react";
import { useErrorHandler } from "@weddinggram/ui-error-handling";
import * as React from "react";
import { usePrevious } from "./usePrevious";
import { useServiceFactory } from "./useServiceFactory";

type StreamUrlFromApiHookData = {
    /**
     * The URL that can be used to stream the video
     */
    mediaData: string | undefined;

    /**
     * Flag indicating if the url request is currently loading.
     */
    isLoading: boolean;

    /**
     * Flag indicating if an error occurred while getting the url.
     */
    hasError: boolean;

    /**
     * Aborts the current url retrieval.
     */
    abort: (reason: string) => void;
};

/**
 * Hook to retrieve a streaming url from the API.
 * @param streamRequestUrl The url to request the streaming url with.
 * @returns An {@link StreamUrlFromApiHookData|object} containing the image and status of retrieval.
 */
export const useStreamingUrl = (
    streamRequestUrl: string | undefined | null,
    shouldLoadNow = true
): StreamUrlFromApiHookData => {
    const factory = useServiceFactory();
    const { t } = useTranslation("common");

    const COULD_NOT_LOAD_ERROR_MESSAGE = t("image.notFound.alt");

    // Store image url as a ref so that we can compare if it changed
    const previousStreamUrl = usePrevious(streamRequestUrl);

    const { trackEvent } = useAppInsights();
    const { start: startDownloadMeasurement } = useAppInsightsPerformance("perf/video/stream/generate", {
        url: streamRequestUrl
    });
    const [hasError, setHasError] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [streamUrl, setStreamUrl] = React.useState<string | undefined>(undefined);
    const retryCountRef = React.useRef<number>(0);
    const abortController = React.useRef<AbortController | undefined>(undefined);
    const handleErrorInUi = useErrorHandler();

    const streamUrlDefined = streamRequestUrl !== undefined && streamRequestUrl !== null;
    const notLoading = !isLoading;
    const noError = !hasError;
    const imageNotLoaded = !streamUrl;
    const notSameUrl = streamRequestUrl !== previousStreamUrl;
    const shouldRequestUrl =
        (imageNotLoaded || notSameUrl) && notLoading && noError && streamUrlDefined && shouldLoadNow;

    const abort = React.useCallback((reason: string) => {
        Logger.warn("aborting URL request", { reason });
        abortController.current?.abort(reason);
    }, []);

    const retryFallback = React.useCallback(
        async (abortSignal: AbortSignal) => {
            let timedOut = false;
            const timeoutId = setTimeout(() => {
                timedOut = true;
                abort(`fallback timeout after ${IMAGE_RETRIEVAL_TIMEOUT}ms`);
                setHasError(true);
                setStreamUrl(undefined);
                setIsLoading(false);
                trackEvent("video/stream/generate/cancel");
            }, IMAGE_RETRIEVAL_TIMEOUT);

            if (retryCountRef.current > 0 || !streamRequestUrl) {
                return;
            }

            try {
                retryCountRef.current++;
                const endMeasurement = startDownloadMeasurement();
                const image = await factory.mediaService.getStreamingUrl(streamRequestUrl, abortSignal);
                if (!timedOut) {
                    endMeasurement();
                    clearTimeout(timeoutId);
                    setStreamUrl(image);
                    setHasError(false);
                }
            } catch (error) {
                clearTimeout(timeoutId);
                handleErrorInUi(COULD_NOT_LOAD_ERROR_MESSAGE, error, "error", { url: streamRequestUrl });
                setHasError(true);
            } finally {
                setIsLoading(false);
            }
        },
        [
            streamRequestUrl,
            factory,
            abort,
            trackEvent,
            startDownloadMeasurement,
            COULD_NOT_LOAD_ERROR_MESSAGE,
            handleErrorInUi
        ]
    );

    React.useEffect(() => {
        const retrieveImageAsync = async () => {
            if (!shouldRequestUrl) {
                return;
            }

            // Do not load media if the url contains a image extension
            if (getMediaType(streamRequestUrl) === "Image") {
                setIsLoading(false);
                setHasError(false);
                setStreamUrl(undefined);
                return;
            }

            let timedOut = false;
            const timeoutId = setTimeout(() => {
                timedOut = true;
                abort(`full timeout after ${IMAGE_RETRIEVAL_TIMEOUT}ms URL: ${streamRequestUrl}`);
                setHasError(true);
                setStreamUrl(undefined);
                setIsLoading(false);
                trackEvent("image/download/cancel");
            }, IMAGE_RETRIEVAL_TIMEOUT);

            abortController.current = new AbortController();
            setIsLoading(true);
            try {
                const endMeasurement = startDownloadMeasurement();
                const image = await factory.mediaService.getStreamingUrl(
                    streamRequestUrl,
                    abortController.current.signal
                );
                if (!timedOut) {
                    clearTimeout(timeoutId);
                    endMeasurement();
                    setStreamUrl(image);
                    setHasError(false);
                }
            } catch (error) {
                clearTimeout(timeoutId);

                const errorObj = unknownToError(error);
                if (errorObj.name === NOT_FOUND_EXCEPTION_NAME) {
                    await retryFallback(abortController.current.signal);
                } else {
                    handleErrorInUi(COULD_NOT_LOAD_ERROR_MESSAGE, error, "warning", { url: streamRequestUrl });
                    setHasError(true);
                }
            } finally {
                setIsLoading(false);
            }
        };
        retrieveImageAsync();
    }, [
        shouldRequestUrl,
        streamRequestUrl,
        factory,
        streamUrl,
        isLoading,
        abort,
        trackEvent,
        startDownloadMeasurement,
        retryFallback,
        COULD_NOT_LOAD_ERROR_MESSAGE,
        handleErrorInUi
    ]);

    return React.useMemo(
        () => ({
            mediaData: streamUrl,
            isLoading,
            hasError,
            abort
        }),
        [streamUrl, isLoading, hasError, abort]
    );
};
