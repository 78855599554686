import { z } from "zod";
import { BaseEntitySchema } from "./BaseEntity";
import { DateTimeOffsetSchema } from "./DateTimeOffset";

/**
 * Represents a media definition for an media post based on {@link MediaDefinitionSchema}
 */
export const MediaDefinitionSchema = BaseEntitySchema.extend({
    /**
     * Url to the image or video in full resolution
     */
    fullUrl: z.string().url(),

    /**
     * Url to the image or video in reduced resolution
     */
    thumbUrl: z.string().url(),

    /**
     * The width of the image in pixels
     */
    width: z.number().int().nonnegative(),

    /**
     * The height of the image in pixels
     */
    height: z.number().int().nonnegative(),

    /**
     * The time the image was created (e.g when was the picture taken)
     */
    creationDate: DateTimeOffsetSchema,

    /**
     * The media type of the file (Image or Video)
     */
    mediaType: z.enum(["Image", "Video"])
});
