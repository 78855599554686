import { z } from "zod";

import { BaseEntitySchema } from "./BaseEntity";
import { DateTimeOffsetSchema } from "./DateTimeOffset";
import { RgbaSchema } from "./RgbaSchema";
import { TranslatedStringSchema } from "./TranslatedString";
import { MinimalUserSchema } from "./User";

/**
 * Represents a wedding invitation.
 */
export const InvitationSchema = BaseEntitySchema.extend({
    /**
     * The id of the wedding this invitation is for.
     */
    weddingId: z.string().uuid(),

    /**
     * Until when the user can reply to the invitation.
     */
    replyUntil: DateTimeOffsetSchema.nullable(),

    /**
     * The primary RGBA color of the invitation card.
     * @example "#FFF000F"
     */
    primaryCardColor: RgbaSchema.nullable(),

    /**
     * The secondary RGBA color of the invitation card.
     * @example "#FFF000F"
     */
    secondaryCardColor: RgbaSchema.nullable(),

    /**
     * The tertiary RGBA color of the invitation card.
     * @example "#FFF000F"
     */
    tertiaryCardColor: RgbaSchema.nullable(),

    /**
     * The name of the host that appears first on the invitation.
     */
    firstHostName: z.string(),

    /**
     * The name of the host that appears second on the invitation.
     */
    secondHostName: z.string(),

    /**
     * The content of the header of the invitation.
     */
    headerTitles: z.array(TranslatedStringSchema),

    /**
     * Custom message that appears when the guest accepts the invitation.
     */
    customAcceptMessage: z.string().nullable(),

    /**
     * Custom message that appears when the guest declines the invitation.
     */
    customDeclineMessage: z.string().nullable(),

    /**
     * The ISO date of the wedding.
     */
    weddingDate: DateTimeOffsetSchema.nullable(),

    /**
     * The location of the wedding.
     */
    weddingLocation: z.string().nullable(),

    /**
     * The address to the header image of the wedding.
     */
    weddingHeaderAddress: z.string().url().nullable(),

    /**
     * The address to the header image thumb of the wedding.
     */
    weddingHeaderThumbAddress: z.string().url().nullable(),

    /**
     * Array of the owners of the wedding.
     */
    owners: z.array(MinimalUserSchema)
});
