import type { IPublicClientApplication } from "@azure/msal-browser";
import {
    AgendaSchema,
    AttendanceSchema,
    GuestTagSchema,
    ImagePostSchema,
    InvitationSchema,
    MinimalUserSchema,
    StreamUrlSchema,
    UserSchema,
    WeddingSchema
} from "@weddinggram/model/schemas";

import type {
    IAgendaItemService,
    IAgendaService,
    IAttendanceService,
    ICurrentUserService,
    IGuestTagService,
    IImageLikeService,
    IImagePostService,
    IInvitationService,
    IMediaService,
    IQrCodeService,
    IUserService,
    IWeddingService
} from "@weddinggram/service";
import type { IAuthenticationService } from "@weddinggram/service/src/auth/IAuthenticationService";

import { MsalAuthenticationService } from "@weddinggram/service/src/auth/MsalAuthenticationService";

import type { ApiCacheOptions } from "@weddinggram/service/src/cache";
import { LocalStorageCacheService, defaultCacheOptions } from "@weddinggram/service/src/cache";
import type { IApplicationInsights } from "@weddinggram/telemetry-core";
import { Logger, SeverityLevel } from "@weddinggram/telemetry-core";
import type { AxiosInstance } from "axios";
import axios from "axios";
import { z } from "zod";

type ServiceFactoryServiceDependencies =
    | MockedServiceFactoryServiceDependencies
    | RealServiceFactoryServiceDependencies;

type MockedServiceFactoryServiceDependencies = {
    axios?: never;
    authenticationService?: IAuthenticationService;
    appInsights?: never;
    environment: "mock";
};

type RealServiceFactoryServiceDependencies = {
    axios: AxiosInstance;
    authenticationService: IAuthenticationService;
    appInsights: IApplicationInsights;
    environment: "devLocal" | "prodLocal" | "devProd" | "prodProd";
};

/**
 * Represents a switch between the different authentication services.
 */
type AuthenticationServiceSwitch =
    | MsalAuthenticationServiceSwitch
    | RedemptionCodeAuthenticationServiceSwitch
    | AnonAuthenticationServiceSwitch;

/**
 * Represents a switch to the msal authentication service.
 */
type MsalAuthenticationServiceSwitch = {
    type: "msal";
    redemptionCode?: never;
};

/**
 * Represents a switch to the redemption code authentication service.
 */
type RedemptionCodeAuthenticationServiceSwitch = {
    type: "redemptionCode";
    redemptionCode: string;
};

type AnonAuthenticationServiceSwitch = {
    type: "anon";
    redemptionCode?: never;
};

export class ServiceFactory {
    private readonly cacheOptions: ApiCacheOptions;
    private readonly dependencies: ServiceFactoryServiceDependencies;

    private _agendaItemService?: IAgendaItemService;
    private _imageLikeService?: IImageLikeService;
    private _imagePostService?: IImagePostService;
    private _agendaService?: IAgendaService;
    private _userService?: IUserService;
    private _currentUserService?: ICurrentUserService;
    private _weddingService?: IWeddingService;
    private _invitationService?: IInvitationService;
    private _attendanceService?: IAttendanceService;
    private _mediaService?: IMediaService;
    private _qrCodeService?: IQrCodeService;
    private _guestTagService?: IGuestTagService;

    private readonly logger = Logger.create("ServiceFactory");

    public get isInitialized(): boolean {
        return (
            Boolean(this._agendaItemService) &&
            Boolean(this._imageLikeService) &&
            Boolean(this._imagePostService) &&
            Boolean(this._agendaService) &&
            Boolean(this._userService) &&
            Boolean(this._currentUserService) &&
            Boolean(this._weddingService) &&
            Boolean(this._mediaService) &&
            Boolean(this._invitationService) &&
            Boolean(this._attendanceService) &&
            Boolean(this._qrCodeService) &&
            Boolean(this._guestTagService)
        );
    }

    /**
     *
     * @param appEnvironmentType {@link AppEnvironmentType|type} of the application environment (mock, devLocal, prodLocal, devProd, prodProd)
     * @param baseUrl The base url of the API
     * @param scopes The scopes to use for the authentication
     * @param msalClient Instance of {@link IPublicClientApplication|msalClient} to use for the authentication. Can be `null` for `AppEnvironmentType.mock`.
     * @param appInsights Instance of {@link IApplicationInsights|appInsights} to use for the telemetry. Can be `null` for `AppEnvironmentType.mock`.
     * @param cacheOptions Optional {@link ApiCacheOptions|cache settings}.
     * @param serviceDelay Optional delay to add to each service call. Useful for testing. Only works for `AppEnvironmentType.mock`.
     */
    constructor(
        private readonly baseUrl: string,
        private readonly scopes: string[],
        private readonly msalClient: IPublicClientApplication | null,
        appInsights: IApplicationInsights | null,
        cacheOptions?: Partial<ApiCacheOptions>,
        private readonly serviceDelay?: number
    ) {
        this.cacheOptions = { ...defaultCacheOptions, ...cacheOptions };

        if (APP_ENV === "mock") {
            this.dependencies = {
                environment: "mock",
                authenticationService:
                    (msalClient && appInsights && new MsalAuthenticationService(msalClient, appInsights)) || undefined
            };
        } else {
            if (!msalClient) {
                this.logger.error("msalClient is required when not in mock mode");
                throw new Error("msalClient is required");
            }

            if (!appInsights) {
                this.logger.error("appInsights is required when not in mock mode");
                throw new Error("appInsights is required");
            }

            this.dependencies = {
                environment: APP_ENV,
                axios: axios.create({
                    baseURL: this.baseUrl
                }),
                appInsights: appInsights,
                authenticationService: new MsalAuthenticationService(msalClient, appInsights)
            };

            appInsights.trackTrace({
                message: "ServiceFactory created",
                properties: { environment: APP_ENV },
                severityLevel: SeverityLevel.Verbose
            });
        }
    }

    /**
     * Switches the authentication service to the given type and re-initializes the services.
     * @param to Type and parameters of the authentication service to switch to.
     * @returns Promise
     */
    public async switchAuthService(to: AuthenticationServiceSwitch): Promise<void> {
        this.logger.log("Switching auth service", { to });
        if (this.dependencies.environment === "mock") {
            return;
        }

        switch (to.type) {
            case "msal": {
                if (!this.msalClient) {
                    this.logger.error("msalClient is required when switching to msal auth service");
                    throw new Error("msalClient is required");
                }
                this.dependencies.authenticationService = new MsalAuthenticationService(
                    this.msalClient,
                    this.dependencies.appInsights
                );
                break;
            }
            case "redemptionCode": {
                const authServiceModule = await import(
                    /* WebpackChunkName: "RedemptionCodeAuthenticationService" */ "@weddinggram/service/src/auth/RedemptionCodeAuthenticationService"
                );
                this.dependencies.authenticationService = new authServiceModule.RedemptionCodeAuthenticationService(
                    to.redemptionCode
                );
                break;
            }
            case "anon": {
                const authServiceModule = await import(
                    /* WebpackChunkName: "AnonAuthenticationService" */ "@weddinggram/service/src/auth/AnonAuthenticationService"
                );
                this.dependencies.authenticationService = new authServiceModule.AnonAuthenticationService();
                break;
            }
            default: {
                this.logger.error("Unknown auth service type", { to });
                throw new Error("Unknown auth service type");
            }
        }

        await this.initialize(true);
    }

    /**
     * Initializes the services.
     * @param forceInitialization If true the services will be re-initialized even if they are already initialized.
     */
    public async initialize(forceInitialization?: boolean): Promise<void> {
        await this.dependencies.authenticationService?.initialize();

        if (IS_MOCK) {
            await this.initializeMockServices();
        } else {
            await this.initializeServices(forceInitialization);
        }
    }

    /**
     * Initializes the real services.
     * @param forceInitialization If true the services will be re-initialized even if they are already initialized.
     * @returns Promise
     */
    private async initializeServices(forceInitialization?: boolean): Promise<void> {
        if (this.dependencies.environment === "mock") {
            return;
        }

        // Stop if already initialized and not forced
        if (this.isInitialized && !forceInitialization) {
            return;
        }

        const [
            AgendaItemServiceModule,
            AgendaServiceModule,
            ImageLikeServiceModule,
            ImagePostServiceModule,
            CurrentUserServiceModule,
            UserServiceModule,
            WeddingServiceModule,
            WeddingInvitationService,
            AttendanceServiceModule,
            MediaServiceModule,
            MediaStreamServiceModule,
            QrCodeServiceModule,
            GuestTagServiceModule
        ] = await Promise.all([
            import(/* webpackChunkName: "AgendaItemService" */ "@weddinggram/service/src/api/agenda/AgendaItemService"),
            import(/* webpackChunkName: "AgendaService" */ "@weddinggram/service/src/api/agenda/AgendaService"),
            import(/* webpackChunkName: "ImageLikeService" */ "@weddinggram/service/src/api/likes/ImageLikeService"),
            import(/* webpackChunkName: "ImagePostService" */ "@weddinggram/service/src/api/posts/ImagePostService"),
            import(/* webpackChunkName: "CurrentUserService" */ "@weddinggram/service/src/api/user/CurrentUserService"),
            import(/* webpackChunkName: "UserService" */ "@weddinggram/service/src/api/user/UserService"),
            import(/* webpackChunkName: "WeddingService" */ "@weddinggram/service/src/api/weddings/WeddingService"),
            import(
                /* WebpackChunkName: "WeddingInvitationService" */ "@weddinggram/service/src/api/weddings/InvitationService"
            ),
            import(
                /* WebpackChunkName: "AttendanceService" */ "@weddinggram/service/src/api/attendance/AttendanceService"
            ),
            import(/* webpackChunkName: "MediaService" */ "@weddinggram/service/src/api/media/MediaService"),
            import(
                /* WebpackChunkName: "MediaStreamService" */ "@weddinggram/service/src/api/media/MediaStreamService"
            ),
            import(/* webpackChunkName: "QrCodeService" */ "@weddinggram/service/src/api/qr/QrCodeService"),
            import(/* webpackChunkName: "TagService" */ "@weddinggram/service/src/api/tags/GuestTagService")
        ]);

        this._agendaItemService = new AgendaItemServiceModule.AgendaItemService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(AgendaSchema, this.cacheOptions.agendaKey, this.cacheOptions.agendaExpiration),
            this.dependencies.appInsights
        );

        this._agendaService = new AgendaServiceModule.AgendaService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(AgendaSchema, this.cacheOptions.agendaKey, this.cacheOptions.agendaExpiration),
            this.dependencies.appInsights
        );

        this._imageLikeService = new ImageLikeServiceModule.ImageLikeService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(
                ImagePostSchema,
                this.cacheOptions.imagePostKey,
                this.cacheOptions.imagePostExpiration
            ),
            this.dependencies.appInsights
        );

        this._imagePostService = new ImagePostServiceModule.ImagePostService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(
                ImagePostSchema,
                this.cacheOptions.imagePostKey,
                this.cacheOptions.imagePostExpiration
            ),
            this.dependencies.appInsights
        );

        this._userService = new UserServiceModule.UserService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(
                MinimalUserSchema,
                this.cacheOptions.otherUserKey,
                this.cacheOptions.otherUserExpiration
            ),
            this.dependencies.appInsights
        );

        this._currentUserService = new CurrentUserServiceModule.CurrentUserService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(UserSchema, this.cacheOptions.userKey, this.cacheOptions.userExpiration),
            this.dependencies.appInsights
        );

        this._weddingService = new WeddingServiceModule.WeddingService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(
                WeddingSchema,
                this.cacheOptions.weddingKey,
                this.cacheOptions.weddingExpiration
            ),
            this.dependencies.appInsights
        );

        this._invitationService = new WeddingInvitationService.InvitationService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(
                InvitationSchema,
                this.cacheOptions.weddingKey,
                this.cacheOptions.weddingExpiration
            ),
            this.dependencies.appInsights
        );

        this._attendanceService = new AttendanceServiceModule.AttendanceService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(
                AttendanceSchema,
                this.cacheOptions.weddingKey,
                this.cacheOptions.weddingExpiration
            ),
            this.dependencies.appInsights
        );

        const mediaStreamService = new MediaStreamServiceModule.MediaStreamService(
            axios.create(), // We need a new instance of axios here because the base url can't be defined as it comes from the URLs of the media.
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(
                StreamUrlSchema,
                this.cacheOptions.mediaStreamKey,
                this.cacheOptions.mediaStreamExpiration
            ),
            this.dependencies.appInsights
        );

        this._mediaService = new MediaServiceModule.MediaService(
            axios.create(), // We need a new instance of axios here because the base url can't be defined as it comes from the URLs of the media.
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(
                z.string().startsWith("data", "image doesn't start with data"),
                this.cacheOptions.mediaKey,
                this.cacheOptions.mediaExpiration
            ),
            this.dependencies.appInsights,
            mediaStreamService
        );
        this._qrCodeService = new QrCodeServiceModule.QrCodeService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            this.dependencies.appInsights
        );
        this._guestTagService = new GuestTagServiceModule.GuestTagService(
            this.dependencies.axios,
            this.baseUrl,
            this.scopes,
            this.dependencies.authenticationService,
            new LocalStorageCacheService(GuestTagSchema, this.cacheOptions.tagKey, this.cacheOptions.tagExpiration),
            this.dependencies.appInsights
        );
        this.logger.log("Services initialized");
    }

    private async initializeMockServices(): Promise<void> {
        this.logger.log("[MOCK] Starting to initialize mock services", {
            factoryInitialized: this.isInitialized,
            delay: this.serviceDelay
        });

        // Stop if already initialized
        if (this.isInitialized) {
            this.logger.warn("[MOCK] Mock services already initialized");
            return;
        }

        if (IS_MOCK) {
            const [
                AgendaItemServiceModule,
                AgendaServiceModule,
                ImageLikeServiceModule,
                ImagePostServiceModule,
                UserServiceModule,
                WeddingServiceModule,
                InvitationServiceModule,
                AttendanceServiceModule,
                MediaServiceModule,
                QrCodeServiceModule,
                cacheExpirationTimeModule,
                GuestTagServiceModule
            ] = await Promise.all([
                import(
                    /* WebpackChunkName: "MockAgendaItemService" */ "@weddinggram/service-mocks/src/api/agenda/MockAgendaItemService"
                ),
                import(
                    /* WebpackChunkName: "MockAgendaService" */ "@weddinggram/service-mocks/src/api/agenda/MockAgendaService"
                ),
                import(
                    /* WebpackChunkName: "MockImageLikeService" */ "@weddinggram/service-mocks/src/api/likes/MockImageLikeService"
                ),
                import(
                    /* WebpackChunkName: "MockImagePostService" */ "@weddinggram/service-mocks/src/api/posts/MockImagePostService"
                ),
                import(
                    /* WebpackChunkName: "MockUserService" */ "@weddinggram/service-mocks/src/api/user/MockUserService"
                ),
                import(
                    /* WebpackChunkName: "MockWeddingService" */ "@weddinggram/service-mocks/src/api/weddings/MockWeddingService"
                ),
                import(
                    /* WebpackChunkName: "MockInvitationService" */ "@weddinggram/service-mocks/src/api/weddings/MockInvitationService"
                ),
                import(
                    /* WebpackChunkName: "MockAttendanceService" */ "@weddinggram/service-mocks/src/api/attendance/MockAttendanceService"
                ),
                import(
                    /* WebpackChunkName: "MockMediaService" */ "@weddinggram/service-mocks/src/api/media/MockMediaService"
                ),
                import(
                    /* WebpackChunkName: "MockQrCodeService" */ "@weddinggram/service-mocks/src/api/qr/MockQrCodeService"
                ),
                import(
                    /* WebpackChunkName: "mockUserCacheExpirationTime" */ "@weddinggram/service-mocks/src/api/user/mockUserCacheExpirationTime"
                ),
                import(
                    /* WebpackChunkName: "MockGuestTagService" */ "@weddinggram/service-mocks/src/api/tags/MockGuestTagService"
                )
            ]);

            const userServiceMock = new UserServiceModule.MockUserService(
                this.dependencies.authenticationService,
                this.scopes,
                new LocalStorageCacheService(
                    UserSchema,
                    this.cacheOptions.userKey,
                    cacheExpirationTimeModule.MockUserCacheExpirationTime
                ),
                this.serviceDelay
            );

            this._userService = userServiceMock;
            this._currentUserService = userServiceMock;

            const mockAgendaService = new AgendaServiceModule.MockAgendaService(
                this._currentUserService,
                this.serviceDelay
            );
            this._agendaService = mockAgendaService;
            this._agendaItemService = new AgendaItemServiceModule.MockAgendaItemService(
                mockAgendaService,
                this._currentUserService,
                this.serviceDelay
            );

            const mediaService = new MediaServiceModule.MockMediaService(this.serviceDelay);
            this._mediaService = mediaService;

            const imagePostService = new ImagePostServiceModule.MockImagePostService(
                this._currentUserService,
                mediaService,
                this.serviceDelay
            );
            this._imagePostService = imagePostService;
            this._imageLikeService = new ImageLikeServiceModule.MockImageLikeService(
                imagePostService,
                this._currentUserService
            );

            const weddingService = new WeddingServiceModule.MockWeddingService(
                userServiceMock,
                mockAgendaService,
                this.serviceDelay
            );
            this._weddingService = weddingService;
            this._invitationService = new InvitationServiceModule.MockInvitationService(
                userServiceMock,
                weddingService,
                this.serviceDelay
            );
            this._attendanceService = new AttendanceServiceModule.MockAttendanceService(this.serviceDelay);
            this._qrCodeService = new QrCodeServiceModule.MockQrCodeService(this.serviceDelay);
            this._guestTagService = new GuestTagServiceModule.MockGuestTagService(
                this.serviceDelay,
                this._currentUserService
            );
        }
    }

    public get agendaItemService(): IAgendaItemService {
        if (!this._agendaItemService) {
            this.logger.error("AgendaItemService not initialized. Did you forget to call initialize?");
            throw new Error("AgendaItemService not initialized. Did you forget to call initialize?");
        }

        return this._agendaItemService;
    }

    public get agendaService(): IAgendaService {
        if (!this._agendaService) {
            this.logger.error("AgendaService not initialized. Did you forget to call initialize?");
            throw new Error("AgendaService not initialized. Did you forget to call initialize?");
        }
        return this._agendaService;
    }

    public get imageLikeService(): IImageLikeService {
        if (!this._imageLikeService) {
            this.logger.error("ImageLikeService not initialized. Did you forget to call initialize?");
            throw new Error("ImageLikeService not initialized. Did you forget to call initialize?");
        }

        return this._imageLikeService;
    }

    public get imagePostService(): IImagePostService {
        if (!this._imagePostService) {
            this.logger.error("ImagePostService not initialized. Did you forget to call initialize?");
            throw new Error("ImagePostService not initialized. Did you forget to call initialize?");
        }

        return this._imagePostService;
    }

    public get userService(): IUserService {
        if (!this._userService) {
            this.logger.error("UserService not initialized. Did you forget to call initialize?");
            throw new Error("UserService not initialized. Did you forget to call initialize?");
        }
        return this._userService;
    }

    public get currentUserService(): ICurrentUserService {
        if (!this._currentUserService) {
            this.logger.error("CurrentUserService not initialized. Did you forget to call initialize?");
            throw new Error("CurrentUserService not initialized. Did you forget to call initialize?");
        }
        return this._currentUserService;
    }

    public get weddingService(): IWeddingService {
        if (!this._weddingService) {
            this.logger.error("WeddingService not initialized. Did you forget to call initialize?");
            throw new Error("WeddingService not initialized. Did you forget to call initialize?");
        }
        return this._weddingService;
    }

    public get invitationService(): IInvitationService {
        if (!this._invitationService) {
            this.logger.error("InvitationService not initialized. Did you forget to call initialize?");
            throw new Error("InvitationService not initialized. Did you forget to call initialize?");
        }
        return this._invitationService;
    }

    public get attendanceService(): IAttendanceService {
        if (!this._attendanceService) {
            this.logger.error("AttendanceService not initialized. Did you forget to call initialize?");
            throw new Error("AttendanceService not initialized. Did you forget to call initialize?");
        }
        return this._attendanceService;
    }

    public get mediaService(): IMediaService {
        if (!this._mediaService) {
            this.logger.error("MediaService not initialized. Did you forget to call initialize?");
            throw new Error("MediaService not initialized. Did you forget to call initialize?");
        }
        return this._mediaService;
    }

    public get qrCodeService(): IQrCodeService {
        if (!this._qrCodeService) {
            this.logger.error("QrCodeService not initialized. Did you forget to call initialize?");
            throw new Error("QrCodeService not initialized. Did you forget to call initialize?");
        }
        return this._qrCodeService;
    }

    public get guestTagService(): IGuestTagService {
        if (!this._guestTagService) {
            this.logger.error("GuestTagService not initialized. Did you forget to call initialize?");
            throw new Error("GuestTagService not initialized. Did you forget to call initialize?");
        }
        return this._guestTagService;
    }
}
