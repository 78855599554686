import type { UserTokenDetails } from "./UserTokenDetails";

/**
 * Represents the name of the header used for redemption code authentication.
 */
export const REDEMPTION_CODE_HEADER_NAME = "x-wg-redemptionCode" as const;

/**
 * Represents a header used for bearer authentication.
 */
export type BearerAuthenticationHeader = { Authorization: string };

/**
 * Represents a header used for wedding redemption code authentication.
 */
export type RedemptionCodeAuthenticationHeader = { [REDEMPTION_CODE_HEADER_NAME]: string };

/**
 * Represents an empty header used by the authentication service.
 */
export type EMPTY_HEADER = Record<string, never>;

export interface IAuthenticationService {
    /**
     * Initializes the authentication service.
     */
    initialize(): Promise<void>;

    /**
     * Gets an access token for the given scopes.
     * @param scopes The scopes to request access to.
     */
    getAccessToken(scopes: string[]): Promise<string>;

    /**
     * Gets the request header for the given scopes.
     * @param scopes The scopes to request access to.
     */
    getAuthenticationHeader(
        scopes: string[]
    ): Promise<BearerAuthenticationHeader | RedemptionCodeAuthenticationHeader | EMPTY_HEADER>;

    /**
     * Returns true if the user is authenticated.
     */
    isAuthenticated(): boolean;

    /**
     * Gets the user's token details if they are authenticated.
     */
    getTokenDetails(): UserTokenDetails | null;
}
