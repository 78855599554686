import { z } from "zod";
import { CHANGE_TOKEN_LENGTH } from "./Attendance";

/**
 * Represents data that can be retrieved by using the QR access code.
 * This is used as a dto when calling the /qr endpoint with the qrAccessCode.
 */
export const QrCodeDataSchema = z.object({
    /**
     * The id of the wedding this invitation is for.
     */
    weddingId: z.string().uuid(),

    /**
     * The display name of the user that is attending.
     */
    userDisplayName: z.string(),

    /**
     * The change token is used to identify the attendance record when updating it.
     * It is a random string of 15 characters.
     */
    attendanceChangeToken: z.union([z.string().length(CHANGE_TOKEN_LENGTH), z.string().length(0)]).nullable(),

    /**
     * The id of the attendance record.
     */
    attendanceId: z.string().uuid(),

    /**
     * The redemption code associated with the qrAccessCode.
     */
    redemptionCode: z.string()
});
