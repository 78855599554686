import {
    Spinner,
    ToggleButton,
    buttonClassNames,
    makeStyles,
    shorthands,
    spinnerClassNames
} from "@fluentui/react-components";
import { CheckmarkSquareFilled, CheckmarkSquareRegular, DismissSquareRegular, bundleIcon } from "@fluentui/react-icons";
import { DEFAULT_SPINNER_DELAY } from "@weddinggram/common";
import { Flex, FlexBlur } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import * as React from "react";
import {
    INVITATION_CSS_VARIABLE_ACCENT_LIGHTER,
    INVITATION_CSS_VARIABLE_BACKGROUND,
    useInvitationStyleVariables
} from "../PageColors";

type InvitationReplyButtonsProps = {
    /**
     * Flag indicating whether the user is attending or not.
     * A `null` value indicates that the user has not yet made a decision.
     */
    isAttending: boolean | null;

    /**
     * Flag indicating whether the buttons should be disabled.
     */
    buttonsDisabled: boolean;

    /**
     * Flag indicating whether the user has replied to the invitation.
     */
    hasReplied: boolean;

    /**
     * Flag indicating whether the reply is being sent.
     */
    isLoading: boolean;

    /**
     * The number of guests that replying to the invitation.
     */
    numberOfGuests: number;

    /**
     * Callback invoked when the user clicks the confirm button.
     * @returns void
     */
    onConfirmClick: () => void;

    /**
     * Callback invoked when the user clicks the decline button.
     * @returns void
     */
    onDeclineClick: () => void;
};

const useStyles = makeStyles({
    confirmationButtons: {
        marginTop: "2rem",
        ...shorthands.padding(0, "1rem"),
        boxSizing: "border-box",
        textAlign: "center",
        height: "95px"
    },
    spinner: {
        [`& .${spinnerClassNames.spinner}`]: {
            backgroundColor: `var(${INVITATION_CSS_VARIABLE_ACCENT_LIGHTER})`,
            color: `var(${INVITATION_CSS_VARIABLE_BACKGROUND})`
        }
    },
    toggleButton: {
        [`& .${buttonClassNames.icon}`]: {
            marginLeft: "0.5rem"
        },
        justifyContent: "start"
    }
});

/**
 * Renders the buttons to confirm or decline the invitation.
 * @param props props for the component.
 * @returns A component rendering two buttons to confirm or decline the invitation.
 */
export const InvitationReplyButtons: React.FC<InvitationReplyButtonsProps> = (props) => {
    const { isAttending, buttonsDisabled, isLoading, numberOfGuests, onConfirmClick, onDeclineClick } = props;

    const { t } = useTranslation("invitation");
    const cardStyles = useInvitationStyleVariables(true);
    const pageClasses = useStyles();

    const confirmButtonLabel = t("confirm.button.label", { count: numberOfGuests });
    const declineButtonLabel = t("decline.button.label", { count: numberOfGuests });

    const AcceptIcon = bundleIcon(CheckmarkSquareFilled, CheckmarkSquareRegular);
    const DeclineIcon = bundleIcon(DismissSquareRegular, DismissSquareRegular);

    const acceptIconSlot = isLoading ? (
        <Spinner size="tiny" delay={DEFAULT_SPINNER_DELAY} className={pageClasses.spinner} />
    ) : (
        <AcceptIcon style={cardStyles.accentColor} />
    );
    const declineIconSlot = isLoading ? (
        <Spinner size="tiny" delay={DEFAULT_SPINNER_DELAY} className={pageClasses.spinner} />
    ) : (
        <DeclineIcon style={cardStyles.accentColor} />
    );

    const confirmButtonDisabled = buttonsDisabled || (isAttending === true && props.hasReplied);
    const declineButtonDisabled = buttonsDisabled || (isAttending === false && props.hasReplied);
    const confirmButtonChecked = isAttending === true && props.hasReplied;
    const declineButtonChecked = isAttending === false && props.hasReplied;

    return (
        <Flex useVertical className={pageClasses.confirmationButtons}>
            {numberOfGuests > 0 ? (
                <FlexBlur animateIn useVertical gap="gapMedium">
                    <ToggleButton
                        size="large"
                        appearance="outline"
                        style={{
                            ...cardStyles.foregroundColor,
                            ...cardStyles.accentBorderColor,
                            ...cardStyles.backgroundColor
                        }}
                        className={pageClasses.toggleButton}
                        onClick={onConfirmClick}
                        disabled={confirmButtonDisabled}
                        icon={acceptIconSlot}
                        checked={confirmButtonChecked}
                    >
                        {confirmButtonLabel}
                    </ToggleButton>
                    <ToggleButton
                        size="large"
                        appearance="outline"
                        style={{
                            ...cardStyles.foregroundColor,
                            ...cardStyles.accentBorderColor,
                            ...cardStyles.backgroundColor
                        }}
                        className={pageClasses.toggleButton}
                        onClick={onDeclineClick}
                        disabled={declineButtonDisabled}
                        icon={declineIconSlot}
                        checked={declineButtonChecked}
                    >
                        {declineButtonLabel}
                    </ToggleButton>
                </FlexBlur>
            ) : (
                <div style={cardStyles.foregroundColor}>{t("confirm.addNamePrompt")}</div>
            )}
        </Flex>
    );
};
