import { DAYS_IN_WEEK, HOURS_IN_DAY, MIN_IN_HOUR } from "@weddinggram/common";
import type { ApiCacheOptions } from "./ApiCacheOptions";

const THIRTY_SECONDS = 0.5;
const ONE_WEEK = MIN_IN_HOUR * HOURS_IN_DAY * DAYS_IN_WEEK;
const ONE_DAY = MIN_IN_HOUR * HOURS_IN_DAY;

export const defaultCacheOptions: ApiCacheOptions = {
    cacheLocation: "localStorage",
    agendaExpiration: 1,
    agendaKey: "agenda",
    otherUserExpiration: 5,
    otherUserKey: "users",
    userExpiration: 0,
    userKey: "me",
    weddingExpiration: 1,
    weddingKey: "weddings",
    imagePostExpiration: THIRTY_SECONDS,
    imagePostKey: "posts",
    mediaExpiration: ONE_WEEK,
    mediaKey: "media",
    mediaStreamExpiration: ONE_DAY,
    mediaStreamKey: "stream",
    tagKey: "tags",
    tagExpiration: ONE_DAY
} as const;
