import { getMediaType } from "@weddinggram/model/utilities";
import { SeverityLevel } from "@weddinggram/telemetry-core";
import { useAppInsights } from "@weddinggram/telemetry-react";
import * as React from "react";
import { useImageFromApi } from "./useImageFromApi";
import { useStreamingUrl } from "./useStreamingUrl";

/**
 * Data returned when media is a video.
 */
type VideoHookData = ReturnType<typeof useStreamingUrl> & {
    /**
     * Type of media is video.
     */
    type: "Video";
};

/**
 * Data returned when media is an image.
 */
type ImageHookData = ReturnType<typeof useImageFromApi> & {
    /**
     * Type of media is image.
     */
    type: "Image";
};

/**
 * Data returned when media is an image or video.
 */
type HookData = VideoHookData | ImageHookData;

/**
 * Hook to retrieve an image or video from the API.
 * @param mediaUrl The url of the image to retrieve.
 * @returns An {@link MediaFromApiHookData|object} containing the image and status of retrieval.
 */
export const useMediaFromApi = (
    mediaUrl: string | undefined | null,
    fullImageUrl?: string | undefined | null,
    shouldLoadNow = true,
    playVideo = false
): HookData => {
    const urlForMediaType = playVideo ? fullImageUrl : mediaUrl;
    const { trackException } = useAppInsights();
    const imageData = useImageFromApi(mediaUrl, fullImageUrl, shouldLoadNow);
    const streamData = useStreamingUrl(urlForMediaType, shouldLoadNow);

    const mediaType = getMediaType(urlForMediaType);

    return React.useMemo(() => {
        switch (mediaType) {
            case "Video":
                return {
                    type: "Video",
                    ...streamData
                };
            case "Image":
                return {
                    type: "Image",
                    ...imageData
                };
            default:
                trackException(new Error(`Unknown media type: ${mediaType}`), SeverityLevel.Error, {
                    mediaUrl,
                    playVideo,
                    fullImageUrl
                });
                return {
                    abort: () => undefined,
                    hasError: true,
                    isLoading: false,
                    mediaData: undefined,
                    type: "Image"
                };
        }
    }, [imageData, streamData, mediaType, trackException, mediaUrl, playVideo, fullImageUrl]);
};
