import { Logger } from "@weddinggram/telemetry-core";
import * as React from "react";
import { useSelector } from "react-redux";
import {
    retrieveWeddingById,
    selectSelectedWedding,
    selectWedding,
    selectWeddingError,
    selectWeddingRetrieveStatus
} from "../slices";
import { useAppDispatch } from "../store";

/**
 * Hook to get the selected wedding and its status.
 * @returns The selected wedding and its status
 */
export const useSelectedWedding = () => {
    const dispatch = useAppDispatch();
    const selectedWeddingId = useSelector(selectSelectedWedding);
    const selectedWedding = useSelector(selectWedding(selectedWeddingId));
    const retrieveStatus = useSelector(selectWeddingRetrieveStatus(selectedWeddingId));
    const retrieveError = useSelector(selectWeddingError);
    const shouldLoadWedding = Boolean(selectedWeddingId) && !selectedWedding && retrieveStatus === "idle";

    const fetchWedding = React.useCallback(() => {
        if (selectedWeddingId) {
            Logger.log("[useSelectedWedding] Fetching wedding", { weddingId: selectedWeddingId });
            dispatch(retrieveWeddingById(selectedWeddingId));
        }
    }, [dispatch, selectedWeddingId]);

    React.useEffect(() => {
        if (shouldLoadWedding) {
            fetchWedding();
        }
    }, [shouldLoadWedding, fetchWedding]);

    return React.useMemo(() => {
        return {
            wedding: selectedWedding,
            retrieveStatus,
            retrieveError,
            fetchWedding
        };
    }, [selectedWedding, retrieveStatus, retrieveError, fetchWedding]);
};
