import { makeStaticStyles, makeStyles, shorthands, tokens } from "@fluentui/react-components";

/**
 * Styles to be used for all invitation pages..
 */
export const useInvitationStyles = makeStyles({
    regularText: {
        fontSize: tokens.fontSizeBase200,
        textAlign: "center",
        fontWeight: 100
    },
    mediumText: {
        fontSize: tokens.fontSizeBase400,
        textAlign: "center",
        fontWeight: 100
    },
    upperCaseText: {
        textTransform: "uppercase"
    },
    wideLetterSpacing: {
        letterSpacing: "0.25rem"
    },
    italicText: {
        fontStyle: "italic"
    },
    separator: {
        ...shorthands.padding("0", "1rem"),
        fontSize: tokens.fontSizeBase600,
        fontWeight: 100
    },
    leftSideFold: {
        boxShadow: "inset -5px 0px 5px -5px rgba(0, 0, 0, 0.5)"
    },
    rightSideFold: {
        boxShadow: "inset 5px 0px 5px -5px rgba(0, 0, 0, 0.5)"
    }
});

/**
 * Generates static styles like the font-face declaration for the invitation.
 */
export const useStaticInvitationStyles = makeStaticStyles({
    "@font-face": {
        fontFamily: "Champignon",
        src: `url("../../../../../../assets/fonts/Champignon.otf") format("opentype")`
    }
});
