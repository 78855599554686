import { Logger } from "@weddinggram/telemetry-core";
import * as ls from "local-storage";
import * as React from "react";

/**
 * Hook to store a value in local storage.
 * @param key The key to store the cache value under.
 * @param initialValue The value to store in the cache.
 * @returns [storedValue, setValue]
 */
export const useLocalStorage = <T>(key: string, initialValue: T) => {
    const [isPending, startTransition] = React.useTransition();

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = React.useState<T>(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = ls.get<string>(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            Logger.error(`Could not get item ${key} from local storage`, { error });
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        startTransition(() => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(storedValue) : value;

                // Save state
                setStoredValue(valueToStore);

                // Save to local storage
                ls.set(key, JSON.stringify(valueToStore));
            } catch (error) {
                // A more advanced implementation would handle the error case
                Logger.error(`Could not save item ${key} to local storage`, { error });
            }
        });
    };
    return [storedValue, setValue, isPending] as const;
};
