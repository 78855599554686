import { PageLoader } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import type { ServiceFactory } from "@weddinggram/service";
import { Logger } from "@weddinggram/telemetry-core";
import * as React from "react";

/**
 * The props for the Service Factory Context which contains the Service Factory instance.
 */
type ServiceFactoryContextProps = {
    factory: ServiceFactory | null;
};

/**
 * The Service Factory Context keeping a global reference to the Service Factory instance.
 */
export const ServiceFactoryContext = React.createContext<ServiceFactoryContextProps>({ factory: null });

/**
 * The props for the Service Factory Provider.
 */
type ServiceFactoryProviderProps = {
    /**
     * The Service Factory instance.
     */
    factory: ServiceFactory;

    /**
     * The children to render.
     */
    children: React.ReactNode;
};

export const ServiceFactoryProvider: React.FC<ServiceFactoryProviderProps> = ({ factory, children }) => {
    const serviceFactoryRef = React.useRef<ServiceFactoryContextProps | null>(null);
    const [initialized, setInitialized] = React.useState(false);
    const [, startTransition] = React.useTransition();
    const { t } = useTranslation("common");
    if (!serviceFactoryRef.current) {
        // Initialize the service factory
        const init = async () => {
            await factory.initialize();
            startTransition(() => {
                setInitialized(true);
                Logger.log("[ServiceFactoryProvider] Service Factory initialized", {
                    factory: serviceFactoryRef.current,
                    factoryInitialized: serviceFactoryRef.current?.factory?.isInitialized
                });
            });
        };
        init();
        serviceFactoryRef.current = { factory };
    }

    return initialized ? (
        <ServiceFactoryContext.Provider value={serviceFactoryRef.current}>{children}</ServiceFactoryContext.Provider>
    ) : (
        <PageLoader label={t("loading.serviceFactory.label")} />
    );
};
