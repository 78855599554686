import { EMPTY_UUID, RouteConstants } from "@weddinggram/common";
import type { DtoEntity, Invitation } from "@weddinggram/model";

export const INVITE_PREVIEW_DATA_QSP_KEY = "invite" as const;

export const INVITE_PREVIEW_GUEST_NAME_QSP_KEY = "guestName" as const;

type WindowMock = {
    location: {
        origin: string;
    };
    btoa: (data: string) => string;
};

/**
 * Encodes an invitation into a preview link.
 * @param invitation The invitation to get the preview link for
 * @param guestName The name of the guest to use in the preview link
 * @param windowMock A mock window object to use for testing
 */
export const getInvitationPreviewLink = (
    invitation: DtoEntity<Invitation>,
    guestName: string | null,
    windowMock?: WindowMock
): string => {
    const windowToUse = windowMock || window;

    const base = `${windowToUse.location.origin}/${RouteConstants.APP}/${RouteConstants.INVITE_PREVIEW}`;

    // Convert a DTO invitation object to a regular invitation by filling in mock data
    const invitationData: Invitation = {
        ...invitation,
        primaryCardColor: invitation.primaryCardColor || null,
        secondaryCardColor: invitation.secondaryCardColor || null,
        tertiaryCardColor: invitation.tertiaryCardColor || null,
        id: EMPTY_UUID,
        metadata: {
            createdAt: new Date().toISOString(),
            createdBy: EMPTY_UUID,
            modifiedAt: new Date().toISOString()
        }
    };
    // Encode the invitation data as base64
    const data = windowToUse.btoa(JSON.stringify(invitationData));
    const encodedGuestName = windowToUse.btoa(JSON.stringify(guestName || "John Doe"));
    return `${base}?${INVITE_PREVIEW_DATA_QSP_KEY}=${data}&${INVITE_PREVIEW_GUEST_NAME_QSP_KEY}=${encodedGuestName}`;
};
