/**
 * Gets the path of the request from the response.
 * @param response The response we use to extract the path from.
 * @returns The path of the request without the base url.
 */
export const getPathFromResponse = (url: string | undefined): string => {
    url = url ?? "";

    // Turn a url like https://localhost:5001/api/identity into /api/identity
    // eslint-disable-next-line no-useless-escape
    const regex = /(?:https?:\/\/[^\/]+)?\/api\/(.*)/;
    const result = url.match(regex);
    return result?.[1] ?? url;
};
