import { LARGE_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from "@weddinggram/common";
import { useEffect, useState } from "react";

export enum ScreenSize {
    Small = "small",
    Medium = "medium",
    Large = "large",
    ExtraLarge = "extraLarge",
    MEDIUM_SCREEN_SIZE = "MEDIUM_SCREEN_SIZE"
}

/**
 * Represents the width and height of the window.
 */
type WindowSize = {
    width: number;
    height: number;
};

type WindowSizeWithScreenSize = WindowSize & {
    /**
     * The current screen size based on the width of the window.
     */
    screenSize: ScreenSize;
};

const getScreenSize = (width: number): ScreenSize => {
    if (width < SMALL_SCREEN_SIZE) {
        return ScreenSize.Small;
    } else if (width < MEDIUM_SCREEN_SIZE) {
        return ScreenSize.Medium;
    } else if (width < LARGE_SCREEN_SIZE) {
        return ScreenSize.Large;
    }
    return ScreenSize.ExtraLarge;
};

/**
 * The minimum number of pixels the window size must change before the screen size is updated.
 */
const SCREEN_SIZE_CHANGE_THRESHOLD = 100;

/**
 * Custom hook that provides the current width and height of the browser window.
 * @returns The current width and height of the window.
 */
export const useWindowSize = (): WindowSizeWithScreenSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        /**
         * Event listener callback function that updates the window size state.
         */
        const handleResize = () => {
            setWindowSize((prevSize) => {
                const widthChange = Math.abs(window.innerWidth - prevSize.width);
                const heightChange = Math.abs(window.innerHeight - prevSize.height);

                // Only update the window size if the change is greater than the threshold
                // this should not greatly impact usage of the screenSize enum.
                // However, this was done to reduce unnecessary re-renders of components that rely on the screenSize. E.g. the invitation pages.
                if (widthChange < SCREEN_SIZE_CHANGE_THRESHOLD && heightChange < SCREEN_SIZE_CHANGE_THRESHOLD) {
                    return prevSize;
                }
                return {
                    width: window.innerWidth,
                    height: window.innerHeight
                };
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const screenSize = getScreenSize(windowSize.width);

    return { ...windowSize, screenSize };
};
