export * from "./pages";
export * from "./preview";

import loadable from "@loadable/component";
export const InvitationAppRoot = loadable(
    () => import(/* webpackChunkName: "InvitationAppRoot" */ "./InvitationAppRoot"),
    {
        resolveComponent: (components) => components.InvitationAppRoot
    }
);

export const InvitationQrCodeAppRoot = loadable(
    () => import(/* webpackChunkName: "InvitationQrCodeAppRoot" */ "./qr/InvitationQrCodeAppRoot"),
    {
        resolveComponent: (components) => components.InvitationQrCodeAppRoot
    }
);
