import { z } from "zod";
import { DateTimeOffsetSchema } from "./DateTimeOffset";

/**
 * Schema for the response of the endpoint to generate a stream url.
 */
export const StreamUrlSchema = z.object({
    /**
     * The date the stream url expires.
     */
    expiresOn: DateTimeOffsetSchema,

    /**
     * The url to the stream.
     */
    sasUrl: z.string().url(),

    /**
     * The id of the wedding.
     */
    weddingId: z.string().uuid()
});
