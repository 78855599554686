export type AppEnvironmentType = "devLocal" | "prodLocal" | "devProd" | "prodProd" | "mock";

export enum AppEnvironment {
    devLocal = "devLocal",
    prodLocal = "prodLocal",
    devProd = "devProd",
    prodProd = "prodProd",
    mock = "mock"
}

const getAppEnv = (): AppEnvironmentType => {
    try {
        const env = APP_ENV;
        if (env) {
            return env;
        }
        return AppEnvironment.mock;
    } catch (e) {
        return AppEnvironment.mock;
    }
};

export const isEnvDev = (envName: AppEnvironmentType) => {
    return envName === AppEnvironment.devLocal || envName === AppEnvironment.devProd || envName === AppEnvironment.mock;
};

export const isDev = () => {
    const env = getAppEnv();
    return isEnvDev(env);
};
