import { ApiException } from "./ApiException";

export const USER_CREATION_EXCEPTION_NAME = "UserCreationException";

export class UserCreationException extends ApiException {
    constructor(
        public readonly email: string,
        public readonly requestId: string,
        message: string,
        innerException?: unknown,
        properties?: Record<string, unknown>
    ) {
        super(null, message, innerException, properties);
        this.name = USER_CREATION_EXCEPTION_NAME;
    }

    public override serialize(): Record<string, unknown> {
        return {
            ...super.serialize(),
            email: this.email
        };
    }
}

export const isUserCreationException = (error: unknown): error is UserCreationException => {
    return (
        error !== null &&
        error !== undefined &&
        typeof error === "object" &&
        "name" in error &&
        typeof error.name === "string" &&
        error.name === USER_CREATION_EXCEPTION_NAME
    );
};
