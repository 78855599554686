import { useSearchParams } from "react-router-dom";

/**
 * Retrieves the value of a query paramter.
 * @param name Name of the query parameter
 * @returns The value of the paramter or `null`.
 */
export const useQueryParam = (name: string) => {
    const [searchParams] = useSearchParams();
    return searchParams.get(name);
};
