import { Logger } from "@weddinggram/telemetry-core";
import { useLocalStorage } from "./useLocalStorage";
import { useMedia } from "./useMedia";

/**
 * Hook that returns a boolean value indicating if dark mode is enabled and a setter function to enable/disable it.
 * @returns [enabled, setEnabled]
 */
export const useDarkMode = (): [boolean, (newMode: boolean) => void, boolean] => {
    // Use our useLocalStorage hook to persist state through a page refresh.
    // Read the recipe for this hook to learn more: usehooks.com/useLocalStorage
    const [enabledState, setDarkModeEnabled, isPending] = useLocalStorage<boolean>("dark-mode-enabled", false);

    // See if user has set a browser or OS preference for dark mode.
    // The usePrefersDarkMode hook composes a useMedia hook (see code below).
    const prefersDarkMode = usePrefersDarkMode();
    // If enabledState is defined use it, otherwise fallback to prefersDarkMode.
    // This allows user to override OS level setting on our website.
    const darkModeEnabled = enabledState ?? prefersDarkMode;

    Logger.log("useDarkMode", darkModeEnabled);

    // Return enabled state and setter
    return [darkModeEnabled, setDarkModeEnabled, isPending];
};

export const usePrefersDarkMode = () => {
    return useMedia(["(prefers-color-scheme: dark)"], [true], false);
};
