import * as React from "react";
import { ServiceFactoryContext } from "./ServiceFactoryProvider";

/**
 * React hook to get the service factory.
 * @returns The service factory.
 */
export const useServiceFactory = () => {
    // Get the factory from the context
    const context = React.useContext(ServiceFactoryContext);

    // Throw an error if the factory is not found
    if (!context || !context.factory) {
        throw new Error("Service factory not found. Make sure you have wrapped your app with ServiceFactoryProvider.");
    }
    return context.factory;
};
