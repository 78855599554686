import { createSlice } from "@reduxjs/toolkit";
import type { DtoEntity, User, Wedding, WeddingUpdate } from "@weddinggram/model";
import type { UploadProgress } from "@weddinggram/service";

import { Logger } from "@weddinggram/telemetry-core";
import type { RootState } from "../store/store";
import { createAsyncThunkWithServiceFactory } from "../thunks";
import { IDLE_STATE, type RequestStatus, type RequestStatusForWedding } from "../utilities/RequestStatus";

export type WeddingState = {
    /**
     * Indicates whether the wedding is currently being retrieved from the server
     */
    retrieveStatus: RequestStatusForWedding;

    /**
     * Indicates whether the wedding is currently being joined on the server.
     */
    joinStatus: RequestStatus;

    /**
     * Indicates whether the wedding is currently being updated on the server.
     */
    updateStatus: RequestStatus;

    /**
     * Indicates whether the wedding is currently being created on the server.
     */
    createStatus: RequestStatus;

    /**
     * Error object that is set when an error occurs.
     */
    error: unknown | null;

    /**
     * The weddings that the user is a part of.
     */
    weddings: Wedding[];

    /**
     * The wedding that is currently selected.
     */
    selectedWeddingId: string | null;

    /**
     * The wedding that is currently being retrieved.
     */
    retrievedWedding: Wedding | null;
};

const initialState: WeddingState = {
    retrieveStatus: {},
    updateStatus: "idle",
    createStatus: "idle",
    joinStatus: "idle",
    error: null,
    weddings: [],
    selectedWeddingId: null,
    retrievedWedding: null
};

/**
 * Retrieves the wedding with the given id.
 */
export const retrieveWeddingById = createAsyncThunkWithServiceFactory(
    "wedding/get",
    async (weddingId: string, { extra, signal }) => {
        return await extra.weddingService.getById(weddingId, signal);
    }
);

/**
 * Joins a wedding with a redemption code.
 */
export const joinWedding = createAsyncThunkWithServiceFactory(
    "wedding/join",
    async (payload: { weddingId: string; redemptionCode: string }, { extra, signal }) => {
        return await extra.weddingService.joinWedding(payload.weddingId, payload.redemptionCode, signal);
    }
);

/**
 * Removes a user from a wedding.
 */
export const removeUserFromWedding = createAsyncThunkWithServiceFactory(
    "wedding/removeUser",
    async (payload: { weddingId: string; userId: string }, { extra, signal }) => {
        await extra.weddingService.removeUserFromWedding(payload.weddingId, payload.userId, signal);
        return payload.userId;
    }
);

/**
 * Creates a new wedding
 */
export const createWedding = createAsyncThunkWithServiceFactory(
    "wedding/create",
    async (wedding: DtoEntity<Wedding>, { extra, signal }) => {
        return await extra.weddingService.create(wedding, signal);
    }
);

type UpdateWeddingPayload = {
    weddingId: string;
    value: WeddingUpdate;
};
export const updateWedding = createAsyncThunkWithServiceFactory(
    "wedding/update",
    async (payload: UpdateWeddingPayload, { extra, signal }) => {
        return await extra.weddingService.update(payload.weddingId, payload.value, signal);
    }
);

type AddOwnerWeddingPayload = {
    weddingId: string;
    newOwner: string;
};
export const addOwnerToWedding = createAsyncThunkWithServiceFactory(
    "wedding/update/addOwner",
    async (payload: AddOwnerWeddingPayload, { extra, signal }) => {
        return await extra.weddingService.addOwner(payload.weddingId, payload.newOwner, signal);
    }
);

type UpdateHeaderImagePayload = {
    /**
     * The id of the wedding to update.
     */
    weddingId: string;

    /**
     * The file to upload as the header image.
     */
    headerImage: File;

    /**
     * The callback to call when the upload progress changes.
     * @param e The upload progress event.
     * @returns void
     */
    onUploadProgress?: (e: UploadProgress) => void;
};
export const updateHeaderImage = createAsyncThunkWithServiceFactory(
    "wedding/update/headerImage",
    async (payload: UpdateHeaderImagePayload, { extra, signal }) => {
        return await extra.weddingService.updateHeaderImage(
            payload.weddingId,
            payload.headerImage,
            signal,
            payload.onUploadProgress
        );
    }
);

export const deleteWedding = createAsyncThunkWithServiceFactory(
    "wedding/delete",
    async (weddingId: string, { extra, signal }) => {
        await extra.weddingService.deleteWedding(weddingId, signal);
        return weddingId;
    }
);

const updateWeddingInWeddingsState = (state: WeddingState, updatedWedding: Wedding): Wedding[] => {
    // If the wedding is not already in the list of weddings, add it.
    if (!state.weddings.some((w) => updatedWedding.id === w.id)) {
        return [...state.weddings, updatedWedding];
    }

    // Otherwise, update the existing wedding.
    const weddingToChange = state.weddings.find((wedding) => updatedWedding.id === wedding.id);
    const newWedding = { ...weddingToChange, ...updatedWedding };

    return [...state.weddings.map((w) => (w.id === updatedWedding.id ? newWedding : w))];
};

const weddingSlice = createSlice({
    name: "wedding",
    initialState,
    reducers: {
        setCurrentWedding: (
            state,
            action: {
                /**
                 * The id of the wedding to select.
                 */
                payload: string;
                type: string;
            }
        ) => {
            state.selectedWeddingId = action.payload;
        },
        resetJoinStatus: (state) => {
            state.joinStatus = "idle";
            state.error = null;
        },
        resetWeddingCreateStatus: (state) => {
            state.createStatus = "idle";
            state.error = null;
        },
        resetWeddingRetrievalStatus: (state) => {
            state.retrieveStatus = {};
            state.error = null;
        },
        resetWeddingUpdateStatus: (state) => {
            state.updateStatus = "idle";
            state.error = null;
        },
        updateGuestInSelectedWedding: (
            state,
            action: {
                /**
                 * The updated user.
                 * @type {User}
                 */
                payload: User;
                type: string;
            }
        ) => {
            if (state.selectedWeddingId === null) {
                return;
            }
            const wedding = state.weddings.find((w) => w.id === state.selectedWeddingId);
            if (wedding === undefined) {
                return;
            }

            wedding.guests = wedding.guests.map((g) => {
                if (g.id === action.payload.id) {
                    return action.payload;
                }
                return g;
            });
            updateWeddingInWeddingsState(state, wedding);
        }
    },
    extraReducers: (builder) => {
        builder
            // RetrieveWeddingById
            .addCase(retrieveWeddingById.pending, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "loading";
                state.error = null;
            })
            .addCase(retrieveWeddingById.fulfilled, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "succeeded";
                state.retrievedWedding = action.payload;

                state.weddings = updateWeddingInWeddingsState(state, action.payload);

                // In case the wedding is not already selected, select it.
                // In case the same wedding is already selected, update the selected wedding.
                if (state.selectedWeddingId === null || state.selectedWeddingId === action.payload.id) {
                    state.selectedWeddingId = action.payload.id;
                }
            })
            .addCase(retrieveWeddingById.rejected, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "failed";
                state.error = action.error;
                Logger.error(`[weddingSlice]${retrieveWeddingById.rejected.type} failed`, action.error);
            })

            // CreateWedding
            .addCase(createWedding.pending, (state) => {
                state.createStatus = "loading";
                state.selectedWeddingId = null;
            })
            .addCase(createWedding.fulfilled, (state, action) => {
                state.createStatus = "succeeded";
                // State.weddings.push(action.payload);
                state.weddings = [...state.weddings, action.payload];

                // When a wedding is created, select it.
                state.selectedWeddingId = action.payload.id;
            })
            .addCase(createWedding.rejected, (state, action) => {
                state.createStatus = "failed";
                state.error = action.error;
                Logger.error(`[weddingSlice]${createWedding.rejected.type} failed`, action.error);
            })

            // JoinWedding
            .addCase(joinWedding.pending, (state) => {
                state.joinStatus = "loading";
                state.error = null;
            })
            .addCase(joinWedding.fulfilled, (state, action) => {
                state.joinStatus = "succeeded";

                // Make sure we haven't already added the wedding before
                if (!state.weddings.some((w) => w.id === action.payload.id)) {
                    state.weddings.push(action.payload);
                }
                state.selectedWeddingId = action.payload.id;
            })
            .addCase(joinWedding.rejected, (state, action) => {
                state.joinStatus = "failed";
                state.error = action.error;
                Logger.error(`[weddingSlice]${joinWedding.rejected.type} failed`, action.error);
            })

            // RemoveUserFromWedding
            .addCase(removeUserFromWedding.pending, (state, action) => {
                Logger.log(
                    `[weddingSlice] ${removeUserFromWedding.pending.type} started. User Id to remove ${action.meta.arg.userId}`
                );
                state.updateStatus = "loading";
                state.error = null;
            })
            .addCase(removeUserFromWedding.fulfilled, (state, action) => {
                Logger.log(
                    `[weddingSlice] ${removeUserFromWedding.fulfilled.type} succeeded. User Id to remove ${action.meta.arg.userId}`
                );
                state.updateStatus = "succeeded";
                state.weddings = state.weddings.map((wedding) => {
                    if (wedding.id === action.meta.arg.weddingId) {
                        return {
                            ...wedding,
                            guests: wedding.guests.filter((guest) => guest.id !== action.meta.arg.userId)
                        };
                    }
                    return wedding;
                });
            })
            .addCase(removeUserFromWedding.rejected, (state, action) => {
                Logger.error(
                    `[weddingSlice] ${removeUserFromWedding.rejected.type} failed. User Id to remove ${action.meta.arg.userId}`,
                    action.error
                );
                state.updateStatus = "failed";
                state.error = action.error;
            })

            // UpdateWedding
            .addCase(updateWedding.pending, (state) => {
                state.updateStatus = "loading";
            })
            .addCase(updateWedding.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                state.retrievedWedding = action.payload;

                state.weddings = updateWeddingInWeddingsState(state, action.payload);
                state.selectedWeddingId = action.payload.id;
            })
            .addCase(updateWedding.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
                Logger.error(`[weddingSlice]${updateWedding.rejected.type} failed`, action.error);
            })

            // AddOwnerToWedding
            .addCase(addOwnerToWedding.pending, (state) => {
                state.updateStatus = "loading";
            })
            .addCase(addOwnerToWedding.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                state.retrievedWedding = action.payload;
                state.weddings = updateWeddingInWeddingsState(state, action.payload);
                state.selectedWeddingId = action.payload.id;
            })
            .addCase(addOwnerToWedding.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
                Logger.error(`[weddingSlice]${addOwnerToWedding.rejected.type} failed`, action.error);
            })

            // UpdateHeaderImage
            .addCase(updateHeaderImage.pending, (state) => {
                state.updateStatus = "loading";
            })
            .addCase(updateHeaderImage.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                state.retrievedWedding = action.payload;
                state.weddings = updateWeddingInWeddingsState(state, action.payload);
                state.selectedWeddingId = action.payload.id;
            })
            .addCase(updateHeaderImage.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
                Logger.error(`[weddingSlice]${updateHeaderImage.rejected.type} failed`, action.error);
            })

            // DeleteWedding
            .addCase(deleteWedding.pending, (state) => {
                state.updateStatus = "loading";
            })
            .addCase(deleteWedding.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                state.weddings = [...state.weddings.filter((wedding) => wedding.id !== action.payload)];

                // Try to select the first wedding in the list
                if (state.weddings.length > 0) {
                    state.selectedWeddingId = state.weddings[0].id;
                } else {
                    state.selectedWeddingId = null;
                }
            })
            .addCase(deleteWedding.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
                Logger.error(`[weddingSlice]${deleteWedding.rejected.type} failed`, action.error);
            });
    }
});

export const weddingReducer = weddingSlice.reducer;
export const {
    resetWeddingRetrievalStatus,
    setCurrentWedding,
    resetJoinStatus,
    resetWeddingCreateStatus,
    updateGuestInSelectedWedding,
    resetWeddingUpdateStatus
} = weddingSlice.actions;

const NO_RESULT = undefined;
const EMPTY_ARRAY: User[] = [] as const;

/**
 * Selects the wedding guests.
 * @param state The root state of the application.
 * @returns A list of guests that are attending the wedding.
 */
export const selectWeddingGuests =
    (weddingId: string | undefined) =>
    (state: RootState): User[] => {
        if (!weddingId) {
            return EMPTY_ARRAY;
        }
        const wedding = state.weddings.weddings.find((wedding) => wedding.id === weddingId);
        if (!wedding) {
            return EMPTY_ARRAY;
        }
        return wedding.guests;
    };

/**
 * Selects the wedding with the given id. If the wedding is not in the state, it will return undefined.
 * @param weddingId The id of the wedding to select.
 * @returns The wedding with the given id.
 */
export const selectWedding =
    (weddingId?: string | null) =>
    (state: RootState): Wedding | undefined => {
        if (!weddingId) {
            return NO_RESULT;
        }
        return state.weddings.weddings.find((wedding) => wedding.id === weddingId);
    };

/**
 * Selects the retrieval status of the wedding.
 * @param state The root state of the application.
 * @returns The status of the wedding retrieval.
 */
export const selectWeddingRetrieveStatus = (weddingId?: string | null) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.weddings.retrieveStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the currently selected wedding id from the state.
 * @param state The root state of the application.
 * @returns The currently selected wedding id.
 */
export const selectSelectedWedding = (state: RootState) => state.weddings.selectedWeddingId;

/**
 * Selects the update status of the wedding.
 * Important: If multiple attributes of the wedding are updated, this will only return the status of the last update.
 * @param state The root state of the application.
 * @returns The current wedding update status.
 */
export const selectWeddingUpdateStatus = (state: RootState) => state.weddings.updateStatus || NO_RESULT;

/**
 * Selects the create status of the wedding
 * @param state The root state of the application
 * @returns The current wedding create status
 */
export const selectWeddingCreateStatus = (state: RootState) => state.weddings.createStatus;

/**
 * Selects the error state for the wedding state
 * @param state The root state of the application
 * @returns The error state
 */
export const selectWeddingError = (state: RootState) => state.weddings.error;

/**
 * Selects the join status for the wedding
 */
export const selectJoinStatus = (state: RootState) => state.weddings.joinStatus;
