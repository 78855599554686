import { useEffect, useRef, useState } from "react";

interface UseNearViewportOptions {
    rootMargin?: string;
    threshold?: number | number[];
}

const defaultOptions: UseNearViewportOptions = {
    rootMargin: "200px",
    threshold: 0
};

/**
 * Hook to detect if an element is near the viewport.
 * @param options Viewport options to configure the IntersectionObserver.
 * @returns [React.RefObject<HTMLDivElement>, boolean] Returns a ref to be attached to the element to be observed and a boolean indicating if the element is near the viewport.
 */
export const useNearViewport = (
    options: UseNearViewportOptions = defaultOptions
): [React.RefObject<HTMLDivElement>, boolean] => {
    const { rootMargin, threshold } = options;

    const [isNearViewport, setIsNearViewport] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observerCallback: IntersectionObserverCallback = (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                setIsNearViewport(true);
                observer.unobserve(entry.target);
            }
        };

        const observer = new IntersectionObserver(observerCallback, {
            rootMargin,
            threshold
        });

        const currentRef = elementRef.current;

        if (currentRef) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [elementRef, rootMargin, threshold]);

    return [elementRef, isNearViewport];
};
