import * as React from "react";
import { darkTheme, lightTheme } from ".";
import { ThemeContext } from "./ThemeProvider";

/**
 * Hook to get the theme context.
 * @returns The theme context.
 */
export const useTheme = () => {
    const { isDarkModeEnabled, setIsDarkModeEnabled, isPending } = React.useContext(ThemeContext);
    if (isDarkModeEnabled === null || setIsDarkModeEnabled === null) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }

    return React.useMemo(
        () => ({
            isDarkModeEnabled,
            theme: isDarkModeEnabled ? darkTheme : lightTheme,
            setIsDarkModeEnabled,
            toggleTheme: () => {
                setIsDarkModeEnabled(!isDarkModeEnabled);
            },
            isPending
        }),
        [isDarkModeEnabled, setIsDarkModeEnabled, isPending]
    );
};
