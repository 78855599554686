import type { ITelemetryContext } from "@microsoft/applicationinsights-common";
import type { IConfiguration, ICookieMgr, ILoadedPlugin, UnloadHandler } from "@microsoft/applicationinsights-core-js";
import type {
    DependencyListenerFunction,
    IDependencyListenerHandler
} from "@microsoft/applicationinsights-dependencies-js";
import type {
    ApplicationAnalytics,
    IApplicationInsights,
    IAutoExceptionTelemetry,
    IDependencyTelemetry,
    IEventTelemetry,
    IExceptionTelemetry,
    IMetricTelemetry,
    IPageViewPerformanceTelemetry,
    IPageViewTelemetry,
    IPlugin,
    ITelemetryItem,
    ITelemetryPlugin,
    ITraceTelemetry,
    Sender
} from "@microsoft/applicationinsights-web";
import type { AppEnvironmentType } from "@weddinggram/common";
import { PERFORMANCE_DURATION_PRECISION, PERFORMANCE_FLUSH_THRESHOLD, isEnvDev } from "@weddinggram/common";

import { Logger } from "./Logger";

const APPINSIGHTS_NOT_INITIALIZED_ERROR = "AppInsights not initialized";

export class AppInsightsService implements IApplicationInsights {
    private _measurements: Record<string, number[]> = {};
    private _measurementEventKeys: string[] = [];
    private _staticProperties: Record<string, unknown> = {};
    private readonly _isDev: boolean;

    constructor(
        private readonly _instance: IApplicationInsights | undefined,
        appVersion: string,
        appEnvironment: AppEnvironmentType
    ) {
        this._staticProperties = {
            appVersion,
            appEnvironment
        };
        this._isDev = isEnvDev(appEnvironment);
    }
    updateCfg<T extends IConfiguration = IConfiguration>(_: T, __?: boolean | undefined): void {
        throw new Error("Method not implemented.");
    }

    trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: unknown } | undefined): void {
        if (!this._measurements[metric.name]) {
            this._measurements[metric.name] = [];
            this._measurementEventKeys.push(metric.name);
        }
        this._measurements[metric.name].push(metric.average);

        if (this._measurements[metric.name].length >= PERFORMANCE_FLUSH_THRESHOLD) {
            this.sendPerformanceEvent(metric.name, customProperties);
        }
    }

    private sendPerformanceEvent(name: string, customProperties?: { [key: string]: unknown } | undefined) {
        const averageDuration =
            this._measurements[name].reduce((acc, cur) => acc + cur, 0) / this._measurements[name].length;
        const minDuration = Math.min(...this._measurements[name]);
        const maxDuration = Math.max(...this._measurements[name]);
        const props = { ...this._staticProperties, customProperties };
        this._instance?.trackMetric(
            {
                name: name,
                average: averageDuration,
                min: minDuration,
                max: maxDuration,
                sampleCount: this._measurements[name].length
            },
            props
        );

        this._measurements[name] = [];

        if (this._isDev) {
            Logger.log(`[PRF][${name}]: ${averageDuration.toFixed(PERFORMANCE_DURATION_PRECISION)}ms (average)`, props);
        }
    }

    flush: (async?: boolean | undefined) => void = (async) => {
        // Make sure we flush any remaining measurements
        this._measurementEventKeys.forEach((key) => {
            if (this._measurements[key].length > 0) {
                this.sendPerformanceEvent(key);
            }
        });

        this._instance?.flush(async);
    };

    trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: unknown } | undefined): void {
        this._instance?.trackEvent(event, { ...this._staticProperties, customProperties });
    }

    trackException(exception: IExceptionTelemetry, customProperties?: { [key: string]: unknown } | undefined): void {
        if (!this || !this?._instance) {
            Logger.error("Caught exception: ", exception);
            return;
        }
        this._instance?.trackException(exception, { ...this._staticProperties, customProperties });
    }

    startTrackEvent(name: string): void {
        this._instance?.trackEvent({ name });
    }

    stopTrackEvent(
        name: string,
        properties?: { [key: string]: string },
        measurements?: { [key: string]: number }
    ): void {
        this._instance?.stopTrackEvent(name, properties, measurements);
    }

    addTelemetryInitializer(telemetryInitializer: (item: ITelemetryItem) => boolean | void): void {
        this._instance?.addTelemetryInitializer(telemetryInitializer);
    }

    trackPageViewPerformance(
        pageViewPerformance: IPageViewPerformanceTelemetry,
        customProperties?: { [key: string]: unknown } | undefined
    ): void {
        this._instance?.trackPageViewPerformance(pageViewPerformance, customProperties);
    }

    trackDependencyData(dependency: IDependencyTelemetry): void {
        this._instance?.trackDependencyData(dependency);
    }

    addDependencyListener(dependencyListener: DependencyListenerFunction): IDependencyListenerHandler {
        if (!this._instance) {
            throw new Error(APPINSIGHTS_NOT_INITIALIZED_ERROR);
        }
        return this._instance.addDependencyListener(dependencyListener);
    }

    get appInsights(): ApplicationAnalytics {
        if (!this._instance) {
            throw new Error(APPINSIGHTS_NOT_INITIALIZED_ERROR);
        }
        return this._instance?.appInsights;
    }

    onunloadFlush: (async?: boolean | undefined) => void = (async) => {
        this._instance?.onunloadFlush(async);
    };

    getSender: () => Sender = () => {
        if (!this._instance) {
            throw new Error(APPINSIGHTS_NOT_INITIALIZED_ERROR);
        }
        return this._instance?.getSender();
    };

    setAuthenticatedUserContext(
        authenticatedUserId: string,
        accountId?: string | undefined,
        storeInCookie?: boolean | undefined
    ): void {
        this._instance?.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
    }

    clearAuthenticatedUserContext(): void {
        this._instance?.clearAuthenticatedUserContext();
    }

    unload(isAsync?: boolean | undefined, unloadComplete?: Parameters<IApplicationInsights["unload"]>["1"]): void {
        this._instance?.unload(isAsync, unloadComplete);
    }

    getPlugin<T extends IPlugin = IPlugin>(pluginIdentifier: string): ILoadedPlugin<T> {
        if (!this._instance) {
            throw new Error(APPINSIGHTS_NOT_INITIALIZED_ERROR);
        }
        return this._instance.getPlugin(pluginIdentifier);
    }

    addPlugin<T extends IPlugin = ITelemetryPlugin>(
        plugin: T,
        replaceExisting: boolean,
        doAsync: boolean,
        addCb?: ((added?: boolean | undefined) => void) | undefined
    ): void {
        this._instance?.addPlugin(plugin, replaceExisting, doAsync, addCb);
    }

    evtNamespace(): string {
        return this._instance?.evtNamespace() ?? "";
    }

    addUnloadCb(handler: UnloadHandler): void {
        this._instance?.addUnloadCb(handler);
    }

    getCookieMgr(): ICookieMgr {
        if (!this._instance) {
            throw new Error(APPINSIGHTS_NOT_INITIALIZED_ERROR);
        }
        return this._instance.getCookieMgr();
    }

    trackPageView(pageView: IPageViewTelemetry, customProperties?: { [key: string]: unknown } | undefined): void {
        this._instance?.trackPageView(pageView, customProperties);
    }

    _onerror(exception: IAutoExceptionTelemetry): void {
        this._instance?._onerror(exception);
    }

    trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: unknown } | undefined): void {
        this._instance?.trackTrace(trace, customProperties);
    }

    startTrackPage(name?: string | undefined): void {
        this._instance?.startTrackPage(name);
    }

    stopTrackPage(
        name?: string | undefined,
        url?: string | undefined,
        customProperties?: { [key: string]: string }
    ): void {
        this._instance?.stopTrackPage(name, url, customProperties);
    }

    get context(): ITelemetryContext {
        if (!this._instance) {
            throw new Error(APPINSIGHTS_NOT_INITIALIZED_ERROR);
        }
        return this._instance.context;
    }
}
