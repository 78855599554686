/* eslint-disable camelcase */
import { MS_IN_SEC } from "@weddinggram/common";
import { z } from "zod";

/**
 * Schema for the claims of the id token.
 */
export const idTokenClaims = z.object({
    exp: z.number().transform((exp) => new Date(exp * MS_IN_SEC)),
    sub: z.string().uuid("user id is not a valid uuid"),
    newUser: z.boolean().optional(),
    given_name: z.string(),
    family_name: z.string(),
    emails: z.array(z.string().email()).transform((emails) => emails[0])
});

export type IdTokenClaims = z.infer<typeof idTokenClaims>;
