import loadable from "@loadable/component";

export const AnimatedHeartImage = loadable(
    () => import(/* webpackChunkName: "AnimatedHeartImage" */ "./AnimatedHeartImage"),
    {
        resolveComponent: (components) => components.AnimatedHeartImage
    }
);

export const AvatarPlaceholderImage = loadable(
    () => import(/* webpackChunkName: "AvatarPlaceholder" */ "./AvatarPlaceholder"),
    {
        resolveComponent: (components) => components.AvatarPlaceholderImage
    }
);

export const ErrorImage = loadable(() => import(/* webpackChunkName: "ErrorImage" */ "./ErrorImage"), {
    resolveComponent: (components) => components.ErrorImage
});

export const HeartImage = loadable(() => import(/* webpackChunkName: "HeartImage" */ "./HeartImage"), {
    resolveComponent: (components) => components.HeartImage
});

export const NoAgendaImage = loadable(() => import(/* webpackChunkName: "NoAgendaImage" */ "./NoAgendaImage"), {
    resolveComponent: (components) => components.NoAgendaImage
});

export const NotFoundImage = loadable(() => import(/* webpackChunkName: "NotFoundImage" */ "./NotFoundImage"), {
    resolveComponent: (components) => components.NotFoundImage
});

export const PlaceholderImage = loadable(
    () => import(/* webpackChunkName: "PlaceholderImage" */ "./PlaceholderImage"),
    {
        resolveComponent: (components) => components.PlaceholderImage
    }
);

export const WeddingPlaceholderImage = loadable(
    () => import(/* webpackChunkName: "WeddingPlaceholderImage" */ "./WeddingImage"),
    {
        resolveComponent: (components) => components.WeddingImage
    }
);
