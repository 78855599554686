import { Button, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { ChevronLeftRegular, ChevronRightRegular } from "@fluentui/react-icons";
import { Flex } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import { Logger } from "@weddinggram/telemetry-core";
import { useAppInsights } from "@weddinggram/telemetry-react";
import * as React from "react";
import type { CardBookPageProps } from "../cardBookWrapper";
import type { InvitationStyles } from "./PageColors";

/**
 * Props for the PageNavigation component.
 */
type PageNavigationProps = {
    /**
     * Whether the next page button should be shown.
     */
    showNextPageButton?: boolean;

    /**
     * Whether the previous page button should be shown.
     */
    showPreviousPageButton?: boolean;

    /**
     * The label for the previous page button.
     */
    nextPageButtonLabel: string;

    /**
     * If true, hides the > icon on the next button.
     */
    hideNextButtonIcon?: boolean;

    /**
     * Optional classes to apply to the root element.
     */
    className?: string;
} & Pick<CardBookPageProps<object>, "nextPage" | "prevPage"> &
    Pick<InvitationStyles, "foregroundColor" | "accentBorderColor">;

const useStyles = makeStyles({
    buttonContainer: {
        ...shorthands.margin("1rem", 0)
    }
});

/**
 * Renders up to two buttons for navigating between the invitation pages.
 * @param props Props for the PageNavigation component.
 * @returns A component that renders the navigation buttons for the invitation pages.
 */
export const PageNavigation: React.FC<PageNavigationProps> = (props) => {
    const { nextPage, prevPage, nextPageButtonLabel } = props;
    const { t } = useTranslation("invitation");
    const { trackEvent } = useAppInsights();

    const next = React.useCallback(() => {
        trackEvent("app/invite/next", { nextPage: nextPageButtonLabel });
        Logger.log(`Navigating to next page: ${nextPageButtonLabel}`);
        nextPage();
    }, [nextPage, nextPageButtonLabel, trackEvent]);

    const prev = React.useCallback(() => {
        trackEvent("app/invite/previous", { nextPage: nextPageButtonLabel });
        Logger.log(`Navigating to previous page: ${nextPageButtonLabel}`);
        prevPage();
    }, [prevPage, trackEvent, nextPageButtonLabel]);

    const classes = useStyles();
    const nextPageButton = props.showNextPageButton ? (
        <Button
            size="large"
            appearance="outline"
            style={{ ...props.foregroundColor, ...props.accentBorderColor }}
            onClick={next}
            icon={props.hideNextButtonIcon ? undefined : <ChevronRightRegular />}
            iconPosition="after"
        >
            {nextPageButtonLabel}
        </Button>
    ) : null;

    const previousPageButton = props.showPreviousPageButton ? (
        <Button
            size="large"
            appearance="outline"
            style={{ ...props.foregroundColor, ...props.accentBorderColor }}
            onClick={prev}
            aria-label={t("previousPage.button.label")}
            icon={<ChevronLeftRegular />}
        />
    ) : null;

    if (!nextPageButton && !previousPageButton) {
        return <div></div>;
    }

    return (
        <Flex gap="gapMedium" className={mergeClasses(classes.buttonContainer, props.className)}>
            {previousPageButton}
            {nextPageButton}
        </Flex>
    );
};
