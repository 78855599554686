import { z } from "zod";

const LANGUAGE_CODE_LENGTH = 2;

/**
 * Represents a translated string which can be used to store user defined translations.
 */
export const TranslatedStringSchema = z
    .object({
        /**
         * The language code of the translation.
         * @example "en" // English
         */
        languageCode: z.string().length(LANGUAGE_CODE_LENGTH).describe("The language code of the translation."),

        /**
         * The translated value.
         * @example "Hello" // English translation of "Hello"
         */
        value: z.string().describe("The translated value.")
    })
    .describe("Represents a translated string which can be used to store user defined translations.");
