import moment from "moment";
import * as React from "react";

const DATE_FORMAT_STRING = "YYYY-MM-DDTHH:mm";

/**
 * A hook to manage a date state. It will return a string with the date in the format "YYYY-MM-DDTHH:mm"
 * This format is used by the <input type="datetime-local" />.
 * @param initialDate The initial date to use. If it's not a valid date, the state will be an empty string.
 * @returns A tuple with the date state and the setter.
 */
export const useDateState = (initialDate: Date | string | undefined | null) => {
    const [date, setDate] = React.useState(() => formatDateToInputDate(initialDate));

    const setAndConvertDate = React.useCallback(
        (newDate: Date | string | undefined | null) => {
            const newDateMoment = moment(newDate);
            if (newDateMoment.isValid()) {
                setDate(newDateMoment.format(DATE_FORMAT_STRING));
            } else {
                setDate("");
            }
        },
        [setDate]
    );

    const withTimezone = React.useCallback(() => {
        const dateMoment = moment(date);
        if (dateMoment.isValid()) {
            return dateMoment.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        }
        return "";
    }, [date]);

    return [date, setAndConvertDate, withTimezone] as const;
};

/**
 * Formats a date to a string that a <input type="datetime-local" /> can understand.
 * @param date The date to format
 * @returns A formatted date (YYYY-MM-DDTHH:mm) that a <input type="datetime-local" /> can understand
 */
export const formatDateToInputDate = (date: Date | string | undefined | null): string => {
    const initialDateMoment = moment(date);
    return initialDateMoment.isValid()
        ? initialDateMoment.format(DATE_FORMAT_STRING) // -> 2023-05-03T16:02
        : "";
};
