import { Toaster as FluentToaster } from "@fluentui/react-components";
import { DEFAULT_TOAST_DURATION, DEFAULT_TOAST_LIMIT } from "@weddinggram/common";
import * as React from "react";

export const TOASTER_ID = "toaster" as const;

export const Toaster: React.FC = () => {
    return (
        <FluentToaster
            toasterId={TOASTER_ID}
            limit={DEFAULT_TOAST_LIMIT}
            timeout={DEFAULT_TOAST_DURATION}
            pauseOnHover
        />
    );
};
