import { INVITATION_FIRST_NAME_PLACEHOLDER, INVITATION_LAST_NAME_PLACEHOLDER } from "@weddinggram/common";
import { useTranslation } from "@weddinggram/i18n";
import type { Invitation } from "@weddinggram/model";
import * as React from "react";
import type { GuestDetails } from "../../InvitationContext";
import { useInvitationContext } from "../../InvitationContext";
import { useInvitationStyles } from "../Invitation.styles";

const firstNameRegex = new RegExp(`{{${INVITATION_FIRST_NAME_PLACEHOLDER}}}`, "g");
const lastNameRegex = new RegExp(`{{${INVITATION_LAST_NAME_PLACEHOLDER}}}`, "g");

/**
 * Formats the header title of the invitation by replacing the placeholders with the first guest's name.
 * @param headerTitle The title of the invitation
 * @param guestDetails The details of the guests
 * @returns The formatted header title
 */
const formatHeaderTitle = (headerTitle: string, guestDetails: GuestDetails[]) => {
    const firstGuestName = guestDetails[0]?.name;

    const nameComponents = firstGuestName?.split(" ");
    const firstName = nameComponents?.shift();
    if (!firstGuestName || !firstName) {
        // There's no first guest name, so we can't format the header title
        // instead, we'll just return the header title as is but with the placeholders removed
        return headerTitle.replace(firstNameRegex, "").replace(lastNameRegex, "");
    }

    const lastName = nameComponents.length > 0 ? nameComponents.join(" ") : "";
    return headerTitle.replace(firstNameRegex, firstName).replace(lastNameRegex, lastName);
};

/**
 * Displays the header title of the invitation.
 *
 * e.g. "Dear {{first_name}} {{last_name}}"
 * @param param0 The invitation to display the header title for
 * @returns The header title of the invitation
 */
export const InvitationHeaderTitle: React.FC<{ invitation: Invitation }> = ({ invitation }) => {
    const invitationClasses = useInvitationStyles();
    const { guestDetails } = useInvitationContext();
    const { i18n } = useTranslation();
    const headerTitles = invitation.headerTitles;

    if (!headerTitles || headerTitles.length === 0) {
        return null;
    }

    // Get the correct header title based on the user language. If the language is not found, default to the first header title
    const headerTitle =
        headerTitles.find((title) => title.languageCode === i18n.language)?.value || headerTitles[0].value;

    const formattedHeaderTitle = formatHeaderTitle(headerTitle, guestDetails);
    return formattedHeaderTitle.split("\n").map((line, index) => (
        <div
            className={index === 0 ? invitationClasses.upperCaseText : invitationClasses.italicText}
            key={`headerLine${index}`}
        >
            {line}
        </div>
    ));
};
