import type { ToastIntent } from "@fluentui/react-components";
import { Caption1Strong, Caption2 } from "@fluentui/react-components";
import { logsPath } from "@weddinggram/common";
import { Flex } from "@weddinggram/common-ui";
import { CANCELLED_EXCEPTION_NAME } from "@weddinggram/exceptions";
import { useTranslation } from "@weddinggram/i18n";
import { errorToString } from "@weddinggram/i18n/src/errorToString";
import { Logger } from "@weddinggram/telemetry-core";
import { useToast } from "@weddinggram/ui-toast";
import type { ElementOrTextProp } from "@weddinggram/ui-toast/src/Toast";
import type { ToastAction } from "@weddinggram/ui-toast/src/ToastFooter";
import * as React from "react";

/**
 * Hook for handling errors in the UI.
 * @returns A function that can be used to handle errors in the UI.
 */
export const useErrorHandler = () => {
    const { dispatchToast } = useToast();
    const { t } = useTranslation("common");

    /**
     * Handles an error in the UI by displaying an error toast.
     * @param userMessage Additional message to display to the user
     * @param error Error to display to the user
     * @param showAsError If true, the error will be displayed as an error toast. If false, it will be displayed as a warning toast.
     * @param properties Additional properties to log to the console.
     * @param retryAction Optional action to retry the operation that caused the error.
     */
    return React.useCallback(
        (
            userMessage: string,
            error: unknown,
            severity: ToastIntent = "error",
            properties?: Record<string, unknown>,
            retryAction?: () => void
        ) => {
            const { message, stack, name } = errorToString(error);
            Logger.error(`${userMessage}`, { ...properties, error, name, stack });

            if (name === CANCELLED_EXCEPTION_NAME) {
                return;
            }

            const toastContent: ElementOrTextProp = {
                element: (
                    <Flex useVertical>
                        <Caption1Strong>{message}</Caption1Strong>
                        <Caption2>{stack[0]}</Caption2>
                    </Flex>
                ),
                type: "element"
            };

            const actions: ToastAction[] = [
                {
                    label: t("error.openLogs"),
                    onClick: () => {
                        window.open(`${window.location.origin}${logsPath}`, "_blank", "rel=noopener noreferrer");
                    }
                }
            ];
            if (retryAction) {
                actions.push({
                    label: t("label.retry"),
                    onClick: retryAction
                });
            }

            dispatchToast(userMessage, toastContent, severity, actions);
        },
        [dispatchToast, t]
    );
};
