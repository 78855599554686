import { useDarkMode } from "@weddinggram/hooks";
import * as React from "react";

interface ThemeContextProps {
    /**
     * Flag indicating if dark mode is enabled.
     */
    isDarkModeEnabled: boolean | null;

    /**
     * Function to set the dark mode flag.
     */
    // eslint-disable-next-line no-magic-numbers
    setIsDarkModeEnabled: ReturnType<typeof useDarkMode>[1] | null;

    /**
     * React transition state of changing the dark mode.
     */
    isPending: boolean;
}

/**
 * The theme context.
 */
export const ThemeContext = React.createContext<ThemeContextProps>({
    isDarkModeEnabled: null,
    setIsDarkModeEnabled: null,
    isPending: false
});

/**
 * Theme provider that provides the theme context and provides the dark mode flag.
 * @param param0 Children to render.
 * @returns The theme provider.
 */
export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isDarkModeEnabled, setIsDarkModeEnabled, isPending] = useDarkMode();
    const value = React.useMemo<ThemeContextProps>(
        () => ({ isDarkModeEnabled, setIsDarkModeEnabled, isPending }),
        [isDarkModeEnabled, setIsDarkModeEnabled, isPending]
    );

    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};
