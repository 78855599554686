import { Link, Text } from "@fluentui/react-components";
import * as React from "react";

export type LocationProps = {
    location: string | null | undefined;
};

export const isLink = (testString: string) => {
    const urlRegex = /^https?:\/\/[^\s$.?#].[^\s]*$/;
    return urlRegex.test(testString);
};

export const isGoogleMapsLink = (testString: string) => {
    const mapsRegex = /^https:\/\/www\.google\.com\/maps\/@\?api=1(&[a-z_]+=[^&]+)+$/;
    const tinyMapsRegex = /^https:\/\/goo\.gl\/maps\/[a-zA-Z0-9]{6}$/;
    return mapsRegex.test(testString) || tinyMapsRegex.test(testString);
};

export const Location: React.FC<LocationProps> = (props) => {
    const { location } = props;
    if (!location) {
        return null;
    }

    const isLocationLink = isLink(location);

    return isLocationLink ? <Link href={location}>{location}</Link> : <Text>{location}</Text>;
};
