import isBrowser from "./isBrowser";
import { roundTo } from "./roundTo";

const DEFAULT_REM_SIZE_IN_PX = 16;
const REM_PRECISION = 4;

let _documentRemSize: number | null = null;

const getDocumentRemSize = (): number => {
    return isBrowser()
        ? // eslint-disable-next-line no-undef
          getFontSizeValue(getComputedStyle(document.documentElement).fontSize) || DEFAULT_REM_SIZE_IN_PX
        : DEFAULT_REM_SIZE_IN_PX;
};

const getFontSizeValue = (size?: string | null): number | null => {
    return (size && parseFloat(size)) || null;
};

/**
 * Converts the provided px size to rem based on the default font size of 16px unless
 * the HTML font size has been previously defined with setHTMLFontSize().
 * @param valueInPx - The px value to convert to rem.
 * @param baseRemSize - Rem size to use for conversions. Optional - document's font size will be taken otherwise.
 * @example
 * ```
 * // Returns '1rem' for default document font size (16px).
 * px2Rem(16)
 *
 * // Returns '2rem'.
 * px2Rem(32, 16)
 * ```
 * @returns The value converted to the rem.
 * @see https://github.com/microsoft/fluent-ui-react/blob/master/packages/react/src/utils/fontSizeUtility.ts
 */
export const px2Rem = (valueInPx: number, baseRemSize?: number): string => {
    if (!baseRemSize && !_documentRemSize) {
        // There is no way how to reset the cached value
        // Invalidating the cache is not possible as resetting cached value won't trigger recalculation of site variables,
        // For which originally computed values will stay unchanged
        _documentRemSize = getDocumentRemSize();
    }

    const remSize = baseRemSize || _documentRemSize || DEFAULT_REM_SIZE_IN_PX;
    const convertedValueInRems = valueInPx / remSize;

    return `${roundTo(convertedValueInRems, REM_PRECISION)}rem`;
};
