import * as React from "react";
import { useInvitationStyles } from "./Invitation.styles";
import type { InvitationStyles } from "./PageColors";

type CoverFontProps = Pick<InvitationStyles, "foregroundColor">;

/**
 * A component that displays a vertical separator on the invitation
 * @param param0 Color of the font
 * @returns A vertical separator
 */
export const VerticalSeparator: React.FC<CoverFontProps> = ({ foregroundColor }) => {
    const classes = useInvitationStyles();
    return (
        <div style={foregroundColor} className={classes.separator} role="separator">
            |
        </div>
    );
};
