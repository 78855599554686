import { EMPTY_UUID } from "@weddinggram/common";
import { z } from "zod";
import { BaseEntitySchema } from "./BaseEntity";
import { DateTimeOffsetSchema } from "./DateTimeOffset";
import { GuestTagSchema } from "./GuestTag";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const CHANGE_TOKEN_LENGTH = 15 as const;

const AttendanceStatusSchema = z.enum(["Attending", "NotAttending", "Invited", "Unknown", "NoChange"]);

/**
 * Represents the status of an attendance.
 * - Attending: The user is attending the wedding.
 * - NotAttending: The user is not attending the wedding.
 * - Invited: The user is invited to the wedding but has not yet responded.
 * - Unknown: The user's attendance status is unknown.
 */
export type AttendanceStatus = z.infer<typeof AttendanceStatusSchema>;

/**
 * Represents a user's attendance to a wedding.
 */
export const AttendanceSchema = BaseEntitySchema.extend({
    /**
     * An attendance can be linked to another attendance.
     * This is used when the user is attending with a partner.
     */
    linkedAttendanceId: z.string().uuid().nullable(),

    /**
     * The id of the wedding this attendance is for.
     */
    weddingId: z.string().uuid(),

    /**
     * The id of the user that is attending.
     * Can be null if the user is not logged in or was not created yet.
     */
    userId: z
        .string()
        .uuid()
        .nullable()
        .transform((id) => (id === EMPTY_UUID ? null : id)),

    /**
     * The email address of the user that is attending.
     * Can be null if the user is not logged in, was not created yet or the user did not provide an email address.
     */
    userEmail: z.string().email().nullable(),

    /**
     * The display name of the user that is attending.
     */
    userDisplayName: z.string().describe("The display name of the user that is attending."),

    /**
     * The status of the attendance.
     */
    status: AttendanceStatusSchema.describe("The status of the attendance."),

    /**
     * The comment the user provided.
     */
    comment: z.string().nullable(),

    /**
     * The change token is used to identify the attendance record when updating it.
     * It is a random string of 15 characters.
     */
    changeToken: z.union([z.string().length(CHANGE_TOKEN_LENGTH), z.string().length(0)]).nullable(),

    /**
     * The date and time the attendance was last opened by the invited user.
     */
    lastOpened: DateTimeOffsetSchema.nullable(),

    /**
     * A QR code that can be used as a shortcut to the invitation and attendance.
     */
    qrAccessCode: z.string().nullable(),

    /**
     * The tags that the user has created for the reply.
     */
    tags: z.array(GuestTagSchema).nullable().describe("The tags that the user has created for the reply.")
});
