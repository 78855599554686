import { z } from "zod";

const RGBA_REGEX = /^#[0-9A-F]{6}[0-9a-f]{0,2}$/i;

/**
 * Schema for validating RGBA color strings.
 * @example "#FFF000F"
 */
export const RgbaSchema = z.string().refine(
    (value) => {
        return RGBA_REGEX.test(value);
    },
    {
        message: "Invalid color format. Expected format: '#RRGGBBAA'"
    }
);
