import { UserCreationException } from "./UserCreationException";

export const USER_ALREADY_EXISTS_EXCEPTION_NAME = "UserAlreadyExistsException";

export class UserAlreadyExistsException extends UserCreationException {
    constructor(email: string, requestId: string, innerException?: unknown, properties?: Record<string, unknown>) {
        super(email, requestId, `The user with ${email} already exists.`, innerException, properties);
        this.name = USER_ALREADY_EXISTS_EXCEPTION_NAME;
    }
}

export const isUserAlreadyExistsApiException = (error: unknown): boolean => {
    return (
        error !== null &&
        error !== undefined &&
        typeof error === "object" &&
        "message" in error &&
        typeof error.message === "string" &&
        error.message.includes("UserAlreadyExists")
    );
};
