import { Button, makeStyles, mergeClasses, shorthands, tokens } from "@fluentui/react-components";
import { getWeddingJoinPath } from "@weddinggram/common";
import { Flex } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import { Logger } from "@weddinggram/telemetry-core";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useInvitationContext } from "../../InvitationContext";
import type { InvitationPageProps } from "../../InvitationPageProps";
import type { CardBookPageProps } from "../../cardBookWrapper";
import { useInvitationStyles, useStaticInvitationStyles } from "../Invitation.styles";
import { useInvitationStyleVariables } from "../PageColors";
import { PageNavigation } from "../PageNavigation";

const usePageStyles = makeStyles({
    message: {
        ...shorthands.padding("1rem"),
        boxSizing: "border-box",
        textAlign: "center",
        fontSize: tokens.fontSizeBase600,
        lineHeight: tokens.lineHeightBase600,
        fontWeight: 100
    },
    confirmation: {
        marginTop: "1rem",
        ...shorthands.padding("1rem"),
        boxSizing: "border-box"
    }
});

const useOnNavigateToApp = (weddingId: string | undefined, redemptionCode: string | null) => {
    const navigate = useNavigate();

    return () => {
        if (!weddingId || !redemptionCode) {
            Logger.warn("[InvitationEnd] weddingId or redemptionCode is not set", weddingId, redemptionCode);
            return;
        }
        navigate(getWeddingJoinPath(weddingId, redemptionCode));
    };
};

/**
 * Page displayed in the invitation card book that shows the user a message either defined by the user
 * or a fallback with or without date.
 */
export const InvitationEnd = React.forwardRef(
    (props: CardBookPageProps<InvitationPageProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation("invitation");
        const classes = usePageStyles();
        useStaticInvitationStyles();
        const invitationClasses = useInvitationStyles();
        const cardStyles = useInvitationStyleVariables();
        const { guestDetails } = useInvitationContext();
        const onNavigateToApp = useOnNavigateToApp(props.pageProps.weddingId, props.pageProps.redemptionCode);

        const hasAcceptedInvitation = guestDetails?.[0]?.status === "Attending";
        let replyMessage: string;

        // The following code provides a better messaging by splitting list of guests in the thank you message
        // By doing this we can do the following "Linda, Felix and Benedikt" instead of "Linda, Felix, Benedikt"
        const numberOfGuests = guestDetails.length;
        const guestFirstNames = guestDetails.map((guest) => guest.name.split(" ")[0]);
        let lastGuestFirstName: string | undefined;
        if (numberOfGuests > 1) {
            lastGuestFirstName = guestFirstNames.pop();
        }

        const hasProvidedMail = guestDetails.some((guest) => Boolean(guest.email));

        if (hasAcceptedInvitation) {
            const formattedWeddingDate = props.pageProps.weddingDate
                ? moment(props.pageProps.weddingDate).format("LL")
                : null;
            if (props.pageProps.customAcceptMessage) {
                replyMessage = props.pageProps.customAcceptMessage.replace("{{date}}", formattedWeddingDate ?? "");
            } else {
                replyMessage = formattedWeddingDate
                    ? t("reply.confirm.accept.fallback.withDate", {
                          count: numberOfGuests,
                          guestNames: guestFirstNames.join(", "),
                          lastGuestName: lastGuestFirstName,
                          date: formattedWeddingDate
                      })
                    : t("reply.confirm.accept.fallback.noDate", {
                          count: numberOfGuests,
                          guestNames: guestFirstNames.join(", "),
                          lastGuestName: lastGuestFirstName
                      });
            }
        } else {
            replyMessage = props.pageProps.customDeclineMessage ?? t("reply.confirm.decline.fallback");
        }

        return (
            <div ref={ref}>
                <Flex
                    gap="gapLarge"
                    useVertical
                    style={cardStyles.backgroundColor}
                    fill
                    hAlign="center"
                    vAlign="center"
                    space="between"
                    className={props.isOnePageLayout ? undefined : invitationClasses.rightSideFold}
                >
                    <Flex
                        useVertical
                        className={mergeClasses(
                            invitationClasses.regularText,
                            invitationClasses.italicText,
                            classes.confirmation
                        )}
                        style={cardStyles.foregroundColor}
                        gap="gapSmall"
                    >
                        <div>{t("reply.send.success")}</div>
                        {hasProvidedMail && hasAcceptedInvitation && <div>{t("reply.mail.provided")}</div>}
                    </Flex>

                    <div className={classes.message} style={cardStyles.foregroundColor}>
                        {replyMessage}
                    </div>

                    {hasAcceptedInvitation && (
                        <Flex useVertical gap="gapMedium" style={cardStyles.foregroundColor}>
                            <div className={mergeClasses(invitationClasses.regularText, invitationClasses.italicText)}>
                                {t("reply.login.prompt")}
                            </div>
                            <Button
                                size="large"
                                appearance="subtle"
                                style={{ ...cardStyles.foregroundColor, ...cardStyles.accentBorderColor }}
                                onClick={onNavigateToApp}
                            >
                                {t("reply.login.button.label")}
                            </Button>
                        </Flex>
                    )}

                    <PageNavigation
                        {...cardStyles}
                        nextPage={props.nextPage}
                        prevPage={props.prevPage}
                        nextPageButtonLabel=""
                        showPreviousPageButton
                    />
                </Flex>
            </div>
        );
    }
);
InvitationEnd.displayName = "InvitationEnd";
