import { mergeClasses } from "@fluentui/react-components";
import * as React from "react";
import { useStyles } from "./Flex.styles";
import type { FlexProps } from "./FlexProps";
import { buildFlexClasses } from "./buildFlexClasses";

export const Flex: React.FC<FlexProps> = (props) => {
    const classes = useStyles();
    const classesToApply = buildFlexClasses(classes, props);

    return (
        <div
            data-testid={props["data-testid"]}
            className={mergeClasses(...classesToApply)}
            onClick={props.onClick}
            title={props.title}
            style={props.style}
        >
            {props.children}
        </div>
    );
};
