/* eslint-disable @typescript-eslint/no-magic-numbers */
import { tintColor } from "./tintColor";

/**
 * Converts a hex color to an RGB array.
 * @param hex The hex color string.
 * @returns Array of RGB values.
 */
function hexToRgb(hex: string): [number, number, number] {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
}

/**
 * Calculates the luminance of an RGB color.
 * @param r Red component (0-255).
 * @param g Green component (0-255).
 * @param b Blue component (0-255).
 * @returns Luminance value.
 */
function luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

/**
 * Determines the best high-contrast foreground color based on the luminance of the background color.
 * Uses the tintColor function for adjusting the color.
 * @param colorHex The background hex color string.
 * @returns The high-contrast foreground color in hex format.
 */
export function tintToHighContrast(colorHex: string): string {
    if (!/^#[0-9A-F]{6}$/i.test(colorHex)) {
        throw new Error("Invalid hex color format.");
    }

    const [r, g, b] = hexToRgb(colorHex);
    const lum = luminance(r, g, b);

    // If background is light, return dark color; if dark, return light color.
    return lum > 0.5 ? tintColor(-0.8, colorHex) : tintColor(0.8, colorHex);
}
