import { z } from "zod";
import { DateTimeOffsetSchema } from "./DateTimeOffset";
import { BaseWeddingSchema, MinimalUserSchema } from "./User";

/**
 * Represents the full wedding model.
 */
export const WeddingSchema = BaseWeddingSchema.extend({
    /**
     * The ISO date of the wedding.
     */
    date: DateTimeOffsetSchema.nullable(),

    /**
     * The location of the wedding.
     */
    location: z.string().nullable(),

    /**
     * The description of the wedding.
     */
    description: z.string().nullable(),

    /**
     * The code that guests can use to redeem their invitation
     */
    redemptionCode: z.string(),

    /**
     * The address to the header image of the wedding.
     */
    headerAddress: z.string().url().nullable(),

    /**
     * The address to the header image thumb of the wedding.
     */
    headerThumbAddress: z.string().url().nullable(),

    /**
     * A list of guests for the wedding.
     */
    guests: z.array(MinimalUserSchema)
});
