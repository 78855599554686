import { IMAGE_RETRIEVAL_TIMEOUT, isDev } from "@weddinggram/common";
import { NOT_FOUND_EXCEPTION_NAME } from "@weddinggram/exceptions";
import { useTranslation } from "@weddinggram/i18n";
import { Logger } from "@weddinggram/telemetry-core";
import { useAppInsights, useAppInsightsPerformance } from "@weddinggram/telemetry-react";
import * as React from "react";

import { unknownToError } from "@weddinggram/exceptions/utilities";
import { getMediaType } from "@weddinggram/model/utilities";
import { useErrorHandler } from "@weddinggram/ui-error-handling";
import { usePrevious } from "./usePrevious";
import { useServiceFactory } from "./useServiceFactory";
import { MOCK_LOAD_URL } from "@weddinggram/common/src/mockConstants";

type ImageFromApiHookData = {
    /**
     * The image encoded as base64 or undefined if the image could not be retrieved or is loading
     */
    mediaData: string | undefined;

    /**
     * Flag indicating if the image is currently loading.
     */
    isLoading: boolean;

    /**
     * Flag indicating if an error occurred while loading the image.
     */
    hasError: boolean;

    /**
     * Aborts the current image retrieval.
     */
    abort: (reason: string) => void;
};

/**
 * Hook to retrieve an image from the API.
 * @param imageUrl The url of the image to retrieve.
 * @returns An {@link ImageFromApiHookData|object} containing the image and status of retrieval.
 */
export const useImageFromApi = (
    imageUrl: string | undefined | null,
    fallbackImageUrl?: string | undefined | null,
    shouldLoadNow = true
): ImageFromApiHookData => {
    const factory = useServiceFactory();
    const { t } = useTranslation("common");

    const COULD_NOT_LOAD_ERROR_MESSAGE = t("image.notFound.alt");

    // Store image url as a ref so that we can compare if it changed
    const previousImageUrl = usePrevious(imageUrl);

    const { trackEvent } = useAppInsights();
    const { start: startDownloadMeasurement } = useAppInsightsPerformance("perf/image/download", { url: imageUrl });
    const [hasError, setHasError] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [imageData, setImageData] = React.useState<string | undefined>(undefined);
    const handleErrorInUi = useErrorHandler();
    const retryCountRef = React.useRef<number>(0);

    const abortController = React.useRef<AbortController | undefined>(undefined);

    const imageUrlDefined = imageUrl !== undefined && imageUrl !== null;
    const notLoading = !isLoading;
    const noError = !hasError;
    const imageNotLoaded = !imageData;
    const notSameUrl = imageUrl !== previousImageUrl;
    const shouldLoadImage = (imageNotLoaded || notSameUrl) && notLoading && noError && imageUrlDefined && shouldLoadNow;

    const abort = React.useCallback((reason: string) => {
        Logger.log("aborting image download", { reason });
        abortController.current?.abort(reason);
    }, []);

    const retryFallback = React.useCallback(
        async (abortSignal: AbortSignal) => {
            let timedOut = false;
            const timeoutId = setTimeout(() => {
                timedOut = true;
                abort(`fallback timeout after ${IMAGE_RETRIEVAL_TIMEOUT}ms`);
                setHasError(true);
                setImageData(undefined);
                setIsLoading(false);
                trackEvent("image/download/cancel");
            }, IMAGE_RETRIEVAL_TIMEOUT);

            if (!fallbackImageUrl || retryCountRef.current > 0) {
                return;
            }

            try {
                retryCountRef.current++;
                const endMeasurement = startDownloadMeasurement();
                const image = await factory.mediaService.getImage(fallbackImageUrl, abortSignal);
                if (!timedOut) {
                    endMeasurement();
                    clearTimeout(timeoutId);
                    setImageData(image);
                    setHasError(false);
                }
            } catch (error) {
                clearTimeout(timeoutId);
                if (!isDev()) {
                    handleErrorInUi(COULD_NOT_LOAD_ERROR_MESSAGE, error, "error", { url: fallbackImageUrl });
                }
                setHasError(true);
            } finally {
                setIsLoading(false);
            }
        },
        [
            fallbackImageUrl,
            factory,
            abort,
            trackEvent,
            startDownloadMeasurement,
            COULD_NOT_LOAD_ERROR_MESSAGE,
            handleErrorInUi
        ]
    );

    React.useEffect(() => {
        const retrieveImageAsync = async () => {
            if (!shouldLoadImage) {
                return;
            }

            // Do not load media if the url is the mock url
            if (imageUrl === MOCK_LOAD_URL) {
                Logger.log("mock image url used, not loading");
                setIsLoading(true);
                setHasError(false);
                setImageData(undefined);
                return;
            }

            // Do not load media if the url contains a video extension
            if (getMediaType(imageUrl) === "Video") {
                setIsLoading(false);
                setHasError(false);
                setImageData(undefined);
                return;
            }

            let timedOut = false;
            const timeoutId = setTimeout(() => {
                timedOut = true;
                abort(`full timeout after ${IMAGE_RETRIEVAL_TIMEOUT}ms URL: ${imageUrl}`);
                Logger.warn(`[useImageFromApi] full timeout of ${imageUrl} after ${IMAGE_RETRIEVAL_TIMEOUT}ms`);
                setHasError(true);
                setImageData(undefined);
                setIsLoading(false);
                trackEvent("image/download/cancel");
            }, IMAGE_RETRIEVAL_TIMEOUT);

            abortController.current = new AbortController();
            setIsLoading(true);
            try {
                const endMeasurement = startDownloadMeasurement();
                const image = await factory.mediaService.getImage(imageUrl, abortController.current.signal);
                if (!timedOut) {
                    clearTimeout(timeoutId);
                    endMeasurement();
                    setImageData(image);
                    setHasError(false);
                }
            } catch (error) {
                clearTimeout(timeoutId);

                const errorObj = unknownToError(error);
                Logger.error(
                    `[useImageFromApi] error loading image ${imageUrl}. Error message: ${errorObj?.message}`,
                    error
                );

                if (errorObj.name === NOT_FOUND_EXCEPTION_NAME) {
                    await retryFallback(abortController.current.signal);
                } else {
                    if (!isDev()) {
                        handleErrorInUi(COULD_NOT_LOAD_ERROR_MESSAGE, error, "warning", { url: imageUrl });
                    }
                    setHasError(true);
                }
            } finally {
                setIsLoading(false);
            }
        };
        retrieveImageAsync();
    }, [
        shouldLoadImage,
        imageUrl,
        fallbackImageUrl,
        factory,
        imageData,
        isLoading,
        abort,
        trackEvent,
        startDownloadMeasurement,
        retryFallback,
        COULD_NOT_LOAD_ERROR_MESSAGE,
        handleErrorInUi
    ]);

    return React.useMemo(
        () => ({
            mediaData: imageData,
            isLoading,
            hasError,
            abort
        }),
        [imageData, isLoading, hasError, abort]
    );
};
