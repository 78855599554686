import { createSlice } from "@reduxjs/toolkit";
import {
    UserAlreadyExistsException,
    UserCreationException,
    isUserAlreadyExistsApiException
} from "@weddinggram/exceptions";
import { errorToString } from "@weddinggram/i18n";
import type { Attendance, DtoEntity, DtoEntityWithId, GuestTag } from "@weddinggram/model";
import type { UserCreationRequestWithAsOwner } from "@weddinggram/model/requests";
import type { AttendanceStatus } from "@weddinggram/model/schemas";
import type { UpdatableAttendanceProperties } from "@weddinggram/service/src/api/attendance/IAttendanceService";
import { Logger } from "@weddinggram/telemetry-core";
import type { RootState } from "../store/store";
import { createAsyncThunkWithServiceFactory } from "../thunks";
import { isRejectedPromiseWithValue } from "../utilities";
import { IDLE_STATE, type RequestStatusForWedding } from "../utilities/RequestStatus";

export type AttendanceState = {
    /**
     * Indicates whether the attendance replies are currently being retrieved from the server
     */
    retrieveStatus: RequestStatusForWedding;

    /**
     * Indicates whether the attendance replies are currently being deleted from the server
     */
    deleteStatus: RequestStatusForWedding;

    /**
     * Indicates whether the attendance replies are currently being updated on the server
     */
    updateStatus: RequestStatusForWedding;

    /**
     * Indicates whether the attendance replies are currently being created on the server
     */
    createStatus: RequestStatusForWedding;

    /**
     * Error object that is set when an error occurs.
     */
    error: unknown | null;

    /**
     * The error that occurred during the last request.
     * The key is the request id and the value is the error.
     */
    multiCreateErrors: Record<string, unknown> | null;

    /**
     * The list of retrieved attendances where the key is the wedding id.
     */
    retrievedAttendances: Record<string, Attendance[] | null>;

    /**
     * The status of the tag retrieval.
     */
    tagRetrieveStatus: RequestStatusForWedding;

    /**
     * The status of the tag update.
     * The key is the wedding id.
     */
    tagUpdateStatus: RequestStatusForWedding;

    /**
     * The status of the tag creation.
     * The key is the request id.
     */
    tagCreateStatus: RequestStatusForWedding;

    /**
     * The tags for a given wedding.
     */
    tags: Record<string, GuestTag[] | null>;

    /**
     * The id of the tag that was created by weddingId
     */
    createdTagId: Record<string, string | null>;
};

const initialState: AttendanceState = {
    retrieveStatus: {},
    createStatus: {},
    deleteStatus: {},
    updateStatus: {},
    error: null,
    multiCreateErrors: null,
    retrievedAttendances: {},
    tagRetrieveStatus: {},
    tagUpdateStatus: {},
    tagCreateStatus: {},
    tags: {},
    createdTagId: {}
};

const NO_RESULT = [] as const;
const NO_RESULT_NULL = null;

type RetrieveAttendancesByWeddingIdPayload = {
    /**
     * The wedding id for which the attendances should be retrieved.
     */
    weddingId: string;

    /**
     * If `true` the cache will be ignored and the data will be fetched from the server.
     */
    forceCacheRefresh: boolean;
};

/**
 * Retrieves all the attendance replies for the given wedding.
 * This method only works for the owner(s) of the wedding.
 */
export const retrieveAttendancesByWeddingId = createAsyncThunkWithServiceFactory(
    "attendance/getByWeddingId",
    async (payload: RetrieveAttendancesByWeddingIdPayload, { extra, signal }) => {
        return await extra.attendanceService.getAllAttendancesForWedding(
            payload.weddingId,
            payload.forceCacheRefresh,
            signal
        );
    }
);

/**
 * Retrieves all the positive attendance replies for the given wedding.
 * This method works for all guests of the wedding.
 */
export const retrievePositiveAttendancesByWeddingId = createAsyncThunkWithServiceFactory(
    "attendance/getPositiveByWeddingId",
    async (weddingId: string, { extra, signal }) => {
        return await extra.attendanceService.getAllPositiveAttendancesForWedding(weddingId, signal);
    }
);

/**
 * Let's the host of the wedding create a single reply for the wedding.
 */
export const createSingleReply = createAsyncThunkWithServiceFactory(
    "attendance/createSingleReply",
    async (attendance: DtoEntity<Attendance>, { extra, signal }) => {
        return await extra.attendanceService.createSingleReply(attendance, signal);
    }
);

/**
 * Payload for creating multiple replies.
 */
type CreateMultipleRepliesPayload = {
    /**
     * The invitation replies to create including a flag indicating whether the user is the owner of the wedding.
     */
    replies: UserCreationRequestWithAsOwner[];

    /**
     * The wedding id for which the replies/users should be created.
     */
    weddingId: string;
};

export const createMultipleReplies = createAsyncThunkWithServiceFactory(
    "attendance/createMultipleReplies",
    async (payload: CreateMultipleRepliesPayload, { extra, signal }) => {
        const creationPromises = payload.replies.map(async ({ asOwner, requestId, ...attendanceProps }) => {
            const attendance: DtoEntity<Attendance> = {
                ...attendanceProps,
                weddingId: payload.weddingId,
                userId: null,
                userDisplayName: `${attendanceProps.firstName} ${attendanceProps.lastName}`,
                linkedAttendanceId: null,
                changeToken: null,
                qrAccessCode: null,
                comment: null,
                lastOpened: null,
                tags: []
            };

            try {
                const createdAttendance = await extra.attendanceService.createSingleReply(attendance, signal);
                Logger.log(`[attendanceSlice] Created attendance for wedding ${payload.weddingId}`);
                if (asOwner && createdAttendance.userId !== null) {
                    Logger.log(`[attendanceSlice] Adding owner for wedding ${payload.weddingId}`);
                    await extra.weddingService.addOwner(payload.weddingId, createdAttendance.userId, signal);
                }
                return createdAttendance;
            } catch (error) {
                if (isUserAlreadyExistsApiException(error)) {
                    throw new UserAlreadyExistsException(attendance.userEmail ?? "?", requestId);
                }
                throw new UserCreationException(
                    attendance.userEmail ?? "?",
                    requestId,
                    "An error occurred while creating the user.",
                    error
                );
            }
        });
        // eslint-disable-next-line sonarjs/prefer-immediate-return
        const result = await Promise.allSettled(creationPromises);
        return result;
    }
);

/**
 * Deletes the attendance for the given wedding.
 * This call will only succeed if the user is the owner of the wedding.
 */
export const deleteAttendance = createAsyncThunkWithServiceFactory(
    "attendance/delete",
    async ({ weddingId, attendanceId }: { weddingId: string; attendanceId: string }, { extra, signal }) => {
        return await extra.attendanceService.deleteAttendance(weddingId, attendanceId, signal);
    }
);

/**
 * Updates the attendance status for the given wedding.
 * This call will only succeed if the user is the owner of the wedding.
 */
export const updateAttendanceStatus = createAsyncThunkWithServiceFactory(
    "attendance/updateStatus",
    async (
        {
            weddingId,
            attendanceId,
            newStatus
        }: { weddingId: string; attendanceId: string; newStatus: AttendanceStatus },
        { extra, signal }
    ) => {
        return await extra.attendanceService.updateAttendanceStatus(weddingId, attendanceId, newStatus, signal);
    }
);

type UpdatePayload = {
    weddingId: string;
    attendanceId: string;
    update: UpdatableAttendanceProperties;
};

/**
 * Updates properties on the attendance for the given wedding by the host
 * To update the status, use the {@link updateAttendanceStatus} action.
 */
export const updateAttendance = createAsyncThunkWithServiceFactory(
    "attendance/update",
    async (payload: UpdatePayload, { extra, signal }) => {
        return await extra.attendanceService.updateAttendance(
            payload.weddingId,
            payload.attendanceId,
            payload.update,
            signal
        );
    }
);

type UpdateTagPayload = {
    weddingId: string;
    tagId: string;
    tag: DtoEntityWithId<GuestTag>;
};

/**
 * Updates an existing tag for the given wedding for all guests.
 */
export const updateGuestTag = createAsyncThunkWithServiceFactory(
    "attendance/tags/update",
    async (payload: UpdateTagPayload, { extra, signal }) => {
        return await extra.guestTagService.update(payload.weddingId, payload.tagId, payload.tag, signal);
    }
);

/**
 * Gets all tags for the given wedding.
 */
export const getAllTags = createAsyncThunkWithServiceFactory(
    "attendance/tags/getAll",
    async (weddingId: string, { extra, signal }) => {
        return await extra.guestTagService.getAll(weddingId, signal);
    }
);

type CreateTagPayload = {
    weddingId: string;
    tag: DtoEntity<GuestTag>;
};

/**
 * Creates a new tag without attaching it to any attendance.
 */
export const createTag = createAsyncThunkWithServiceFactory(
    "attendance/tags/create",
    async (payload: CreateTagPayload, { extra, signal }) => {
        return await extra.guestTagService.create(payload.weddingId, payload.tag, signal);
    }
);

type AddTagPayload = {
    weddingId: string;
    attendanceId: string;
    tag: DtoEntityWithId<GuestTag>;
};

/**
 * Adds a tag to the attendance for the given wedding.
 */
export const addTagToAttendance = createAsyncThunkWithServiceFactory(
    "attendance/tags/add",
    async (payload: AddTagPayload, { extra, signal }) => {
        return await extra.attendanceService.addTag(payload.weddingId, payload.attendanceId, payload.tag, signal);
    }
);

type RemoveTagPayload = {
    weddingId: string;
    attendanceId: string;
    tagId: string;
};

/**
 * Removes a tag from the attendance for the given wedding.
 */
export const removeTagFromAttendance = createAsyncThunkWithServiceFactory(
    "attendance/tags/remove",
    async (payload: RemoveTagPayload, { extra, signal }) => {
        return await extra.attendanceService.removeTag(payload.weddingId, payload.attendanceId, payload.tagId, signal);
    }
);

const attendanceSlice = createSlice({
    name: "attendance",
    initialState,
    reducers: {
        resetAttendanceRetrievalStatus: (state) => {
            Logger.debug("[attendanceSlice] Resetting retrieval status");
            state.retrieveStatus = {};
            state.error = null;
        },
        resetAttendanceCreateStatus: (state) => {
            Logger.debug("[attendanceSlice] Resetting create status");
            state.createStatus = {};
            state.multiCreateErrors = null;
            state.error = null;
        },
        resetTagUpdateStatus: (state) => {
            Logger.debug("[attendanceSlice] Resetting tag update status");
            state.tagUpdateStatus = {};
            state.tagCreateStatus = {};
            state.error = null;
            state.createdTagId = {};
        }
    },
    extraReducers: (builder) => {
        builder
            // RetrieveAttendancesByWeddingId
            .addCase(retrieveAttendancesByWeddingId.pending, (state, action) => {
                state.retrieveStatus[action.meta.arg.weddingId] = "loading";
                state.error = null;
                Logger.log(
                    `[attendanceSlice] ${retrieveAttendancesByWeddingId.pending.type} started (${action.meta.arg.forceCacheRefresh ? "force refresh" : ""})`
                );
            })
            .addCase(retrieveAttendancesByWeddingId.fulfilled, (state, action) => {
                state.retrieveStatus[action.meta.arg.weddingId] = "succeeded";
                state.retrievedAttendances[action.meta.arg.weddingId] = action.payload;

                // Add tags from the attendance to the list of tags if they are not already there
                const tagsFromAttendance = action.payload
                    // Add tags from all attendances
                    .flatMap((a) => a.tags ?? [])
                    // Make sure we only have unique tags. This filters out tags that are already in the list but with a different index
                    .filter((tag, index, self) => self.findIndex((t) => t.id === tag.id) === index);

                const existingTags = state.tags[action.meta.arg.weddingId] ?? [];
                const newTags = tagsFromAttendance.filter((tag) => !existingTags.some((t) => t.id === tag.id));
                state.tags[action.meta.arg.weddingId] = [...existingTags, ...newTags];
                Logger.log(`[attendanceSlice] ${retrieveAttendancesByWeddingId.fulfilled.type} succeeded`);
            })
            .addCase(retrieveAttendancesByWeddingId.rejected, (state, action) => {
                state.retrieveStatus[action.meta.arg.weddingId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${retrieveAttendancesByWeddingId.rejected.type} failed`, action.error);
            })

            // RetrievePositiveAttendancesByWeddingId
            .addCase(retrievePositiveAttendancesByWeddingId.pending, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${retrievePositiveAttendancesByWeddingId.pending.type} started`);
            })
            .addCase(retrievePositiveAttendancesByWeddingId.fulfilled, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "succeeded";
                state.retrievedAttendances[action.meta.arg] = action.payload;

                // Add tags from the attendance to the list of tags if they are not already there
                const tagsFromAttendance = action.payload
                    // Add tags from all attendances
                    .flatMap((a) => a.tags ?? [])
                    // Make sure we only have unique tags. This filters out tags that are already in the list but with a different index
                    .filter((tag, index, self) => self.findIndex((t) => t.id === tag.id) === index);

                const existingTags = state.tags[action.meta.arg] ?? [];
                const newTags = tagsFromAttendance.filter((tag) => !existingTags.some((t) => t.id === tag.id));
                state.tags[action.meta.arg] = [...existingTags, ...newTags];

                Logger.log(`[attendanceSlice] ${retrievePositiveAttendancesByWeddingId.fulfilled.type} succeeded`);
            })
            .addCase(retrievePositiveAttendancesByWeddingId.rejected, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "failed";
                state.error = action.error;
                Logger.error(
                    `[attendanceSlice] ${retrievePositiveAttendancesByWeddingId.rejected.type} failed`,
                    action.error
                );
            })

            // CreateSingleReply
            .addCase(createSingleReply.pending, (state, action) => {
                state.createStatus[action.meta.arg.weddingId] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${createSingleReply.pending.type} started`);
            })
            .addCase(createSingleReply.fulfilled, (state, action) => {
                state.createStatus[action.meta.arg.weddingId] = "succeeded";
                state.retrievedAttendances[action.meta.arg.weddingId] = state.retrievedAttendances[
                    action.meta.arg.weddingId
                ]?.concat(action.payload) ?? [action.payload];
                Logger.log(`[attendanceSlice] ${createSingleReply.fulfilled.type} succeeded`);
            })
            .addCase(createSingleReply.rejected, (state, action) => {
                state.createStatus[action.meta.arg.weddingId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${createSingleReply.rejected.type} failed`, action.error);
            })

            // CreateMultipleReplies
            .addCase(createMultipleReplies.pending, (state, action) => {
                state.createStatus[action.meta.arg.weddingId] = "loading";
                state.error = null;
                Logger.log(
                    `[attendanceSlice] ${createMultipleReplies.pending.type} started. RequestId: ${action.meta.requestId} - WeddingId: ${action.meta.arg.weddingId} - Users: ${action.meta.arg.replies.length}`
                );
            })
            .addCase(createMultipleReplies.fulfilled, (state, action) => {
                const failedUsers = action.payload.filter(isRejectedPromiseWithValue).map((result) => result.reason);

                state.multiCreateErrors = failedUsers.reduce(
                    (acc, error) => {
                        Logger.error(`[attendanceSlice] ${createMultipleReplies.fulfilled.type} failed`, error);
                        if ("requestId" in error && error.requestId && typeof error.requestId === "string") {
                            const serializedError = errorToString(error);
                            acc[error.requestId] = serializedError;
                        } else {
                            state.error = error;
                        }
                        return acc;
                    },
                    {} as Record<string, unknown>
                );

                state.createStatus[action.meta.arg.weddingId] = failedUsers.length > 0 ? "failed" : "succeeded";

                const fulfilledResults = action.payload.filter(
                    (p) => p.status === "fulfilled"
                ) as PromiseFulfilledResult<Attendance>[];
                state.retrievedAttendances[action.meta.arg.weddingId] =
                    state.retrievedAttendances[action.meta.arg.weddingId]?.concat(
                        fulfilledResults.map((p) => p.value)
                    ) ?? fulfilledResults.map((p) => p.value);
                Logger.log(
                    `[attendanceSlice] ${createMultipleReplies.fulfilled.type} succeeded. Created attendances: ${fulfilledResults.length}.`
                );
            })

            // DeleteAttendance
            .addCase(deleteAttendance.pending, (state, action) => {
                state.deleteStatus[action.meta.arg.weddingId] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${deleteAttendance.pending.type} started`);
            })
            .addCase(deleteAttendance.fulfilled, (state, action) => {
                state.deleteStatus[action.meta.arg.weddingId] = "succeeded";
                state.retrievedAttendances[action.meta.arg.weddingId] =
                    state.retrievedAttendances[action.meta.arg.weddingId]?.filter(
                        (a) => a.id !== action.meta.arg.attendanceId
                    ) ?? [];
                Logger.log(
                    `[attendanceSlice] ${deleteAttendance.fulfilled.type} succeeded. New attendance list length: ${state.retrievedAttendances[action.meta.arg.weddingId]?.length}`
                );
            })
            .addCase(deleteAttendance.rejected, (state, action) => {
                state.deleteStatus[action.meta.arg.weddingId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${deleteAttendance.rejected.type} failed`, action.error);
            })

            // UpdateAttendanceStatus
            .addCase(updateAttendanceStatus.pending, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${updateAttendanceStatus.pending.type} started`);
            })
            .addCase(updateAttendanceStatus.fulfilled, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "succeeded";
                state.retrievedAttendances[action.meta.arg.weddingId] =
                    state.retrievedAttendances[action.meta.arg.weddingId]?.map((a) =>
                        a.id === action.meta.arg.attendanceId ? action.payload : a
                    ) ?? [];
                Logger.log(`[attendanceSlice] ${updateAttendanceStatus.fulfilled.type} succeeded`);
            })
            .addCase(updateAttendanceStatus.rejected, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${updateAttendanceStatus.rejected.type} failed`, action.error);
            })

            // UpdateAttendance
            .addCase(updateAttendance.pending, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${updateAttendance.pending.type} started`);
            })
            .addCase(updateAttendance.fulfilled, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "succeeded";
                state.retrievedAttendances[action.meta.arg.weddingId] =
                    state.retrievedAttendances[action.meta.arg.weddingId]?.map((a) =>
                        a.id === action.meta.arg.attendanceId ? action.payload : a
                    ) ?? [];
                Logger.log(`[attendanceSlice] ${updateAttendance.fulfilled.type} succeeded`);
            })
            .addCase(updateAttendance.rejected, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${updateAttendance.rejected.type} failed`, action.error);
            })

            // UpdateTag
            .addCase(updateGuestTag.pending, (state, action) => {
                state.tagUpdateStatus[action.meta.arg.weddingId] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${updateGuestTag.pending.type} started`);
            })
            .addCase(updateGuestTag.fulfilled, (state, action) => {
                const weddingId = action.meta.arg.weddingId;
                const updatedTag = action.payload;
                const tagId = action.meta.arg.tagId;
                state.tagUpdateStatus[weddingId] = "succeeded";

                // We need to update the tag in two places:
                // 1. in the list of tags for the wedding
                // 2. any tag that is associated with an attendance

                // #1
                state.tags[weddingId] = state.tags[weddingId]?.map((t) => (t.id === tagId ? updatedTag : t)) ?? [];

                // #2
                const attendances = state.retrievedAttendances[weddingId];
                if (attendances) {
                    state.retrievedAttendances[weddingId] = attendances.map((a) => {
                        const tags = a.tags?.map((t) => (t.id === tagId ? updatedTag : t)) ?? [];
                        return { ...a, tags };
                    });
                }

                Logger.log(`[attendanceSlice] ${updateGuestTag.fulfilled.type} succeeded`);
            })
            .addCase(updateGuestTag.rejected, (state, action) => {
                state.tagUpdateStatus[action.meta.arg.weddingId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${updateGuestTag.rejected.type} failed`, action.error);
            })

            // GetAllTags
            .addCase(getAllTags.pending, (state, action) => {
                state.tagRetrieveStatus[action.meta.arg] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${getAllTags.pending.type} started`);
            })
            .addCase(getAllTags.fulfilled, (state, action) => {
                state.tagRetrieveStatus[action.meta.arg] = "succeeded";
                state.tags[action.meta.arg] = action.payload;
                Logger.log(`[attendanceSlice] ${getAllTags.fulfilled.type} succeeded`);
            })
            .addCase(getAllTags.rejected, (state, action) => {
                state.tagRetrieveStatus[action.meta.arg] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${getAllTags.rejected.type} failed`, action.error);
            })

            // CreateTag
            .addCase(createTag.pending, (state, action) => {
                state.tagCreateStatus[action.meta.requestId] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${createTag.pending.type} started`);
            })
            .addCase(createTag.fulfilled, (state, action) => {
                state.tagCreateStatus[action.meta.requestId] = "succeeded";
                state.tags[action.meta.arg.weddingId] = [
                    ...(state.tags[action.meta.arg.weddingId] ?? []),
                    action.payload
                ];
                state.createdTagId[action.meta.arg.weddingId] = action.payload.id;
                Logger.log(`[attendanceSlice] ${createTag.fulfilled.type} succeeded`);
            })
            .addCase(createTag.rejected, (state, action) => {
                state.tagCreateStatus[action.meta.requestId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${createTag.rejected.type} failed`, action.error);
            })

            // AddTagToAttendance - Adds a new or existing tag to the attendance
            .addCase(addTagToAttendance.pending, (state, action) => {
                state.tagCreateStatus[action.meta.requestId] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${addTagToAttendance.pending.type} started`);
            })
            .addCase(addTagToAttendance.fulfilled, (state, action) => {
                const weddingId = action.meta.arg.weddingId;
                const attendanceId = action.meta.arg.attendanceId;
                const updatedAttendance = action.payload;

                // This is the id of the tag that was added to the attendance
                // If it is undefined, the tag is new
                // If it is defined, the tag already existed and we should find it in the list of tags
                const tagIdPreAdd = action.meta.arg.tag.id;
                let newTag = updatedAttendance.tags?.find((t) => t.id === tagIdPreAdd);

                // We have an existing id but it's not part of the updated attendance
                if (tagIdPreAdd && !newTag) {
                    Logger.error(
                        `[attendanceSlice] ${addTagToAttendance.fulfilled.type} failed. Tag not found in updated attendance.`,
                        action.payload
                    );
                    state.error = new Error("Tag not found in updated attendance.");
                    state.tagCreateStatus[action.meta.requestId] = "failed";
                    return;
                }

                // Try to find the tag that matches all the attributes from the updated attendance object (since we can't match by id)
                const isNewTag = !newTag;
                if (!newTag) {
                    Logger.debug("[attendanceSlice] Tag not found by id. Searching by attributes from attendance.");
                    newTag = updatedAttendance.tags?.find(
                        (t) =>
                            t.tag === action.meta.arg.tag.tag &&
                            t.color === action.meta.arg.tag.color &&
                            t.icon === action.meta.arg.tag.icon
                    );

                    // If we still can't find the tag, set the error
                    if (!newTag) {
                        Logger.error(
                            `[attendanceSlice] ${addTagToAttendance.fulfilled.type} failed. Tag not found in updated attendance. Searched by attributes.`,
                            action.payload
                        );
                        state.error = new Error("Tag not found in updated attendance.");
                        state.tagCreateStatus[action.meta.requestId] = "failed";
                        return;
                    }

                    // Update the created tag id state
                    state.createdTagId[weddingId] = newTag.id;
                }

                // If we are here - we've found the tag that was added to the attendance
                // if isNewTag is true - it was a net new tag
                // if isNewTag is false - it was an existing tag
                Logger.debug(`[attendanceSlice] Tag found in updated attendance. New tag: ${isNewTag}`, newTag);

                state.tagCreateStatus[action.meta.requestId] = "succeeded";

                // This tag might already exist in the wedding wide tag list. If it doesn't
                // we need to add it.
                // 1. in the list of tags for the wedding
                // 2. the attendance

                // #1
                const existingTags = state.tags[weddingId] ?? [];
                if (!existingTags.some((t) => t.id === tagIdPreAdd)) {
                    state.tags[weddingId] = [...existingTags, newTag];
                }

                // #2 - update the attendance
                state.retrievedAttendances[weddingId] =
                    state.retrievedAttendances[weddingId]?.map((a) =>
                        a.id === attendanceId ? updatedAttendance : a
                    ) ?? [];
                Logger.log(`[attendanceSlice] ${addTagToAttendance.fulfilled.type} succeeded`);
            })

            .addCase(addTagToAttendance.rejected, (state, action) => {
                state.tagCreateStatus[action.meta.requestId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${addTagToAttendance.rejected.type} failed`, action.error);
            })

            // RemoveTagFromAttendance
            .addCase(removeTagFromAttendance.pending, (state, action) => {
                state.tagCreateStatus[action.meta.requestId] = "loading";
                state.error = null;
                Logger.log(`[attendanceSlice] ${removeTagFromAttendance.pending.type} started`);
            })

            .addCase(removeTagFromAttendance.fulfilled, (state, action) => {
                const weddingId = action.meta.arg.weddingId;
                const attendanceId = action.meta.arg.attendanceId;
                const tagIdToRemove = action.meta.arg.tagId;

                state.tagCreateStatus[action.meta.requestId] = "succeeded";

                // Was this the last attendance with this tag?
                const attendanceTags = state.retrievedAttendances[weddingId]?.flatMap((a) => a.tags) ?? [];
                const numberOfAttendancesWithThisTag = attendanceTags.filter((t) => t?.id === tagIdToRemove).length;

                // Remove if one or less attendances have this tag (there will always be one - the one we are removing)
                if (numberOfAttendancesWithThisTag <= 1) {
                    state.tags[weddingId] = state.tags[weddingId]?.filter((t) => t.id !== tagIdToRemove) ?? [];
                }

                // #2 - update the attendance
                state.retrievedAttendances[weddingId] =
                    state.retrievedAttendances[weddingId]?.map((a) =>
                        a.id === attendanceId
                            ? { ...a, tags: a.tags?.filter((tag) => tag.id !== tagIdToRemove) ?? [] }
                            : a
                    ) ?? [];
                Logger.log(`[attendanceSlice] ${removeTagFromAttendance.fulfilled.type} succeeded`);
            })
            .addCase(removeTagFromAttendance.rejected, (state, action) => {
                state.tagCreateStatus[action.meta.requestId] = "failed";
                state.error = action.error;
                Logger.error(`[attendanceSlice] ${removeTagFromAttendance.rejected.type} failed`, action.error);
            });
    }
});

export const attendanceReducer = attendanceSlice.reducer;
export const { resetAttendanceRetrievalStatus, resetAttendanceCreateStatus, resetTagUpdateStatus } =
    attendanceSlice.actions;

/**
 * Selects the attendances from the state.
 * @param weddingId The wedding id for which the attendances should be retrieved.
 * @returns The attendances that has been retrieved.
 */
export const selectAttendances = (weddingId: string | undefined) => (state: RootState) => {
    if (!weddingId) {
        return NO_RESULT;
    }
    return state.attendances.retrievedAttendances[weddingId];
};

/**
 * Selects a single attendance by wedding id and attendance id
 * @param weddingId The wedding id for which the attendance should be retrieved.
 * @param attendanceId The attendance id for which the attendance should be retrieved.
 * @returns The attendance that has been retrieved.
 */
export const selectAttendanceById =
    (weddingId: string | null | undefined, attendanceId: string | null | undefined) => (state: RootState) => {
        if (!weddingId || !attendanceId) {
            return null;
        }
        return state.attendances.retrievedAttendances[weddingId]?.find((a) => a.id === attendanceId) ?? null;
    };

/**
 * Selects the retrieval status of the attendances.
 * @param state The root state of the application.
 * @returns The status of the attendance retrieval.
 */
export const selectAttendanceRetrievalStatus = (weddingId: string | undefined) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.attendances.retrieveStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the create status of the attendances for a wedding.
 * @param weddingId The wedding id for which the attendance create status should be retrieved.
 * @returns The status of the attendance create action.
 */
export const selectAttendanceCreateStatus = (weddingId: string | null) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.attendances.createStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the update status of the attendances for a wedding.
 * @param weddingId The wedding id for which the attendance update status should be retrieved.
 * @returns The status of the attendance update action.
 */
export const selectAttendanceUpdateStatus = (weddingId: string | null) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.attendances.updateStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the delete status of the attendances for a wedding.
 * @param weddingId The wedding id for which the attendance delete status should be retrieved.
 * @returns The status of the attendance delete action.
 */
export const selectAttendanceDeleteStatus = (weddingId: string | null) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.attendances.deleteStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the error state
 * @param state The root state of the application
 * @returns The error state
 */
export const selectAttendanceError = (state: RootState) => state.attendances.error;

/**
 * Selects the error state for actions which created multiple replies
 * The key is the request id and the value is the error.
 * @param state The root state of the application
 * @returns The error state for actions which created multiple replies
 */
export const selectMultiCreateErrors = (state: RootState) => state.attendances.multiCreateErrors;

/**
 * Selects the retrieval status of tags for a given wedding.
 */
export const selectTagRetrievalStatus = (weddingId: string | null | undefined) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.attendances.tagRetrieveStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the update status of the tags for a given wedding.
 * @param weddingId The wedding id for which the update status of the tags should be retrieved.
 * @returns The update status of the tags for the given wedding.
 */
export const selectTagUpdateStatus = (weddingId: string | null) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.attendances.tagUpdateStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the create status of the tags for a given wedding.
 * @param requestId The id of the request for which the tag creation status should be retrieved.
 * @returns The create status of the tags for the given request id.
 */
export const selectTagCreateStatus = (requestId: string | null) => (state: RootState) => {
    if (!requestId) {
        return IDLE_STATE;
    }
    return state.attendances.tagCreateStatus[requestId] ?? IDLE_STATE;
};

/**
 * Selects the tags for a given wedding.
 * @param weddingId The wedding id for which the tags should be selected.
 * @returns A list of tags for the given wedding.
 */
export const selectTags = (weddingId: string | null | undefined) => (state: RootState) => {
    if (!weddingId) {
        return NO_RESULT;
    }
    return state.attendances.tags[weddingId] ?? NO_RESULT;
};

/**
 * Selects the id of all tags that are associated to an attendance by the given wedding and attendance id.
 * @param weddingId The wedding id for which the tags should be selected.
 * @param attendanceId The attendance id for which the tags should be selected.
 * @returns A list of tag ids for the given wedding and attendance.
 */
export const selectAttendanceTagIds =
    (weddingId: string | null, attendanceId: string | null | undefined) => (state: RootState) => {
        if (!weddingId || !attendanceId) {
            return NO_RESULT;
        }
        const attendance = state.attendances.retrievedAttendances[weddingId]?.find((a) => a.id === attendanceId);
        return attendance?.tags?.map((t) => t.id) ?? NO_RESULT;
    };

export const selectCreatedTagId = (weddingId: string | null) => (state: RootState) => {
    if (!weddingId) {
        return null;
    }
    return state.attendances.createdTagId[weddingId] ?? NO_RESULT_NULL;
};
