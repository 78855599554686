import type { useStyles } from "./Flex.styles";
import type { FlexProps } from "./FlexProps";

/**
 * Builds the class names to apply to the component based on the props passed in.
 * @param classes classes from useStyles
 * @param props props from Flex
 * @returns array of class names to apply to the component.
 */
export const buildFlexClasses = (classes: ReturnType<typeof useStyles>, props: FlexProps) => {
    const { useVertical, debug, fill, hFill, vFill, wrap, inline, padding, gap, hAlign, vAlign, space, className } =
        props;

    let classesToApply = [classes.root];

    useVertical && classesToApply.push(classes.column);
    debug && classesToApply.push(classes.debug);
    fill && classesToApply.push(classes.fill);
    hFill && classesToApply.push(classes.hFill);
    vFill && classesToApply.push(classes.vFill);

    wrap && classesToApply.push(classes.wrap);
    inline && classesToApply.push(classes.inline);
    padding && classesToApply.push(...[classes.paddingMedium, classes.padding]);

    classesToApply = addGap(gap, useVertical, classes, classesToApply);
    classesToApply = addAlign(hAlign, vAlign, useVertical, classes, classesToApply);

    if (space) {
        classesToApply.push(classes[`space${space}`]);
    }

    if (props.onClick) {
        classesToApply.push(classes.clickable);

        if (!props.hideOnClickHover) {
            classesToApply.push(classes.clickableHover);
        }
    }

    // Class name always needs to be last to override any styles.
    if (className) {
        classesToApply.push(className);
    }

    return classesToApply;
};

const addGap = (
    gap: FlexProps["gap"],
    useVertical: FlexProps["useVertical"],
    classes: ReturnType<typeof useStyles>,
    classesToApply: string[]
): string[] => {
    if (gap) {
        classesToApply.push(classes[gap]);

        useVertical ? classesToApply.push(classes.gapColumn) : classesToApply.push(classes.gapRow);
    }
    return classesToApply;
};

const addAlign = (
    hAlign: FlexProps["hAlign"],
    vAlign: FlexProps["vAlign"],
    useVertical: FlexProps["useVertical"],
    classes: ReturnType<typeof useStyles>,
    classesToApply: string[]
): string[] => {
    if (hAlign) {
        switch (hAlign) {
            case "start":
                classesToApply.push(classes.hAlignStart);
                break;
            case "center":
                classesToApply.push(classes.hAlignCenter);
                break;
            case "end":
                classesToApply.push(classes.hAlignEnd);
                break;
            case "stretch":
                classesToApply.push(classes.hAlignStretch);
                break;
        }
        useVertical ? classesToApply.push(classes.hAlignColumn) : classesToApply.push(classes.hAlignRow);
    }

    if (vAlign) {
        switch (vAlign) {
            case "start":
                classesToApply.push(classes.vAlignStart);
                break;
            case "center":
                classesToApply.push(classes.vAlignCenter);
                break;
            case "end":
                classesToApply.push(classes.vAlignEnd);
                break;
            case "stretch":
                classesToApply.push(classes.vAlignStretch);
                break;
        }
        useVertical ? classesToApply.push(classes.vAlignColumn) : classesToApply.push(classes.vAlignRow);
    }

    return classesToApply;
};
