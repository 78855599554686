import { makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { RouteConstants } from "@weddinggram/common";
import { Flex, TermsOfServiceText } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import type { Invitation } from "@weddinggram/model";
import type { AttendanceStatus } from "@weddinggram/model/schemas";
import { useAppInsights } from "@weddinggram/telemetry-react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import type { GuestDetails } from "../../InvitationContext";
import type { CardBookPageProps } from "../../cardBookWrapper";
import { useInvitationStyles, useStaticInvitationStyles } from "../Invitation.styles";
import { InvitationReplyUntil } from "../InvitationReplyUntil";
import { INVITATION_CSS_VARIABLE_ACCENT, useInvitationStyleVariables } from "../PageColors";
import { PageNavigation } from "../PageNavigation";
import { InvitationComment } from "./InvitationComment";
import { InvitationReplyButtons } from "./InvitationReplyButtons";

const usePageStyles = makeStyles({
    termsLinks: {
        color: `var(${INVITATION_CSS_VARIABLE_ACCENT})`
    },
    container: {
        ...shorthands.margin(0, "1rem")
    }
});

type InvitationConfirmationRendererProps = CardBookPageProps<Invitation> & {
    /**
     * Records the guest attendance sending a reply to the API.
     * @param guestDetails Guest details to send.
     * @returns void
     */
    sendGuestReply: (guestDetails: GuestDetails[]) => void;

    /**
     * Flag indicating that the request is currently being sent.
     */
    isLoading: boolean;

    /**
     * The details of the guests.
     * Each guest is represented by an object with a name and an email.
     */
    guestDetails: GuestDetails[];

    /**
     * Wether the reply has been sent.
     */
    invitationReplySent: boolean;

    /**
     * Changes the attendance status of the guests.
     * @param status True if guests are attending, false otherwise
     * @returns void
     */
    setGuestAttendance: (status: AttendanceStatus) => void;

    /**
     * Changes the comment of the guests.
     * @param comment The comment to set
     * @returns void
     */
    setGuestComment: (comment: string) => void;
};

/**
 * Page displayed in the invitation card book that allows the user to confirm or decline the invitation.
 */
export const InvitationConfirmationRenderer: React.FC<InvitationConfirmationRendererProps> = (props) => {
    const { t } = useTranslation("invitation");
    const { trackEvent } = useAppInsights();
    useStaticInvitationStyles();
    const pageClasses = usePageStyles();
    const invitationClasses = useInvitationStyles();
    const cardStyles = useInvitationStyleVariables(true);
    const { guestDetails, setGuestAttendance, invitationReplySent, setGuestComment } = props;
    const navigate = useNavigate();

    const onConfirmClick = () => {
        trackEvent("app/invite/accept", { inviteId: props.pageProps.id });
        setGuestAttendance("Attending");
        props.sendGuestReply(guestDetails.map((g) => ({ ...g, hasConfirmedAttendance: true, status: "Attending" })));
    };
    const onDeclineClick = () => {
        trackEvent("app/invite/decline", { inviteId: props.pageProps.id });
        setGuestAttendance("NotAttending");
        props.sendGuestReply(
            guestDetails.map((g) => ({ ...g, hasConfirmedAttendance: false, status: "NotAttending" }))
        );
    };

    const isAttending = guestDetails[0]?.status === "Attending";

    const onPrivacyPolicyClick = () => {
        trackEvent("app/invite/privacyPolicy", { inviteId: props.pageProps.id });
        // FIXME: this should be opening in a new window
        navigate("/" + RouteConstants.PRIVACY_POLICY);
    };

    const onTermsOfServiceClick = () => {
        trackEvent("app/invite/tos", { inviteId: props.pageProps.id });
        navigate("/" + RouteConstants.TERMS_OF_SERVICE);
    };

    const buttonsDisabled = props.isLoading || guestDetails.length === 0;

    return (
        <Flex
            gap="gapLarge"
            useVertical
            style={cardStyles.backgroundColor}
            fill
            hAlign="center"
            space="between"
            className={props.isOnePageLayout ? undefined : invitationClasses.rightSideFold}
        >
            <Flex useVertical>
                <InvitationReplyUntil
                    foregroundColor={cardStyles.foregroundColor}
                    replyUntil={props.pageProps.replyUntil}
                    asChangeReplyUntil
                />
                <TermsOfServiceText
                    translation={t("confirm.termsOfService.description")}
                    onNavigateToPrivacyPolicy={onPrivacyPolicyClick}
                    onNavigateToTermsOfService={onTermsOfServiceClick}
                    style={cardStyles.foregroundColor}
                    linkClassName={pageClasses.termsLinks}
                    className={mergeClasses(
                        invitationClasses.regularText,
                        invitationClasses.italicText,
                        pageClasses.container
                    )}
                />
            </Flex>

            <InvitationComment
                isLoading={props.isLoading}
                numberOfGuests={guestDetails.length}
                comment={guestDetails[0]?.comment}
                setGuestComment={setGuestComment}
            />

            <InvitationReplyButtons
                isAttending={isAttending}
                hasReplied={invitationReplySent}
                buttonsDisabled={buttonsDisabled}
                isLoading={props.isLoading}
                numberOfGuests={guestDetails.length}
                onConfirmClick={onConfirmClick}
                onDeclineClick={onDeclineClick}
            />
            <PageNavigation
                {...cardStyles}
                nextPage={props.nextPage}
                prevPage={props.prevPage}
                nextPageButtonLabel=""
                showPreviousPageButton={props.isOnePageLayout}
                showNextPageButton={invitationReplySent}
            />
        </Flex>
    );
};
