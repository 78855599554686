import type { ApiError } from "@weddinggram/model";
import { SeverityLevel } from "@weddinggram/telemetry-core";
import { BaseException } from "./BaseException";

export const API_EXCEPTION_NAME = "ApiException";

export class ApiException extends BaseException {
    constructor(
        public apiError: ApiError | null,
        message: string,
        innerException?: unknown,
        public readonly properties?: Record<string, unknown>
    ) {
        super(
            API_EXCEPTION_NAME,
            ApiException.composeMessage(message, apiError),
            SeverityLevel.Error,
            innerException,
            ApiException.getIdFromError(apiError)
        );
    }

    protected static composeMessage(message: string, apiError: ApiError | null): string {
        if (apiError === null) {
            return message;
        }

        return `[${apiError.StatusCode}][${apiError.ErrorType}] ${message} <|> ${apiError.Message} <|> ${apiError.InnerExceptionMessage}`;
    }

    private static getIdFromError(error: ApiError | null): string | undefined {
        if (error === null) {
            return undefined;
        }

        return error.Id;
    }

    public static isApiException(error: unknown): error is ApiException {
        return (
            error !== null &&
            error !== undefined &&
            (error instanceof ApiException || (error as ApiException)?.name === API_EXCEPTION_NAME)
        );
    }
}
