import type { ToastIntent } from "@fluentui/react-components";
import { useId, useToastController } from "@fluentui/react-components";
import { DEFAULT_TOAST_DURATION } from "@weddinggram/common";
import { useTranslation } from "@weddinggram/i18n";
import { Logger } from "@weddinggram/telemetry-core";
import * as React from "react";
import type { ElementOrTextProp } from "./Toast";
import { Toast } from "./Toast";
import type { ToastAction } from "./ToastFooter";
import { TOASTER_ID } from "./Toaster";

export const useToast = () => {
    const { dispatchToast, dismissToast, updateToast } = useToastController(TOASTER_ID);
    const toastId = useId("toast");
    const { t } = useTranslation("common");

    const [unmounted, setUnmounted] = React.useState(true);

    const dismiss = React.useCallback(() => dismissToast(toastId), [dismissToast, toastId]);

    /**
     * Manual way to dispatch a toast.
     * Gives the ability to customize the toast.
     * @param title The title of the toast. If undefined, no title will be displayed.
     * @param body The body of the toast. Can be either a React element or a string.
     * @param intent The intent of the toast. Can be either "success", "info", "warning", "error" or "avatar"
     * @param actions The actions of the toast.
     * @param timeout The timeout of the toast.
     */
    const dispatch = React.useCallback(
        (
            title: string | undefined,
            body: ElementOrTextProp,
            intent?: ToastIntent,
            actions?: ToastAction[],
            timeout = DEFAULT_TOAST_DURATION
        ) => {
            dispatchToast(<Toast title={title} body={body} actions={actions} />, {
                intent,
                timeout,
                toastId,
                onStatusChange: (_, { status }) => setUnmounted(status === "unmounted")
            });
        },
        [dispatchToast, toastId]
    );

    /**
     * Manual way to update a toast.
     */
    const update = React.useCallback(
        (
            title: string,
            body: ElementOrTextProp,
            actions?: ToastAction[],
            intent?: ToastIntent,
            timeout = DEFAULT_TOAST_DURATION
        ) => {
            // If the component is unmounted, don't update the toast.
            if (unmounted) {
                Logger.warn("Attempted to update a toast that is unmounted.");
                return;
            }
            updateToast({
                content: <Toast title={title} body={body} actions={actions} />,
                intent,
                toastId,
                timeout,
                onStatusChange: (_, { status }) => setUnmounted(status === "unmounted")
            });
        },
        [updateToast, toastId, unmounted]
    );

    /**
     * Shortcut to dispatch a toast with an error intent.
     */
    const error = React.useCallback(
        (body: string) => {
            dispatch(t("error.toast.title"), { type: "text", text: body }, "error");
        },
        [dispatch, t]
    );

    /**
     * Shortcut to dispatch a toast with a success intent.
     */
    const success = React.useCallback(
        (title: string, body: string | null = null) => {
            dispatch(title, { type: "text", text: body }, "success");
        },
        [dispatch]
    );

    return {
        dispatchToast: dispatch,
        updateToast: update,
        dismissToast: dismiss,
        errorToast: error,
        successToast: success
    };
};
