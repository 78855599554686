import type { Configuration } from "@azure/msal-browser";

/**
 * Configuration for Microsoft Authentication Library
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: AAD_CLIENT_ID,
        authority: `${AAD_INSTANCE}/${AAD_DOMAIN}/B2C_1_SignUp_SignIn`, // "https://weddingGram.b2clogin.com/weddingGram.onmicrosoft.com/<policy-name>/oauth2/v2.0/authorize", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: AAD_REDIRECT_URI,
        knownAuthorities: [`${AAD_INSTANCE}/${AAD_DOMAIN}/B2C_1_SignUp_SignIn`]
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
    telemetry: {
        application: {
            appName: APP_NAME,
            appVersion: APP_VERSION
        }
    }
};
