import { SeverityLevel } from "@weddinggram/telemetry-core";
import type { ZodError, ZodIssue } from "zod";
import { BaseException } from "./BaseException";

export const VALIDATION_EXCEPTION_NAME = "ValidationException";

export class ValidationException<TEntity> extends BaseException {
    constructor(
        public readonly validationError: ZodError<TEntity>,
        public readonly entityName: string
    ) {
        super(
            VALIDATION_EXCEPTION_NAME,
            `Could not parse entity '${entityName}'. ${validationError.message}`,
            SeverityLevel.Error
        );

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ValidationException.prototype);
        this.name = VALIDATION_EXCEPTION_NAME;

        // Make the zod error readable
        this.message = this.getValidationMessageFromZodError();
    }

    private getValidationMessageFromZodError() {
        const zodError = this.validationError.issues.map(this.formatZodIssue).join(", ");

        return `Could not parse entity '${this.entityName}'. ${zodError}`;
    }

    private formatZodIssue(issue: ZodIssue) {
        const path = issue.path.join(".");
        switch (issue.code) {
            case "too_small":
            case "too_big":
                return `[${path}] ${issue.message}`;
            case "invalid_type":
                return `[${path}] expected ${issue.expected} but got ${issue.received}`;
            case "invalid_enum_value":
                return `[${path}] expected one of ${issue.options.join(", ")} but got ${issue.received}`;
            case "invalid_union":
                return `[${path}] ${issue.unionErrors.map((error) => error.message).join(", ")}`;
            case "invalid_union_discriminator":
                return `[${path}] expected one of ${issue.options.join(", ")} but got ${issue.message}`;
            case "invalid_literal":
                return `[${path}] expected ${issue.expected} but got ${issue.received}`;
            case "invalid_arguments":
                return `[${path}] ${issue.argumentsError.message}`;
            case "invalid_return_type":
                return `[${path}] ${issue.returnTypeError.message}`;
            case "unrecognized_keys":
                return `[${path}] ${issue.keys.join(", ")}`;
            case "invalid_date":
                return `[${path}] ${issue.message}`;
            case "invalid_string":
                return `[${path}] ${issue.message}`;
            case "not_multiple_of":
                return `[${path}] ${issue.message}`;
            case "not_finite":
                return `[${path}] ${issue.message}`;
            case "invalid_intersection_types":
                return `[${path}] ${issue.message}`;
            case "custom":
                return `[${path}] ${issue.message}`;
            default:
                return "unknown error";
        }
    }
}
