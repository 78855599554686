import type { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import type { IApplicationInsights } from "@microsoft/applicationinsights-web";
import { AuthenticationException } from "@weddinggram/exceptions";
import { Logger, SeverityLevel } from "@weddinggram/telemetry-core";

import type {
    BearerAuthenticationHeader,
    IAuthenticationService,
    RedemptionCodeAuthenticationHeader
} from "./IAuthenticationService";
import type { UserTokenDetails } from "./UserTokenDetails";
import type { IdTokenClaims } from "./idTokenClaims";
import { idTokenClaims } from "./idTokenClaims";

export class MsalAuthenticationService implements IAuthenticationService {
    private wasAuthenticated = false;
    public idClaims: IdTokenClaims | null;
    private expiration: Date | null;
    private readonly logger = Logger.create("MsalAuthenticationService");

    constructor(
        private readonly msal: IPublicClientApplication,
        private readonly appInsights: IApplicationInsights
    ) {
        this.expiration = null;
        this.idClaims = null;
    }

    /**
     * Initializes the authentication service.
     */
    public async initialize(): Promise<void> {
        this.logger.log("Initializing");
        await this.msal.initialize();
        this.logger.log("Initialized");
    }

    /**
     * Gets the user token details if the user is authenticated.
     * @returns The user token details or null if the user is not authenticated.
     */
    getTokenDetails(): UserTokenDetails | null {
        if (!this.isAuthenticated() || this.idClaims === null) {
            this.logger.warn("getTokenDetails called when not authenticated");
            return null;
        }

        return {
            firstName: this.idClaims.given_name,
            lastName: this.idClaims.family_name,
            email: this.idClaims.emails,
            userId: this.idClaims.sub
        };
    }

    /**
     * Tries to acquire a token silently. If that fails, it will try to acquire a token via a popup.
     * @param scopes The scopes to acquire a token for.
     * @returns The access token.
     */
    async getAccessToken(scopes: string[]): Promise<string> {
        let result: AuthenticationResult;
        try {
            this.logger.log("Acquiring token silently");
            result = await this.getAccessTokenSilently(scopes);
        } catch (error) {
            this.logger.warn("Failed to acquire token silently", error);
            result = await this.handleSilentTokenAcquisitionError(error, scopes);
        }

        if (!this.wasAuthenticated) {
            this.wasAuthenticated = true;
            this.expiration = result.expiresOn;

            const claims = idTokenClaims.safeParse(result.idTokenClaims);
            if (claims.success) {
                this.idClaims = claims.data;
                this.appInsights.setAuthenticatedUserContext(this.idClaims.sub);
            } else {
                this.logger.warn("Failed to parse id token claims", claims.error);
                this.appInsights.trackException(
                    new AuthenticationException("Failed to parse id token claims", SeverityLevel.Warning, claims.error)
                );
            }
        }
        return result.accessToken;
    }

    /**
     *  Gets the request header for the given scopes.
     * @param scopes The scopes to request access to.
     */
    public async getAuthenticationHeader(
        scopes: string[]
    ): Promise<BearerAuthenticationHeader | RedemptionCodeAuthenticationHeader> {
        const token = await this.getAccessToken(scopes);
        return { Authorization: `Bearer ${token}` };
    }

    /**
     * Tries to acquire a token silently. If that fails, it will throw an error.
     * @param scopes The scopes to acquire a token for.
     */
    private async getAccessTokenSilently(scopes: string[]): Promise<AuthenticationResult> {
        const account = this.msal.getActiveAccount() ?? this.msal.getAllAccounts()[0];
        if (!account) {
            this.logger.error("Trying to acquire token silently, but no active account found");
            throw new Error("No active account");
        }
        return await this.msal.acquireTokenSilent({ scopes: scopes, account });
    }

    private async handleSilentTokenAcquisitionError(error: unknown, scopes: string[]): Promise<AuthenticationResult> {
        this.appInsights.trackException(
            new AuthenticationException("Failed to acquire token silently", SeverityLevel.Warning, error)
        );

        try {
            this.logger.log("Acquiring token via popup after silent acquisition failed");
            const result = await this.msal.acquireTokenPopup({ scopes: scopes });
            this.logger.log("Acquired token via popup successfully");
            return result;
        } catch (error) {
            return await this.handlePopupTokenAcquisitionError(error, scopes);
        }
    }

    private async handlePopupTokenAcquisitionError(
        popupAcquisitionError: unknown,
        scopes: string[]
    ): Promise<AuthenticationResult> {
        this.appInsights.trackException(
            new AuthenticationException(
                "Failed to acquire token via popup",
                SeverityLevel.Warning,
                popupAcquisitionError
            )
        );
        this.logger.log("Acquiring token via redirect after popup acquisition failed");

        try {
            await this.msal.acquireTokenRedirect({ scopes: scopes });
            this.logger.log("Acquired token via redirect successfully");
            const result = await this.msal.handleRedirectPromise();
            this.logger.log("Handled redirect promise successfully");

            if (!result) {
                this.logger.error("handleRedirectPromise returned null");
                throw new Error("handleRedirectPromise returned null");
            }
            return result;
        } catch (error) {
            const exception = new AuthenticationException(
                "Failed to acquire token via redirect. Previous popup token acquisition failed.",
                SeverityLevel.Error,
                error
            );
            this.logger.error(exception.message, exception);
            this.appInsights.trackException(exception);
            throw exception;
        }
    }

    /**
     * Returns true if the user was authenticated and the token is not expired.
     * @returns True if the user was authenticated and the token is not expired.
     */
    isAuthenticated(): boolean {
        return this.wasAuthenticated === true && this.expiration !== null && this.expiration > new Date();
    }
}
