import "@weddinggram/i18n/src/i18n";
import { ErrorBoundary } from "@weddinggram/ui-error-handling";
import { ThemeProvider } from "@weddinggram/ui-theme";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";

const container = document.getElementById("root") as HTMLElement;

const startApp = () => {
    return ReactDOM.createRoot(container).render(
        <React.StrictMode>
            <ErrorBoundary>
                <ThemeProvider>
                    <App />
                </ThemeProvider>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

startApp();
