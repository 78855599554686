import { createSlice } from "@reduxjs/toolkit";
import type { Agenda, AgendaItem, AgendaItemUpdate, DtoEntity } from "@weddinggram/model";
import { Logger } from "@weddinggram/telemetry-core";
import type { RootState } from "../store/store";
import { createAsyncThunkWithServiceFactory } from "../thunks";
import { IDLE_STATE, type RequestStatus, type RequestStatusForWedding } from "../utilities/RequestStatus";

/**
 * The state of the agenda slice
 */
export type AgendaState = {
    /**
     * Indicates whether the agenda is currently being retrieved from the server
     */
    retrieveStatus: RequestStatusForWedding;

    /**
     * Indicates whether the agenda is currently being updated on the server.
     */
    updateStatus: RequestStatus;

    /**
     * Error object that is set when an error occurs.
     */
    error: unknown | null;

    /**
     * The agendas that the user is a part of.
     * The key is the wedding id.
     */
    agendas: Record<string, Agenda>;
};

const initialState: AgendaState = {
    retrieveStatus: {},
    updateStatus: "idle",
    error: null,
    agendas: {}
};

/**
 * Retrieves the agenda for the given wedding
 */
export const retrieveAgenda = createAsyncThunkWithServiceFactory(
    "agenda/get",
    /**
     * Retrieves the agenda for the given wedding
     * @param weddingId The id of the wedding to retrieve the agenda for
     * @param param1 Injected extra parameters
     * @returns The agenda for the wedding including all agenda items
     */
    async (weddingId: string, { extra, signal }) => {
        return await extra.agendaService.getAgenda(weddingId, signal);
    }
);

/**
 * Payload for the {@link addAgendaItem} action
 */
type AddAgendaItemPayload = {
    /**
     * The id of the wedding to add the agenda item to
     */
    weddingId: string;

    /**
     * The id of the agenda to add the agenda item to
     */
    agendaId: string;

    /**
     * The agenda item to add
     */
    agendaItem: DtoEntity<AgendaItem>;
};

/**
 * Adds an agenda item to the agenda
 */
export const addAgendaItem = createAsyncThunkWithServiceFactory(
    "agenda/addItem",
    async (payload: AddAgendaItemPayload, { extra, signal }) => {
        return await extra.agendaService.addAgendaItem(payload.weddingId, payload.agendaId, payload.agendaItem, signal);
    }
);

type BaseAgendaItemPayload = {
    /**
     * The id of the wedding that the agenda item belongs to-
     */
    weddingId: string;

    /**
     * The id of the agenda item to update
     */
    agendaItemId: string;
};

type UpdateAgendaItemPayload = BaseAgendaItemPayload & {
    /**
     * The value to update the agenda item with
     */
    valueToUpdate: AgendaItemUpdate;
};

/**
 * Updates an agenda item.
 */
export const updateAgendaItem = createAsyncThunkWithServiceFactory(
    "agenda/updateItem",
    async (payload: UpdateAgendaItemPayload, { extra, signal }) => {
        return await extra.agendaItemService.update(
            payload.weddingId,
            payload.agendaItemId,
            payload.valueToUpdate,
            signal
        );
    }
);

/**
 * Payload for the {@link deleteAgendaItem} action
 */
type DeleteAgendaItemPayload = BaseAgendaItemPayload;

/**
 * Deletes an agenda item
 */
export const deleteAgendaItem = createAsyncThunkWithServiceFactory(
    "agenda/deleteItem",
    async (payload: DeleteAgendaItemPayload, { extra, signal }) => {
        await extra.agendaItemService.deleteAgendaItem(payload.weddingId, payload.agendaItemId, signal);
    }
);

export const agendaSlice = createSlice({
    name: "agenda",
    initialState,
    reducers: {
        // Add actions to reset the state here
        resetAgendaUpdateState: (state) => {
            if (state.updateStatus !== "idle") {
                state.updateStatus = "idle";
            }

            state.error = null;
        },
        resetAgendaRetrieveState: (state) => {
            state.retrieveStatus = {};
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // RetrieveAgenda
            .addCase(retrieveAgenda.pending, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "loading";
                state.updateStatus = "idle";
                state.error = null;
            })
            .addCase(retrieveAgenda.fulfilled, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "succeeded";
                state.agendas[action.meta.arg] = action.payload;
            })
            .addCase(retrieveAgenda.rejected, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "failed";
                state.error = action.error;
                Logger.error(`[agendaSlice]${retrieveAgenda.rejected.type} failed`, action.error);
            })

            // AddAgendaItem
            .addCase(addAgendaItem.pending, (state) => {
                state.updateStatus = "loading";
                state.error = null;
            })
            .addCase(addAgendaItem.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                const weddingId = action.meta.arg.weddingId;
                state.agendas[weddingId] = action.payload;
            })
            .addCase(addAgendaItem.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
                Logger.error(`[agendaSlice]${addAgendaItem.rejected.type} failed`, action.error);
            })

            // UpdateAgendaItem
            .addCase(updateAgendaItem.pending, (state) => {
                state.updateStatus = "loading";
                state.error = null;
            })
            .addCase(updateAgendaItem.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                const weddingId = action.meta.arg.weddingId;
                const agendaItem = action.payload;
                const agenda = state.agendas[weddingId];

                // Rebuild the agenda items array
                state.agendas[weddingId].agendaItems = [
                    ...agenda.agendaItems.map((existingItem) =>
                        existingItem.id === agendaItem.id ? agendaItem : existingItem
                    )
                ];
            })
            .addCase(updateAgendaItem.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
                Logger.error(`[agendaSlice]${updateAgendaItem.rejected.type} failed`, action.error);
            })

            // DeleteAgendaItem
            .addCase(deleteAgendaItem.pending, (state) => {
                state.updateStatus = "loading";
                state.error = null;
            })
            .addCase(deleteAgendaItem.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                const weddingId = action.meta.arg.weddingId;
                const agendaItemId = action.meta.arg.agendaItemId;
                const agenda = state.agendas[weddingId];
                const index = agenda.agendaItems.findIndex((item) => item.id === agendaItemId);
                agenda.agendaItems.splice(index, 1);
            })
            .addCase(deleteAgendaItem.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
                Logger.error(`[agendaSlice]${deleteAgendaItem.rejected.type} failed`, action.error);
            });
    }
});

export const agendaReducer = agendaSlice.reducer;
export const { resetAgendaUpdateState, resetAgendaRetrieveState } = agendaSlice.actions;

/**
 * Stable undefined result for selectors
 */
const NO_RESULT = undefined;

/**
 * Selects the agenda for the given wedding
 * @param weddingId The id of the wedding to retrieve the agenda for
 * @returns The agenda for the wedding including all agenda items
 */
export const selectAgenda =
    (weddingId: string | undefined) =>
    (state: RootState): Agenda | undefined =>
        weddingId ? state.agenda.agendas[weddingId] : NO_RESULT;

export const selectRetrieveAgendaStatus = (weddingId: string | undefined) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.agenda.retrieveStatus[weddingId] ?? IDLE_STATE;
};

export const selectAgendaUpdateStatus = (state: RootState) => state.agenda.updateStatus;

export const selectAgendaError = (state: RootState) => state.agenda.error;
