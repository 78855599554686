import { configureStore } from "@reduxjs/toolkit";
import type { ServiceFactory } from "@weddinggram/service";
import { Logger } from "@weddinggram/telemetry-core";
import { attendanceReducer } from "../slices";
import { agendaReducer } from "../slices/agendaSlice";
import { postsReducer } from "../slices/imagePostSlice";
import { invitationsReducer } from "../slices/invitationSlice";
import { userReducer } from "../slices/userSlice";
import { weddingReducer } from "../slices/weddingSlice";

export const buildStore = (factory: ServiceFactory, useDevtools: boolean) => {
    Logger.log("[store] Building redux store", { useDevtools, factoryInitialized: factory.isInitialized });
    return configureStore({
        reducer: {
            users: userReducer,
            weddings: weddingReducer,
            posts: postsReducer,
            agenda: agendaReducer,
            invitations: invitationsReducer,
            attendances: attendanceReducer
        },
        devTools: useDevtools,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: factory
                }
            })
    });
};

/**
 * Utility type of the store's dispatch function.
 */
export type AppDispatch = ReturnType<typeof buildStore>["dispatch"];

/**
 * Utility type of the store's state.
 * 
 * ```ts
 {
	users: UserState;
	...
 }
 * ```
 */
export type RootState = ReturnType<ReturnType<typeof buildStore>["getState"]>;
