import { useServiceFactory } from "@weddinggram/hooks";
import { useTranslation } from "@weddinggram/i18n";
import type { Attendance, DtoEntityWithId } from "@weddinggram/model";
import { useAppInsights, useLogger } from "@weddinggram/telemetry-react";
import { useErrorHandler } from "@weddinggram/ui-error-handling";
import * as React from "react";
import type { GuestDetails } from "../../InvitationContext";
import { useInvitationContext } from "../../InvitationContext";
import type { InvitationPageProps } from "../../InvitationPageProps";
import type { CardBookPageProps } from "../../cardBookWrapper";
import { InvitationConfirmationRenderer } from "./InvitationConfirmationRenderer";

/**
 * Page displayed in the invitation card book that allows the user to confirm or decline the invitation.
 */
export const InvitationConfirmationContainer = React.forwardRef(
    (props: CardBookPageProps<InvitationPageProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
        const {
            guestDetails,
            setInvitationReplySent,
            invitationReplySent,
            setGuestAttendance,
            setGuestComment,
            attendanceId,
            changeToken
        } = useInvitationContext();
        const { trackEvent } = useAppInsights();
        const factory = useServiceFactory();
        const [isLoading, setIsLoading] = React.useState(false);
        const [, setError] = React.useState<Error | null>(null);
        const { t } = useTranslation("invitation");
        const logger = useLogger("InvitationConfirmationContainer");
        const abortController = React.useRef<AbortController | null>(null);
        const handleErrorInUi = useErrorHandler();

        const sendGuestReply = React.useCallback(
            (details: GuestDetails[]) => {
                const send = async () => {
                    if (abortController.current) {
                        abortController.current.abort();
                    } else {
                        abortController.current = new AbortController();
                    }
                    setIsLoading(true);
                    setError(null);
                    const acceptedNum = details.filter((g) => g.status === "Attending").length;
                    const declinedNum = details.length - acceptedNum;
                    const providedEmailNum = details.filter((g) => Boolean(g.email)).length;
                    const providedComment = details.some((g) => Boolean(g.comment));
                    logger.log("Attendance will be sent to API.", {
                        acceptedNum,
                        declinedNum,
                        providedEmailNum,
                        providedComment
                    });
                    trackEvent("app/invite/reply", {
                        invitationId: props.pageProps.id,
                        acceptedNum,
                        declinedNum,
                        providedEmailNum,
                        providedComment
                    });

                    const attendances = details.map((g, i) => {
                        // If the were sent a personalized invitation, we need to use the attendanceId and changeToken
                        // that was supplied through the qsps and ultimately through the context.
                        // BUT - only for the first attendance, as the rest will be linked to the first one.
                        if (i === 0 && attendanceId && changeToken) {
                            logger.log("Using attendanceId and changeToken for first attendance.", {
                                attendanceId,
                                changeToken
                            });
                            const attendance: DtoEntityWithId<Attendance> = {
                                status: g.status,
                                userDisplayName: g.name,
                                weddingId: props.pageProps.weddingId,
                                userEmail: g.email || null,
                                comment: g.comment || null,
                                linkedAttendanceId: null,
                                userId: null,
                                changeToken: changeToken,
                                qrAccessCode: null,
                                lastOpened: null,
                                id: attendanceId,
                                tags: []
                            };
                            return attendance;
                        }

                        const attendance: DtoEntityWithId<Attendance> = {
                            status: g.status,
                            userDisplayName: g.name,
                            weddingId: props.pageProps.weddingId,
                            userEmail: g.email || null,
                            comment: g.comment || null,
                            linkedAttendanceId: null,
                            qrAccessCode: null,
                            userId: null,
                            changeToken: "",
                            lastOpened: null,
                            tags: []
                        };
                        return attendance;
                    });

                    try {
                        await factory.attendanceService.sendReply(attendances, abortController.current.signal);
                        // Make sure we don't set state on an unmounted component
                        if (abortController.current?.signal.aborted) {
                            abortController.current = null;
                            return;
                        }
                        abortController.current = null;
                        setInvitationReplySent();
                        props.nextPage();
                    } catch (e) {
                        handleErrorInUi(t("reply.errorMessage"), e);
                    } finally {
                        setIsLoading(false);
                    }
                };
                send();
            },
            [props, factory, trackEvent, t, setInvitationReplySent, handleErrorInUi, logger, attendanceId, changeToken]
        );

        // Abort any pending requests when the component is unmounted
        React.useEffect(() => {
            return () => {
                if (abortController.current) {
                    abortController.current.abort();
                }
            };
        }, []);

        return (
            <div ref={ref}>
                <InvitationConfirmationRenderer
                    sendGuestReply={sendGuestReply}
                    {...props}
                    isLoading={isLoading}
                    guestDetails={guestDetails}
                    invitationReplySent={invitationReplySent}
                    setGuestAttendance={setGuestAttendance}
                    setGuestComment={setGuestComment}
                />
            </div>
        );
    }
);
InvitationConfirmationContainer.displayName = "InvitationConfirmationContainer";
