/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useStyles = makeStyles({
    root: {
        display: "flex"
    },
    clickable: {
        cursor: "pointer"
    },
    clickableHover: {
        ":hover": {
            backgroundColor: tokens.colorBrandBackground2
        }
    },
    column: {
        flexDirection: "column"
    },
    debug: {
        ...shorthands.border(tokens.strokeWidthThin, "dashed", tokens.colorNeutralStroke1),
        ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)"
        }
    },
    fill: {
        width: "100%",
        height: "100%"
    },
    vFill: {
        height: "100%"
    },
    hFill: {
        width: "100%"
    },
    gapSmaller: {
        "--gap": "0.5rem"
    },
    gapSmall: {
        "--gap": "0.625rem"
    },
    gapMedium: {
        "--gap": "0.9375rem"
    },
    gapLarge: {
        "--gap": "1.875rem"
    },
    gapColumn: {
        "> :not(:last-child)": {
            marginBottom: "var(--gap)"
        }
    },
    gapRow: {
        "> :not(:last-child)": {
            marginRight: "var(--gap)"
        }
    },
    hAlignStart: {
        "--hAlign": "start"
    },
    hAlignCenter: {
        "--hAlign": "center"
    },
    hAlignEnd: {
        "--hAlign": "end"
    },
    hAlignStretch: {
        "--hAlign": "stretch"
    },
    vAlignStart: {
        "--vAlign": "start"
    },
    vAlignCenter: {
        "--vAlign": "center"
    },
    vAlignEnd: {
        "--vAlign": "end"
    },
    vAlignStretch: {
        "--vAlign": "stretch"
    },
    hAlignColumn: {
        alignItems: "var(--hAlign)"
    },
    hAlignRow: {
        justifyContent: "var(--hAlign)"
    },
    vAlignColumn: {
        justifyContent: "var(--vAlign)"
    },
    vAlignRow: {
        alignItems: "var(--vAlign)"
    },
    inline: {
        display: "inline-flex"
    },
    paddingMedium: {
        "--padding": "0.5rem"
    },
    padding: {
        paddingLeft: "var(--padding)",
        paddingRight: "var(--padding)",
        paddingTop: "var(--padding)",
        paddingBottom: "var(--padding)"
    },
    spacearound: {
        justifyContent: "space-around"
    },
    spacebetween: {
        justifyContent: "space-between"
    },
    spaceevenly: {
        justifyContent: "space-evenly"
    },
    wrap: {
        flexWrap: "wrap"
    }
});
