import { ApiException } from "./ApiException";

export const NETWORK_EXCEPTION_NAME = "NetworkException";

export class NetworkException extends ApiException {
    constructor(message: string, innerException?: unknown, properties?: Record<string, unknown>) {
        super(null, message, innerException, properties);
        this.name = NETWORK_EXCEPTION_NAME;
    }
}
