import { tokens } from "@fluentui/react-components";
import { tintColor } from "@weddinggram/common-ui/utilities";
import type { InvitationCardColors } from "@weddinggram/model";
import * as React from "react";

/**
 * CSS variable name suffix for inverted colors
 */
export const INVITATION_CSS_VARIABLE_INVERTED_SUFFIX = "-inverted" as const;

/**
 * CSS variable name for the foreground color
 * For inverted colors, append `-inverted` to the variable name
 */
export const INVITATION_CSS_VARIABLE_FOREGROUND = "--wg-foreground-color" as const;

/**
 * CSS variable name for the foreground color but 15% darker.
 */
export const INVITATION_CSS_VARIABLE_FOREGROUND_DARKER = "--wg-foreground-color-darker" as const;

/**
 * CSS variable name for the foreground color but 15% lighter.
 */
export const INVITATION_CSS_VARIABLE_FOREGROUND_LIGHTER = "--wg-foreground-color-lighter" as const;

/**
 * CSS variable name for the background color
 * For inverted colors, append `-inverted` to the variable name
 */
export const INVITATION_CSS_VARIABLE_BACKGROUND = "--wg-background-color" as const;

/**
 * CSS variable name for the background color but 15% lighter.
 */
export const INVITATION_CSS_VARIABLE_BACKGROUND_LIGHTER = "--wg-background-color-lighter" as const;

/**
 * CSS variable name for the accent color
 */
export const INVITATION_CSS_VARIABLE_ACCENT = "--wg-accent-color" as const;

/**
 * CSS variable name for the accent color but 15% lighter.
 */
export const INVITATION_CSS_VARIABLE_ACCENT_LIGHTER = "--wg-accent-color-lighter" as const;

/**
 * Ratio for the lighter colors
 */
const LIGHTER_COLOR_RATIO = 0.15;

/**
 * Ratio for the darker colors
 */
const DARKER_COLOR_RATIO = -0.15;

/**
 * Styles exposed by the {@link useInvitationStyleVariables} hook.
 */
export type InvitationStyles = {
    /**
     * Style for the foreground color
     */
    foregroundColor: React.CSSProperties;

    /**
     * Style for the background color
     */
    backgroundColor: React.CSSProperties;

    /**
     * Style for the accent color
     */
    accentColor: React.CSSProperties;

    /**
     * Style for the accent border color
     */
    accentBorderColor: React.CSSProperties;

    /**
     * Style for the accent background color
     */
    accentBackgroundColor: React.CSSProperties;
};

/**
 * Gets the CSS variables for the invitation to be used in child components.
 * @param useInverted Flag indicating if the inverted colors should be used.
 * @returns CSS variables for the invitation to be used in child components.
 */
export const useInvitationStyleVariables = (useInverted = false): InvitationStyles => {
    const variableSuffix = useInverted ? INVITATION_CSS_VARIABLE_INVERTED_SUFFIX : "";

    return React.useMemo(
        () => ({
            foregroundColor: {
                color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND}${variableSuffix})`
            },
            backgroundColor: {
                background: `var(${INVITATION_CSS_VARIABLE_BACKGROUND}${variableSuffix})`
            },
            accentColor: {
                color: `var(${INVITATION_CSS_VARIABLE_ACCENT})`
            },
            accentBorderColor: {
                borderColor: `var(${INVITATION_CSS_VARIABLE_ACCENT})`
            },
            accentBackgroundColor: {
                background: `var(${INVITATION_CSS_VARIABLE_ACCENT})`
            }
        }),
        [variableSuffix]
    );
};

/**
 * Gets the colors to use in the page as color values.
 * @param invitationColors The invitation colors to use.
 * @returns The colors to use in the page.
 */
export const usePageColors = (invitationColors: InvitationCardColors | null) => {
    const { primaryCardColor, secondaryCardColor, tertiaryCardColor } = invitationColors ?? {};

    return React.useMemo(() => {
        const foreground = secondaryCardColor ?? tokens.colorNeutralForeground2;
        const darkerForeground = secondaryCardColor
            ? tintColor(DARKER_COLOR_RATIO, secondaryCardColor)
            : tokens.colorNeutralForeground1;
        const lighterForeground = secondaryCardColor
            ? tintColor(LIGHTER_COLOR_RATIO, secondaryCardColor)
            : tokens.colorNeutralForeground3;
        const background = primaryCardColor ?? tokens.colorNeutralBackground2;
        const lighterBackground = primaryCardColor
            ? tintColor(LIGHTER_COLOR_RATIO, primaryCardColor)
            : tokens.colorNeutralBackground1;
        const darkerBackground = primaryCardColor
            ? tintColor(DARKER_COLOR_RATIO, primaryCardColor)
            : tokens.colorNeutralBackground3;
        const accentColor = tertiaryCardColor ?? tokens.colorBrandBackground;
        const accentColorLighter = tertiaryCardColor
            ? tintColor(LIGHTER_COLOR_RATIO, tertiaryCardColor)
            : tokens.colorBrandBackground2;

        const foregroundInverted = primaryCardColor ?? tokens.colorNeutralForegroundInverted2;
        const backgroundInverted = secondaryCardColor ?? tokens.colorNeutralBackgroundInverted;

        return {
            foreground,
            darkerForeground,
            lighterForeground,
            background,
            lighterBackground,
            darkerBackground,
            accentColor,
            accentColorLighter,
            foregroundInverted,
            backgroundInverted
        } as const;
    }, [primaryCardColor, secondaryCardColor, tertiaryCardColor]);
};

/**
 * Gets the CSS variables for the invitation to be used in child components.
 * Inject these variables using `style={getInvitationPageStyle(invitation)}`
 * @param invitation The invitation to get the colors from.
 * @returns CSS variables for the invitation to be used in child components.
 */
export const useInvitationPageStyle = (invitationColors: InvitationCardColors | null): React.CSSProperties => {
    const pageColors = usePageColors(invitationColors);

    return React.useMemo(() => {
        const {
            foreground,
            darkerForeground,
            lighterForeground,
            background,
            lighterBackground,
            darkerBackground,
            accentColor,
            accentColorLighter,
            foregroundInverted,
            backgroundInverted
        } = pageColors;
        return {
            [INVITATION_CSS_VARIABLE_ACCENT]: accentColor,
            [INVITATION_CSS_VARIABLE_ACCENT_LIGHTER]: accentColorLighter,
            [INVITATION_CSS_VARIABLE_BACKGROUND]: background,
            [INVITATION_CSS_VARIABLE_BACKGROUND_LIGHTER]: lighterBackground,
            [INVITATION_CSS_VARIABLE_FOREGROUND]: foreground,
            [INVITATION_CSS_VARIABLE_FOREGROUND_DARKER]: darkerForeground,
            [INVITATION_CSS_VARIABLE_FOREGROUND_LIGHTER]: lighterForeground,

            // Inverted colors - foreground <-> background are swapped
            [`${INVITATION_CSS_VARIABLE_BACKGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX}`]: backgroundInverted,
            [`${INVITATION_CSS_VARIABLE_FOREGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX}`]: foregroundInverted,

            // Inverted modifier colors -
            [`${INVITATION_CSS_VARIABLE_BACKGROUND_LIGHTER}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX}`]:
                lighterForeground,
            [`${INVITATION_CSS_VARIABLE_FOREGROUND_DARKER}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX}`]:
                darkerBackground,
            [`${INVITATION_CSS_VARIABLE_FOREGROUND_LIGHTER}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX}`]:
                lighterBackground
        } as React.CSSProperties;
    }, [pageColors]);
};
