/**
 * See https://emailregex.com/index.html for the regex used here
 */
const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * Validates a given email by regex
 * @param email The email to validate
 * @returns True if the email is valid, false otherwise
 */
export const isEmail = (email: string): boolean => {
    return emailRegex.test(email);
};
