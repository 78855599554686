import loadable from "@loadable/component";
import { RouteConstants } from "@weddinggram/common";
import { ErrorComponent } from "@weddinggram/ui-error-handling";
import { InvitationAppRoot, InvitationCardPreview, InvitationQrCodeAppRoot } from "@weddinggram/ui-invitation";
import React from "react";
import type { RouteObject } from "react-router-dom";
import { LogsDisplayComponent } from "../components/app/pages/logs";

const LandingPageMainContentContainer = loadable(
    () =>
        import(
            /* WebpackChunkName: "LandingPageMainContentContainer" */ "../components/landingpage/mainContent/LandingPageMainContentContainer"
        ),
    {
        resolveComponent: (component) => component.LandingPageMainContentContainer
    }
);

const HowItWorks = loadable(
    () => import(/* webpackChunkName: "TermsOfService" */ "../components/landingpage/howItWorks/HowItWorks"),
    {
        resolveComponent: (component) => component.HowItWorksContainer
    }
);

const PrivacyPolicy = loadable(
    () => import(/* webpackChunkName: "PrivacyPolicy" */ "../components/landingpage/privacy/PrivacyPolicy"),
    {
        resolveComponent: (component) => component.PrivacyPolicyContainer
    }
);

const TermsOfService = loadable(
    () => import(/* webpackChunkName: "TermsOfService" */ "../components/landingpage/termsOfService/TermsOfService"),
    {
        resolveComponent: (component) => component.TermsOfServiceContainer
    }
);

const AboutComponent = loadable(() => import(/* webpackChunkName: "About" */ "../components/landingpage/about/About"), {
    resolveComponent: (component) => component.AboutContainer
});

export const unprotectedRoutes: RouteObject[] = [
    {
        path: "/",
        element: <LandingPageMainContentContainer />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.ABOUT}`,
        element: <AboutComponent />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.HOW_IT_WORKS}`,
        element: <HowItWorks />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.PRICING}`,
        element: <AboutComponent />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.PRIVACY_POLICY}`,
        element: <PrivacyPolicy />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.TERMS_OF_SERVICE}`,
        element: <TermsOfService />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.APP}/${RouteConstants.INVITE}/:id`,
        element: <InvitationAppRoot />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.QR_CODE}/:id`,
        element: <InvitationQrCodeAppRoot />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.APP}/${RouteConstants.INVITE_PREVIEW}`,
        element: <InvitationCardPreview />,
        errorElement: <ErrorComponent status={404} />
    },
    {
        path: `/${RouteConstants.APP}/${RouteConstants.LOGS}`,
        element: <LogsDisplayComponent />
    }
];
