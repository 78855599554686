import { InteractionType } from "@azure/msal-browser";
import loadable from "@loadable/component";
import { RouteConstants } from "@weddinggram/common";
import { PageLoader } from "@weddinggram/common-ui";
import i18n from "@weddinggram/i18n/src/i18n";
import { authRequest } from "@weddinggram/service/auth";
import { ErrorComponent, MsalErrorComponent } from "@weddinggram/ui-error-handling";
import * as React from "react";
import type { RouteObject } from "react-router-dom";

const MsalAuthenticationTemplate = loadable(
    () =>
        import(
            /* WebpackChunkName: "MsalAuthenticationTemplate" */
            "@azure/msal-react"
        ),
    {
        resolveComponent: (component) => component.MsalAuthenticationTemplate
    }
);

const AppRoot = loadable(
    () =>
        import(
            /* WebpackChunkName: "AppRoot" */
            "@weddinggram/ui-app-root-authenticated"
        ),
    {
        resolveComponent: (component) => component.AuthenticatedAppRoot,
        fallback: <PageLoader label="..." />
    }
);

const Home = loadable(
    () =>
        import(
            /* WebpackChunkName: "Home" */
            "../components/app/pages/home/Home"
        ),
    {
        resolveComponent: (component) => component.Home,
        fallback: <PageLoader label={i18n.t("loading.home.label")} />
    }
);

const Gallery = loadable(
    () =>
        import(
            /* WebpackChunkName: "Gallery" */
            "../components/app/pages/gallery/Gallery"
        ),
    {
        resolveComponent: (component) => component.Gallery,
        fallback: <PageLoader label={i18n.t("loading.gallery.label")} />
    }
);

const Login = loadable(
    () =>
        import(
            /* WebpackChunkName: "Login" */
            "../components/app/pages/login/Login"
        ),
    {
        resolveComponent: (component) => component.Login,
        fallback: <PageLoader label={i18n.t("loading.login.label")} />
    }
);

const NewPost = loadable(
    () =>
        import(
            /* WebpackChunkName: "NewPost" */
            "../components/app/pages/newPost/NewPost"
        ),
    {
        resolveComponent: (component) => component.NewPost
    }
);

const WeddingContainer = loadable(
    () => import(/* webpackChunkName: "WeddingContainer" */ "../components/app/wedding/WeddingContainer"),
    { resolveComponent: (component) => component.WeddingContainer }
);

const WeddingRoot = loadable(
    () =>
        import(
            /* WebpackChunkName: "WeddingRoot" */
            "../components/app/pages/weddingRoot/WeddingRoot"
        ),
    { resolveComponent: (component) => component.WeddingRoot }
);

const WeddingJoinContainer = loadable(
    () =>
        import(
            /* WebpackChunkName: "WeddingJoinContainer" */
            "../components/app/pages/weddingRoot/join/link/WeddingJoinLinkContainer"
        ),
    {
        resolveComponent: (component) => component.WeddingJoinLinkContainer
    }
);

const WeddingJoinQrContainer = loadable(
    /* WebpackChunkName: "WeddingJoinQrContainer" */
    () => import("../components/app/pages/weddingRoot/join/qr/WeddingJoinQrContainer"),
    {
        resolveComponent: (component) => component.WeddingJoinQrContainer
    }
);

const Logout = loadable(
    () =>
        import(
            /* WebpackChunkName: "Logout" */
            "../components/app/pages/logout/Logout"
        ),
    {
        resolveComponent: (component) => component.Logout
    }
);

const UserProfileContainer = loadable(
    () =>
        import(
            /* WebpackChunkName: "UserProfileContainer" */
            "../components/app/pages/profile/UserProfileContainer"
        ),
    {
        resolveComponent: (component) => component.UserProfileContainer
    }
);

export const protectedRoutes: RouteObject[] = [
    {
        path: "/" + RouteConstants.APP,
        element: (
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={MsalErrorComponent}
            >
                <AppRoot appVersion={APP_VERSION} />
            </MsalAuthenticationTemplate>
        ),
        children: [
            {
                path: RouteConstants.HOME,
                element: <Home />
            },
            {
                path: RouteConstants.GALLERY,
                element: <Gallery />
            },
            {
                path: RouteConstants.WEDDINGS,
                element: <WeddingRoot />,
                children: [
                    {
                        path: RouteConstants.WEDDINGS_NEW,
                        element: <WeddingContainer inCreateMode />
                    },
                    {
                        path: RouteConstants.JOIN,
                        element: <WeddingJoinQrContainer />
                    },
                    {
                        path: ":id",
                        element: <WeddingContainer />
                    },
                    {
                        path: ":id/" + RouteConstants.JOIN,
                        element: <WeddingJoinContainer />
                    }
                ]
            },
            {
                path: RouteConstants.USERS + "/:id",
                element: <UserProfileContainer />
            },
            {
                path: RouteConstants.LOGIN,
                element: <Login />
            },
            {
                path: RouteConstants.LOGOUT,
                element: <Logout />
            },
            {
                path: RouteConstants.PROFILE,
                element: <UserProfileContainer />
            },
            {
                path: RouteConstants.NEW_POST,
                element: <NewPost />
            }
        ],
        errorElement: <ErrorComponent status={404} />
    }
];
