import type { DotNetApiError } from "@weddinggram/model";
import { ApiException } from "./ApiException";

export const DOT_NET_API_EXCEPTION_NAME = "DotNetApiException";

export class DotNetApiException extends ApiException {
    constructor(
        public readonly dotNetError: DotNetApiError,
        message: string,
        innerException?: unknown,
        properties?: Record<string, unknown>
    ) {
        super(
            null,
            DotNetApiException.composeDotNetApiExceptionMessage(dotNetError, message),
            innerException,
            properties
        );
        this.name = DOT_NET_API_EXCEPTION_NAME;
    }

    protected static composeDotNetApiExceptionMessage(dotNetError: DotNetApiError, message: string): string {
        return `[${dotNetError.status}][${dotNetError.type}] ${dotNetError.title} - ${message}`;
    }
}
