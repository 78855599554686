import type { IApplicationInsights } from "@microsoft/applicationinsights-web";
import type { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { SeverityLevel } from "@weddinggram/telemetry-core";
import * as React from "react";
import { useSelector } from "react-redux";

import { unknownToError } from "@weddinggram/exceptions/utilities";
import type { RequestStatus } from "@weddinggram/redux";
import { isLoading, isReady, useAppDispatch } from "@weddinggram/redux";
import type { RootState } from "@weddinggram/redux/src/store/store";
import type { EventNames } from "@weddinggram/telemetry-core";
import { useAppInsights, useLogger } from "@weddinggram/telemetry-react";
import { useErrorHandler } from "@weddinggram/ui-error-handling";
import type { ToastIntent } from "@weddinggram/ui-toast";
import { useToast } from "@weddinggram/ui-toast";

type ToastErrorHandlingArgs = {
    toastOnError: true;
    userErrorMessage: string;
    toastType?: ToastIntent;
};

type SilentErrorHandlingArgs = {
    toastOnError: false;
    userErrorMessage?: never;
};

export type ErrorHandlingArgs = (ToastErrorHandlingArgs | SilentErrorHandlingArgs) & {
    errorSeverity?: number | SeverityLevel;
    failureEventName?: EventNames;
};

export type SuccessHandlingArgs = {
    userSuccessMessage?: string;
    onSuccess?: () => void;
};

const handleError = (
    handleErrorInUi: ReturnType<typeof useErrorHandler>,
    ai: IApplicationInsights,
    error: unknown,
    errorHandlingArgs: ErrorHandlingArgs
) => {
    const errorObject = unknownToError(error);
    ai.trackException({
        exception: errorObject,
        severityLevel: errorHandlingArgs.errorSeverity ?? SeverityLevel.Error
    });

    if (errorHandlingArgs.failureEventName) {
        ai.trackEvent({ name: errorHandlingArgs.failureEventName });
    }

    if (errorHandlingArgs.toastOnError) {
        handleErrorInUi(errorHandlingArgs.userErrorMessage, error, errorHandlingArgs.toastType);
    }
};

/**
 * A hook that can be used to monitor the status of a dispatched action and handle the error and success cases.
 * @param entityTypeName The name of the entity type that is being monitored, only used for logging.
 * @param selector The selector that returns the request status of the action being monitored.
 * @param errorSelector The selector that returns the error of the action being monitored.
 * @param errorHandlingArgs The arguments to handle the error.
 * @param resetAction The action to dispatch when the request status is ready.
 * @param successHandlingArgs The arguments to handle the success
 * @returns An object with the request status, error and a boolean indicating if the request is pending.
 */
export const useMonitorDispatchAction = (
    entityTypeName: string,
    selector: (state: RootState) => RequestStatus,
    errorSelector: (state: RootState) => unknown,
    errorHandlingArgs: ErrorHandlingArgs,
    resetAction?: ActionCreatorWithoutPayload,
    successHandlingArgs?: SuccessHandlingArgs
) => {
    const logger = useLogger(`useMonitorDispatchAction.${entityTypeName}`);
    const status = useSelector(selector);
    const error = useSelector(errorSelector);
    const dispatch = useAppDispatch();
    const { appInsights } = useAppInsights();
    const handleErrorInUi = useErrorHandler();
    const { successToast } = useToast();

    const { userSuccessMessage, onSuccess } = successHandlingArgs ?? {};

    React.useEffect(() => {
        logger.log("Status changed", { status, error });
        if (status === "failed") {
            handleError(handleErrorInUi, appInsights, error, errorHandlingArgs);
        } else if (status === "succeeded" && (onSuccess || userSuccessMessage)) {
            userSuccessMessage && successToast(userSuccessMessage);
            onSuccess && onSuccess();
        }

        if (resetAction && isReady(status)) {
            logger.log("Resetting action");
            dispatch(resetAction());
        }
    }, [
        logger,
        status,
        error,
        successToast,
        dispatch,
        resetAction,
        handleErrorInUi,
        appInsights,
        errorHandlingArgs,
        userSuccessMessage,
        onSuccess
    ]);

    return {
        isPending: isLoading(status),
        requestStatus: status,
        error
    };
};
