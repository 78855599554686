import { createAsyncThunk } from "@reduxjs/toolkit";
import type { ServiceFactory } from "@weddinggram/service";
import type { AppDispatch, RootState } from "./store/store";

/**
 * Creates an async thunk that has the service factory injected as extra parameter
 */
export const createAsyncThunkWithServiceFactory = createAsyncThunk.withTypes<{
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: unknown;
    extra: ServiceFactory;
}>();
