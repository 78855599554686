import {
    AvatarGroup,
    AvatarGroupItem,
    AvatarGroupPopover,
    makeStyles,
    mergeClasses,
    shorthands
} from "@fluentui/react-components";
import { INVITATION_AVATAR_GROUP_POPOVER, NUMBER_OF_CHARS_VISIBLE_FOR_INVITE_GUEST_NAME } from "@weddinggram/common";
import { Flex } from "@weddinggram/common-ui";
import { useWindowSize } from "@weddinggram/hooks";
import * as React from "react";
import type { GuestDetails } from "../../InvitationContext";
import { useInvitationStyles } from "../Invitation.styles";
import { INVITATION_CSS_VARIABLE_ACCENT } from "../PageColors";

type InvitationGUestAvatarGroupProps = {
    activeGuestIndex: number;
    setActiveGuestIndex: (index: number) => void;
    guestDetails: GuestDetails[];
};

const usePageStyles = makeStyles({
    avatarGroup: {
        ...shorthands.margin("0.5rem", 0),
        justifyContent: "center"
    },
    avatarGroupItemContainer: {
        ...shorthands.margin(0, "0.5rem")
    },
    inactiveAvatar: {
        cursor: "pointer"
    },
    activeGuestUserName: {
        fontWeight: 300,
        color: `var(${INVITATION_CSS_VARIABLE_ACCENT})`
    },
    container: {
        minHeight: "34px"
    }
});

export const InvitationGuestAvatarGroup: React.FC<InvitationGUestAvatarGroupProps> = (props) => {
    const { activeGuestIndex, setActiveGuestIndex, guestDetails } = props;
    const classes = usePageStyles();
    const invitationClasses = useInvitationStyles();
    const { screenSize } = useWindowSize();

    const upperCaseText = mergeClasses(
        invitationClasses.regularText,
        invitationClasses.upperCaseText,
        invitationClasses.wideLetterSpacing
    );

    let content: React.ReactNode;
    if (guestDetails.length === 0) {
        content = <div></div>;
    } else {
        const avatarGroupType = screenSize === "small" ? "stack" : "spread";

        content = (
            <AvatarGroup className={classes.avatarGroup} layout={avatarGroupType}>
                {guestDetails.slice(0, INVITATION_AVATAR_GROUP_POPOVER).map((guest, index) => {
                    const isActive = index === activeGuestIndex;

                    let nameToDisplay = guest.name.split(" ")[0];
                    if (nameToDisplay.length > NUMBER_OF_CHARS_VISIBLE_FOR_INVITE_GUEST_NAME) {
                        nameToDisplay = `${nameToDisplay.slice(
                            0,
                            NUMBER_OF_CHARS_VISIBLE_FOR_INVITE_GUEST_NAME - "...".length
                        )}...`;
                    }

                    const guestNameClasses = [upperCaseText];
                    if (isActive) {
                        guestNameClasses.push(classes.activeGuestUserName);
                    }

                    return (
                        <Flex
                            key={`guest-${index}`}
                            vAlign="center"
                            gap="gapSmall"
                            className={classes.avatarGroupItemContainer}
                        >
                            <AvatarGroupItem
                                key={guest.name}
                                name={guest.name}
                                active={isActive ? "active" : "inactive"}
                                className={isActive ? undefined : classes.inactiveAvatar}
                                onClick={() => setActiveGuestIndex(index)}
                                activeAppearance="shadow"
                                color="neutral"
                            />
                            <div className={mergeClasses(...guestNameClasses)}>{nameToDisplay}</div>
                        </Flex>
                    );
                })}
                {guestDetails.length > INVITATION_AVATAR_GROUP_POPOVER ? (
                    <AvatarGroupPopover>
                        {guestDetails.slice(INVITATION_AVATAR_GROUP_POPOVER).map((guest, index) => {
                            return (
                                <AvatarGroupItem
                                    key={guest.name}
                                    name={guest.name}
                                    onClick={() => setActiveGuestIndex(index + INVITATION_AVATAR_GROUP_POPOVER)}
                                    activeAppearance="shadow"
                                    color="neutral"
                                />
                            );
                        })}
                    </AvatarGroupPopover>
                ) : null}
            </AvatarGroup>
        );
    }

    return (
        <Flex useVertical hFill vAlign="center" className={classes.container}>
            {content}
        </Flex>
    );
};
