export { Flex } from "./Flex";

import loadable from "@loadable/component";
export const FlexAnimated = loadable(() => import("./FlexAnimated"), {
    resolveComponent: (component) => component.FlexAnimated
});

export const FlexBlur = loadable(() => import("./FlexBlur"), {
    resolveComponent: (component) => component.FlexBlur
});
