export type { ToastIntent } from "@fluentui/react-components";
export { useToast } from "./src/useToast";

import loadable from "@loadable/component";
export const Toast = loadable(() => import(/* webpackChunkName: "Toast" */ "./src/Toast"), {
    resolveComponent: (components) => components.Toast
});

export const Toaster = loadable(() => import(/* webpackChunkName: "Toaster" */ "./src/Toaster"), {
    resolveComponent: (components) => components.Toaster
});
