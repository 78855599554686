import { z } from "zod";
import { MetadataSchema } from "./Metadata";

export const BaseEntitySchema = z.object({
    /**
     * The id of the entity.
     */
    id: z.string().uuid(),

    /**
     * The metadata of the entity.
     */
    metadata: MetadataSchema
});
