import { Flex } from "@weddinggram/common-ui";
import * as React from "react";
import type { InvitationPageProps } from "../../InvitationPageProps";
import type { CardBookPageProps } from "../../cardBookWrapper";
import { useInvitationStyles, useStaticInvitationStyles } from "../Invitation.styles";
import { useInvitationStyleVariables } from "../PageColors";

/**
 * Renders an empty page with non-inverted colors.
 */
export const EmptyPageNonInverted = React.forwardRef(
    (props: CardBookPageProps<InvitationPageProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
        useStaticInvitationStyles();
        const invitationClasses = useInvitationStyles();
        const cardStyles = useInvitationStyleVariables();

        return (
            <div ref={ref}>
                <Flex
                    style={cardStyles.backgroundColor}
                    className={props.isOnePageLayout ? undefined : invitationClasses.leftSideFold}
                    fill
                ></Flex>
            </div>
        );
    }
);

EmptyPageNonInverted.displayName = "EmptyPageNonInverted";
