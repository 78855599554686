import type { BaseWedding } from "..";

/**
 * Checks if the user is an owner of the wedding.
 * @param wedding The wedding to check
 * @param user The user to check
 * @returns True if the user is an owner of the wedding, false otherwise
 */
export function isWeddingOwner(wedding: BaseWedding | null | undefined, userId: string | null | undefined): boolean {
    if (!wedding || !userId) {
        return false;
    }
    return wedding.owners.some((ownerId) => ownerId === userId);
}
