import { mergeClasses } from "@fluentui/react-components";
import { useTranslation } from "@weddinggram/i18n";
import type { Invitation } from "@weddinggram/model";
import * as React from "react";
import { useInvitationStyles } from "./Invitation.styles";
import type { InvitationStyles } from "./PageColors";

type InvitationLocationProps = {
    /**
     * The location of the wedding
     */
    weddingLocation: Invitation["weddingLocation"];
} & Pick<InvitationStyles, "foregroundColor">;

/**
 * A component that displays the location of the wedding
 * @param props Props for the InvitationLocation component
 * @returns An InvitationLocation component
 */
export const InvitationLocation: React.FC<InvitationLocationProps> = (props) => {
    const invitationClasses = useInvitationStyles();
    const { t } = useTranslation("invitation");
    const weddingLocation =
        props.weddingLocation ?? `${t("location.fallback.title")}\n${t("location.fallback.description")}`;

    const upperCaseText = mergeClasses(
        invitationClasses.regularText,
        invitationClasses.upperCaseText,
        invitationClasses.wideLetterSpacing
    );

    return (
        <div style={props.foregroundColor} className={mergeClasses(invitationClasses.regularText)}>
            {weddingLocation.split("\n").map((line, index) => (
                <div
                    className={index === 0 ? upperCaseText : invitationClasses.italicText}
                    key={`locationLine${index}`}
                >
                    {line}
                </div>
            ))}
        </div>
    );
};
