import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { Flex } from "@weddinggram/common-ui";
import { useMomentLocale, useTranslation } from "@weddinggram/i18n";
import type { Invitation } from "@weddinggram/model";
import moment from "moment";
import * as React from "react";
import { useInvitationStyles } from "./Invitation.styles";
import type { InvitationStyles } from "./PageColors";
import { VerticalSeparator } from "./VerticalSeparator";

type InvitationDateProps = {
    /**
     * The wedding date
     */
    weddingDate: Invitation["weddingDate"];

    /**
     * Whether to display the time of the wedding
     */
    withTime?: boolean;
} & Pick<InvitationStyles, "foregroundColor">;

const useStyles = makeStyles({
    eventDay: {
        fontSize: tokens.fontSizeBase600,
        fontWeight: 400,
        textAlign: "center"
    }
});

/**
 * A component that displays the wedding date on the invitation
 * @param props Props for the InvitationDate component
 * @returns An InvitationDate component
 */
export const InvitationDate: React.FC<InvitationDateProps> = (props) => {
    const invitationClasses = useInvitationStyles();
    const classes = useStyles();
    const { t } = useTranslation("invitation");
    const weddingDate = props.weddingDate;
    useMomentLocale();

    const upperCaseText = mergeClasses(
        invitationClasses.regularText,
        invitationClasses.upperCaseText,
        invitationClasses.wideLetterSpacing
    );

    if (!weddingDate) {
        return (
            <Flex useVertical gap="gapSmall">
                <div style={props.foregroundColor} className={upperCaseText}>
                    {t("date.fallback.title")}
                </div>
                <Flex hAlign="center">
                    <div
                        style={props.foregroundColor}
                        className={mergeClasses(invitationClasses.upperCaseText, classes.eventDay)}
                    >
                        {t("date.fallback.description")}
                    </div>
                </Flex>
            </Flex>
        );
    }

    const momentWeddingDate = moment(weddingDate);

    return (
        <Flex useVertical gap="gapSmall">
            <div style={props.foregroundColor} className={upperCaseText}>
                {momentWeddingDate.format("dddd")}
            </div>
            <Flex hAlign="center">
                <div
                    style={props.foregroundColor}
                    className={mergeClasses(invitationClasses.upperCaseText, invitationClasses.mediumText)}
                >
                    {momentWeddingDate.format("MMM")}
                </div>
                <VerticalSeparator foregroundColor={props.foregroundColor} />
                <div
                    style={props.foregroundColor}
                    className={mergeClasses(invitationClasses.upperCaseText, classes.eventDay)}
                >
                    {momentWeddingDate.format("DD")}
                </div>
                <VerticalSeparator foregroundColor={props.foregroundColor} />
                <div
                    style={props.foregroundColor}
                    className={mergeClasses(invitationClasses.upperCaseText, invitationClasses.mediumText)}
                >
                    {momentWeddingDate.format("YYYY")}
                </div>
            </Flex>
            <WeddingTime {...props} withTime />
        </Flex>
    );
};

const WeddingTime: React.FC<InvitationDateProps> = (props) => {
    const { t } = useTranslation("invitation");
    const invitationClasses = useInvitationStyles();

    if (!props.withTime) {
        return null;
    }

    const momentTime = moment(props.weddingDate).format("LT");

    return (
        <div
            className={mergeClasses(invitationClasses.regularText, invitationClasses.italicText)}
            style={props.foregroundColor}
        >
            {t("time.at", { time: momentTime })}
        </div>
    );
};
