// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    min-height: calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0));
}

@supports (-webkit-touch-callout: none) {
    body html {
        /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
        min-height: -webkit-fill-available;
    }
}

#root {
    height: 100%;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;;IAEI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;IACX,sFAAsF;AAC1F;;AAEA;IACI;QACI,gFAAgF;QAChF,kCAAkC;IACtC;AACJ;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":["body,\nhtml {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    width: 100%;\n    min-height: calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0));\n}\n\n@supports (-webkit-touch-callout: none) {\n    body html {\n        /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */\n        min-height: -webkit-fill-available;\n    }\n}\n\n#root {\n    height: 100%;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
