import { Logger } from "@weddinggram/telemetry-core";
import type {
    BearerAuthenticationHeader,
    IAuthenticationService,
    RedemptionCodeAuthenticationHeader
} from "./IAuthenticationService";
import { REDEMPTION_CODE_HEADER_NAME } from "./IAuthenticationService";
import type { UserTokenDetails } from "./UserTokenDetails";

const METHOD_NOT_SUPPORTED_ERROR = "method is not supported by the redemption code authentication service" as const;

/**
 * Represents an authentication service that uses a redemption code to authenticate.
 * This is used for the wedding invitation page where a user is not authenticated, but has a redemption code.
 */
export class RedemptionCodeAuthenticationService implements IAuthenticationService {
    private readonly logger = Logger.create("RedemptionCodeAuthenticationService");
    constructor(private readonly redemptionCode: string) {
        this.logger.log("Created");
    }

    initialize(): Promise<void> {
        return Promise.resolve();
    }
    getAccessToken(_: string[]): Promise<string> {
        this.logger.warn("getAccessToken is not supported");
        throw new Error(METHOD_NOT_SUPPORTED_ERROR);
    }
    getAuthenticationHeader(_: string[]): Promise<BearerAuthenticationHeader | RedemptionCodeAuthenticationHeader> {
        return Promise.resolve({ [REDEMPTION_CODE_HEADER_NAME]: this.redemptionCode });
    }
    isAuthenticated(): boolean {
        return this.redemptionCode !== undefined && this.redemptionCode !== null && this.redemptionCode !== "";
    }
    getTokenDetails(): UserTokenDetails | null {
        this.logger.warn("getTokenDetails is not supported");
        throw new Error(METHOD_NOT_SUPPORTED_ERROR);
    }
}
