/* eslint-disable no-console */
import type { GetClassMethods } from "@weddinggram/common";
import { HOURS_IN_DAY, MIN_IN_HOUR, MS_IN_SEC, SEC_IN_MIN, isDev } from "@weddinggram/common";
import * as ls from "local-storage";

export type LogMessage = {
    message: string;
    timestamp: number;
    optionalParams: unknown[];
};

/**
 * The static methods of the Logger class.
 */
export type LogMethods = GetClassMethods<typeof Logger>;

const debounceTime = 1000;

/**
 * Logger class to log messages to the console and store them in memory.
 */
export class Logger {
    public static messages: LogMessage[] = [];
    private static messageQueue: LogMessage[] = [];

    /**
     * Creates a new logger instance. Preferred over the static methods because it allows you to specify a name.
     * @param name The name of the file to create a logger for.
     * @returns A new logger instance.
     */
    public static create(name: string): LogMethods {
        return {
            create: Logger.create,
            trace: (message: string, ...optionalParams: unknown[]) => {
                Logger.trace(`[${name}] ${message}`, ...optionalParams);
            },
            debug: (message: string, ...optionalParams: unknown[]) => {
                Logger.debug(`[${name}] ${message}`, ...optionalParams);
            },
            log: (message: string, ...optionalParams: unknown[]) => {
                Logger.log(`[${name}] ${message}`, ...optionalParams);
            },
            warn: (message: string, ...optionalParams: unknown[]) => {
                Logger.warn(`[${name}] ${message}`, ...optionalParams);
            },
            error: (message: string, ...optionalParams: unknown[]) => {
                Logger.error(`[${name}] ${message}`, ...optionalParams);
            },
            getStoredMessages: Logger.getStoredMessages,
            init: () => undefined,
            messages: undefined,
            prototype: undefined
        };
    }

    private static debouncedSaveMessage = (message: LogMessage) => {
        if (Logger.messageQueue.length === 0) {
            setTimeout(() => {
                const newMessages = [...Logger.messages, ...Logger.messageQueue];
                ls.set("logMessages", newMessages);
                Logger.messageQueue = [];
                Logger.messages = newMessages;
            }, debounceTime);
        }
        Logger.messageQueue.push(message);
    };

    /**
     * Retrieves the stored log messages from local storage.
     * @returns The stored log messages.
     */
    public static getStoredMessages(): LogMessage[] {
        return ls.get<LogMessage[]>("logMessages") || [];
    }

    public static init(): void {
        this.messages = this.getStoredMessages();

        if (isDev()) {
            console.log(`[Logger] Logger initializing. Found ${this.messages.length} old messages.`);
        }

        // Filter out messages that are older than 24 hours
        const now = Date.now();
        const messagesBefore = this.messages.length;
        this.messages = this.messages.filter(
            (m) => now - m.timestamp < HOURS_IN_DAY * MIN_IN_HOUR * SEC_IN_MIN * MS_IN_SEC
        );

        if (messagesBefore !== this.messages.length) {
            this.log(`[Logger] Cleaned up old messages. Current message count: ${this.messages.length}.`);
        }

        if (isDev()) {
            console.log(
                `[Logger] Logger initialized. Cleaned up old messages. Current message count: ${this.messages.length}.`
            );
        }
    }

    public static trace(message: string, ...optionalParams: unknown[]): void {
        this.debouncedSaveMessage({ message: `[TRACE] ${message}`, timestamp: Date.now(), optionalParams });

        // Return without logging if we're not in dev mode
        if (isDev()) {
            console.trace(message, optionalParams);
        }
    }

    public static debug(message: string, ...optionalParams: unknown[]): void {
        this.debouncedSaveMessage({ message: `[DEBUG] ${message}`, timestamp: Date.now(), optionalParams });

        // Return without logging if we're not in dev mode
        if (isDev()) {
            console.debug(message, optionalParams);
        }
    }

    /**
     * Logs a message to the console.
     * @param message The message to log.
     * @param optionalParams Optional parameters to log.
     */
    public static log(message: string, ...optionalParams: unknown[]): void {
        this.debouncedSaveMessage({ message, timestamp: Date.now(), optionalParams });

        // Return without logging if we're not in dev mode
        if (isDev()) {
            console.log(message, optionalParams);
        }
    }

    /**
     * Logs a warning to the console.
     * @param message The message to log.
     * @param optionalParams Optional parameters to log.
     */
    public static warn(message: string, ...optionalParams: unknown[]): void {
        this.debouncedSaveMessage({ message: `[WARN] ${message}`, timestamp: Date.now(), optionalParams });

        // Return without logging if we're not in dev mode
        if (isDev()) {
            console.warn(message, optionalParams);
        }
    }

    /**
     * Logs an error to the console.
     * @param message The message to log.
     * @param optionalParams Optional parameters to log.
     */
    public static error(message: string, ...optionalParams: unknown[]): void {
        this.debouncedSaveMessage({ message: `[ERROR] ${message}`, timestamp: Date.now(), optionalParams });

        // Return without logging if we're not in dev mode
        if (isDev()) {
            console.error(message, optionalParams);
        }
    }
}
