import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { Flex } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import * as React from "react";
import type { InvitationPageProps } from "../../InvitationPageProps";
import type { CardBookPageProps } from "../../cardBookWrapper";
import { useInvitationStyles, useStaticInvitationStyles } from "../Invitation.styles";
import { InvitationDate } from "../InvitationDate";
import { InvitationLocation } from "../InvitationLocation";
import { useInvitationStyleVariables } from "../PageColors";
import { PageNavigation } from "../PageNavigation";

const usePageStyles = makeStyles({
    hostNamesContainer: {
        marginTop: "5rem"
    },
    hostNames: {
        fontFamily: "Champignon",
        fontSize: tokens.fontSizeHero1000,
        textAlign: "center"
    }
});

export const InvitationMain = React.forwardRef(
    (props: CardBookPageProps<InvitationPageProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { weddingDate, firstHostName, secondHostName } = props.pageProps;

        const { t } = useTranslation("invitation");
        useStaticInvitationStyles();
        const pageClasses = usePageStyles();
        const invitationClasses = useInvitationStyles();
        const cardStyles = useInvitationStyleVariables();

        const upperCaseText = mergeClasses(
            invitationClasses.regularText,
            invitationClasses.upperCaseText,
            invitationClasses.wideLetterSpacing
        );

        return (
            <div ref={ref}>
                <Flex
                    useVertical
                    style={cardStyles.backgroundColor}
                    fill
                    hAlign="center"
                    vAlign="center"
                    space="between"
                    className={props.isOnePageLayout ? undefined : invitationClasses.rightSideFold}
                >
                    <Flex useVertical gap="gapLarge" className={pageClasses.hostNamesContainer}>
                        <div className={pageClasses.hostNames} style={cardStyles.foregroundColor}>
                            {firstHostName}
                        </div>
                        <div className={upperCaseText} style={cardStyles.foregroundColor}>
                            {t("hostNames.separator")}
                        </div>
                        <div className={pageClasses.hostNames} style={cardStyles.foregroundColor}>
                            {secondHostName}
                        </div>
                    </Flex>
                    <InvitationDate weddingDate={weddingDate} foregroundColor={cardStyles.foregroundColor} />
                    <InvitationLocation
                        weddingLocation={props.pageProps.weddingLocation}
                        foregroundColor={cardStyles.foregroundColor}
                    />
                    <PageNavigation
                        {...cardStyles}
                        nextPage={props.nextPage}
                        prevPage={props.prevPage}
                        showNextPageButton
                        nextPageButtonLabel={t("reply.button.label")}
                        showPreviousPageButton
                    />
                </Flex>
            </div>
        );
    }
);
InvitationMain.displayName = "InvitationMain";
