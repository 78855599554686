import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { BaseWedding, FullUser, User } from "@weddinggram/model";

import { isDefined } from "@weddinggram/model/utilities";
import type { UploadProgress } from "@weddinggram/service";
import { Logger } from "@weddinggram/telemetry-core";
import type { RootState } from "../store/store";
import { createAsyncThunkWithServiceFactory } from "../thunks";
import type { RequestStatus } from "../utilities/RequestStatus";

/**
 * The state of the user.
 */
export type UserState = {
    /**
     * The currently logged in user.
     */
    currentUser: FullUser | null;

    /**
     * The base64 encoded avatar of the current user.
     */
    currentUserAvatar: string | null;

    /**
     * The users that are currently loaded.
     * The key is the user id.
     */
    users: Record<string, User>;

    /**
     * The status of the retrieve user request.
     */
    retrieveStatus: RequestStatus;

    /**
     * The status of the retrieve connected users request.
     */
    retrieveConnectedUsersStatus: RequestStatus;

    /**
     * The status of the update user request.
     */
    updateStatus: RequestStatus;

    /**
     * The error that occurred during the last request.
     * The key is the email and the value is the error.
     */
    createUsersError: Record<string, unknown> | null;

    /**
     * The error that occurred during the last request.
     */
    error: unknown | null;
};

/**
 * The initial state of the user.
 */
const initialState: UserState = {
    currentUser: null,
    currentUserAvatar: null,
    users: {},
    retrieveStatus: "idle",
    retrieveConnectedUsersStatus: "idle",
    updateStatus: "idle",
    createUsersError: null,
    error: null
};

/**
 * Retrieves the currently logged in user.
 */
export const retrieveUser = createAsyncThunkWithServiceFactory("user/get/me", async (_, { extra, signal }) => {
    Logger.log("[userSlice] retrieveUser");
    return await extra.currentUserService.getMe(signal);
});

/**
 * Registers a (new) user coming from B2C.
 * This operation is idempotent meaning that it can be called multiple times for the same user.
 */
export const registerUser = createAsyncThunkWithServiceFactory("user/register", async (_, { extra, signal }) => {
    Logger.log("[userSlice] registerUser");
    return await extra.currentUserService.register(signal);
});

/**
 * Retrieves the guests for the specified wedding by retrieving the wedding.
 */
export const retrieveUsersForWedding = createAsyncThunkWithServiceFactory(
    "user/get/wedding",
    async (weddingId: string, { extra, signal }) => {
        Logger.log("[userSlice] retrieveUsersForWedding");
        const wedding = await extra.weddingService.getById(weddingId, signal);
        return wedding.guests;
    }
);

export const retrieveConnectedUsers = createAsyncThunkWithServiceFactory(
    "user/get/connected",
    async (_, { extra, signal }) => {
        Logger.log("[userSlice] retrieveConnectedUsers");
        return await extra.userService.getAllConnectedUsers(signal);
    }
);

/**
 * Retrieves the avatar of the specified user.
 */
export const retrieveUserAvatar = createAsyncThunkWithServiceFactory(
    "user/get/avatar",
    async (user: User, { extra, signal }) => {
        Logger.log("[userSlice] retrieveUserAvatar");
        if (!user.avatarThumbAddress) {
            return null;
        }
        return await extra.mediaService.getImage(user.avatarThumbAddress, signal);
    }
);

/**
 * Updates the bio of the current user.
 */
export const updateBio = createAsyncThunkWithServiceFactory(
    "user/update/bio",
    async (bio: string, { extra, signal }) => {
        Logger.log("[userSlice] updateBio");
        return await extra.currentUserService.updateBio(bio, signal);
    }
);

export const acceptTermsOfService = createAsyncThunkWithServiceFactory(
    "user/update/termsOfServiceAccepted",
    async (_, { extra, signal }) => {
        Logger.log("[userSlice] acceptTermsOfService");
        return await extra.currentUserService.acceptTermsOfService(signal);
    }
);

type UpdateUserPayload = {
    /**
     * The avatar file to upload.
     */
    avatar: File;

    /**
     * The callback to call when the upload progress changes.
     * @param e The upload progress event.
     * @returns void
     */
    onUploadProgress?: (e: UploadProgress) => void;
};
/**
 * Updates the avatar of the current user.
 */
export const updateAvatar = createAsyncThunkWithServiceFactory(
    "user/update/avatar",
    async (payload: UpdateUserPayload, { extra, signal }) => {
        return await extra.currentUserService.updateAvatar(payload.avatar, signal, payload.onUploadProgress);
    }
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        resetUpdateStatus: (state) => {
            Logger.log("[userSlice] resetUpdateStatus");
            state.updateStatus = "idle";
            state.error = null;
        },
        resetUserRetrieveStatus: (state) => {
            Logger.log("[userSlice] resetUserRetrieveStatus");
            state.retrieveStatus = "idle";
            state.error = null;
        },
        addWeddingToUser: (state, action: PayloadAction<BaseWedding>) => {
            Logger.log("[userSlice] addWeddingToUser", { weddingId: action.payload.id });
            if (!state.currentUser) {
                state.error = new Error("Cannot add wedding to user because currentUser is not defined.");
                return;
            }
            // Make sure the wedding is not already added
            if (state.currentUser.weddings.some((wedding) => wedding.id === action.payload.id)) {
                return;
            }
            state.currentUser.weddings.push(action.payload);
        },
        removeWeddingFromUser: (state, action: PayloadAction<{ id: string }>) => {
            Logger.log("[userSlice] removeWeddingFromUser", { weddingId: action.payload.id });
            if (!state.currentUser) {
                state.error = new Error("Cannot remove wedding from user because currentUser is not defined.");
                return;
            }
            state.currentUser.weddings = state.currentUser.weddings.filter(
                (wedding) => wedding.id !== action.payload.id
            );
        },
        updateWeddingNameInUser: (state, action: PayloadAction<{ id: string; newName: string }>) => {
            Logger.log("[userSlice] updateWeddingNameInUser", {
                weddingId: action.payload.id,
                newName: action.payload.newName
            });
            if (!state.currentUser) {
                state.error = new Error("Cannot update wedding name to user because currentUser is not defined.");
                return;
            }
            const weddingIndex = state.currentUser.weddings.findIndex((wedding) => wedding.id === action.payload.id);
            if (weddingIndex === -1) {
                const idsInUserWeddings = state.currentUser.weddings.map((wedding) => wedding.id);
                state.error = new Error(
                    `Cannot update wedding name to user because wedding (Id: ${
                        action.payload.id
                    }) is not present in user state. IDs in user weddings: ${idsInUserWeddings.join(", ")}`
                );
                return;
            }
            state.currentUser.weddings[weddingIndex] = {
                ...state.currentUser.weddings[weddingIndex],
                name: action.payload.newName
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // RetrieveUser
            .addCase(retrieveUser.pending, (state) => {
                Logger.log(`[userSlice] ${retrieveUser.pending.type}`);
                state.retrieveStatus = "loading";
                state.error = null;
            })
            .addCase(retrieveUser.fulfilled, (state, action) => {
                Logger.log(`[userSlice] ${retrieveUser.fulfilled.type}`);
                state.retrieveStatus = "succeeded";
                state.currentUser = action.payload;
            })
            .addCase(retrieveUser.rejected, (state, action) => {
                Logger.log(`[userSlice] ${retrieveUser.rejected.type}`);
                state.retrieveStatus = "failed";
                state.error = action.error;
            })

            // RegisterUser
            .addCase(registerUser.pending, (state) => {
                Logger.log(`[userSlice] ${registerUser.pending.type}`);
                state.retrieveStatus = "loading";
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                Logger.log(`[userSlice] ${registerUser.fulfilled.type}`);
                state.retrieveStatus = "succeeded";
                state.currentUser = action.payload;
            })
            .addCase(registerUser.rejected, (state, action) => {
                Logger.log(`[userSlice] ${registerUser.rejected.type}`);
                state.retrieveStatus = "failed";
                state.error = action.error;
            })

            // RetrieveUsersForWedding
            .addCase(retrieveUsersForWedding.pending, (state) => {
                Logger.log(`[userSlice] ${retrieveUsersForWedding.pending.type}`);
                state.retrieveConnectedUsersStatus = "loading";
                state.error = null;
            })
            .addCase(retrieveUsersForWedding.fulfilled, (state, action) => {
                Logger.log(`[userSlice] ${retrieveUsersForWedding.fulfilled.type}`);
                state.retrieveConnectedUsersStatus = "succeeded";
                state.users = action.payload.reduce((acc, user) => {
                    acc[user.id] = user;
                    return acc;
                }, state.users);
            })
            .addCase(retrieveUsersForWedding.rejected, (state, action) => {
                Logger.log(`[userSlice] ${retrieveUsersForWedding.rejected.type}`);
                state.retrieveConnectedUsersStatus = "failed";
                state.error = action.error;
            })

            // RetrieveConnectedUsers
            .addCase(retrieveConnectedUsers.pending, (state) => {
                Logger.log(`[userSlice] ${retrieveConnectedUsers.pending.type}`);
                state.retrieveConnectedUsersStatus = "loading";
                state.error = null;
            })
            .addCase(retrieveConnectedUsers.fulfilled, (state, action) => {
                Logger.log(`[userSlice] ${retrieveConnectedUsers.fulfilled.type}`);
                state.retrieveConnectedUsersStatus = "succeeded";
                state.users = action.payload.reduce((acc, user) => {
                    acc[user.id] = user;
                    return acc;
                }, state.users);
            })
            .addCase(retrieveConnectedUsers.rejected, (state, action) => {
                Logger.log(`[userSlice] ${retrieveConnectedUsers.rejected.type}`);
                state.retrieveConnectedUsersStatus = "failed";
                state.error = action.error;
            })

            // RetrieveUserAvatar
            .addCase(retrieveUserAvatar.pending, (state) => {
                Logger.log(`[userSlice] ${retrieveUserAvatar.pending.type}`);
                state.retrieveStatus = "loading";
                state.error = null;
            })
            .addCase(retrieveUserAvatar.fulfilled, (state, action) => {
                Logger.log(`[userSlice] ${retrieveUserAvatar.fulfilled.type}`);
                state.retrieveStatus = "succeeded";
                state.currentUserAvatar = action.payload;
            })
            .addCase(retrieveUserAvatar.rejected, (state, action) => {
                Logger.log(`[userSlice] ${retrieveUserAvatar.rejected.type}`);
                state.retrieveStatus = "failed";
                state.error = action.error;
            })

            // UpdateBio
            .addCase(updateBio.pending, (state) => {
                Logger.log(`[userSlice] ${updateBio.pending.type}`);
                state.updateStatus = "loading";
                state.error = null;
            })
            .addCase(updateBio.fulfilled, (state, action) => {
                Logger.log(`[userSlice] ${updateBio.fulfilled.type}`);
                state.updateStatus = "succeeded";
                state.currentUser = action.payload;
            })
            .addCase(updateBio.rejected, (state, action) => {
                Logger.log(`[userSlice] ${updateBio.rejected.type}`);
                state.updateStatus = "failed";
                state.error = action.error;
            })

            // UpdateAvatar
            .addCase(updateAvatar.pending, (state) => {
                Logger.log(`[userSlice] ${updateAvatar.pending.type}`);
                state.updateStatus = "loading";
                state.error = null;
            })
            .addCase(updateAvatar.fulfilled, (state, action) => {
                Logger.log(`[userSlice] ${updateAvatar.fulfilled.type}`);
                state.updateStatus = "succeeded";
                state.currentUser = action.payload;
            })
            .addCase(updateAvatar.rejected, (state, action) => {
                Logger.log(`[userSlice] ${updateAvatar.rejected.type}`);
                state.updateStatus = "failed";
                state.error = action.error;
            })

            // Accept ToS
            .addCase(acceptTermsOfService.pending, (state) => {
                Logger.log(`[userSlice] ${acceptTermsOfService.pending.type}`);
                state.updateStatus = "loading";
                state.error = null;
            })
            .addCase(acceptTermsOfService.fulfilled, (state, action) => {
                Logger.log(`[userSlice] ${acceptTermsOfService.fulfilled.type}`);
                state.updateStatus = "succeeded";
                state.currentUser = action.payload;
            })
            .addCase(acceptTermsOfService.rejected, (state, action) => {
                Logger.log(`[userSlice] ${acceptTermsOfService.rejected.type}`);
                state.updateStatus = "failed";
                state.error = action.error;
            });
    }
});

export const userReducer = userSlice.reducer;
export const {
    resetUpdateStatus,
    resetUserRetrieveStatus,
    addWeddingToUser,
    removeWeddingFromUser,
    updateWeddingNameInUser
} = userSlice.actions;

const EMPTY_ARRAY: [] = [] as const;

const NO_RESULT = undefined;

/**
 * Selects all users from the store.
 */
export const selectAllUsers = (state: RootState) => state.users.users;

/**
 * Selects the current user from the store.
 * @param state The current state of the store.
 * @returns The current user.
 */
export const selectCurrentUser = (state: RootState) => state.users.currentUser;

/**
 * Selects a user from the store by ID.
 * @param state The current state of the store.
 * @param userId The ID of the user to select.
 * @returns The user with the specified ID or `undefined` if not found.
 */
export const selectUserById = (userId: string | undefined) => (state: RootState) => {
    if (!userId) {
        return NO_RESULT;
    }
    return state.users.currentUser?.id === userId ? state.users.currentUser : state.users.users[userId];
};

/**
 * Selects the users with the specified IDs from the store.
 * @param userIds The IDs of the users to select.
 * @returns An array of users with the specified IDs.
 */
export const selectUsers = (userIds: string[]) =>
    createSelector([selectAllUsers, selectCurrentUser], (users, currentUser) => {
        return userIds.map((userId) => (currentUser?.id === userId ? currentUser : users[userId])).filter(isDefined);
    });

/**
 * Selects the avatar of the current user.
 * @param state The current state of the store
 * @returns The users current avatar.
 */
export const selectUserAvatar = (state: RootState) => state.users.currentUserAvatar;

/**
 * Returns true if the current user is defined in the store.
 * @param state The current state of the store.
 * @returns True if user is defined in store.
 */

export const selectUserRetrievalStatus = (state: RootState) => state.users.retrieveStatus;
export const selectConnectedUsersRetrievalStatus = (state: RootState) => state.users.retrieveConnectedUsersStatus;
export const selectUserUpdateStatus = (state: RootState) => state.users.updateStatus;
export const selectUserError = (state: RootState) => state.users.error;

/**
 * Selects the weddings that the current user is invited to or owns.
 */
export const selectUserWeddings = createSelector(
    selectCurrentUser,
    (currentUser) => currentUser?.weddings ?? EMPTY_ARRAY
);

/**
 * Selects the weddings that the current user owns.
 */
export const selectOwnWeddings = createSelector(selectCurrentUser, (currentUser) => {
    if (currentUser === null) {
        return EMPTY_ARRAY;
    }
    const currentUserId = currentUser.id;
    return currentUser.weddings.filter((wedding) => wedding.owners.some((ownerId) => ownerId === currentUserId));
});
