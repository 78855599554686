/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import { isDev } from "@weddinggram/common";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import type { HttpBackendOptions } from "i18next-http-backend";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export const defaultNS = "common" as const;
export const guestListNS = "guestList" as const;
export const invitationNS = "invitation" as const;
export const landingPageNS = "landingPage" as const;

/**
 * Type for the namespaces of the translations.
 * Can be used to restrict the usage of a function expecting a namespace.
 */
export type NameSpace = typeof defaultNS | typeof guestListNS | typeof invitationNS | typeof landingPageNS;

i18n.use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
        load: "languageOnly",
        ns: [defaultNS, guestListNS, invitationNS, landingPageNS],
        defaultNS,
        backend: {
            loadPath: "/assets/locales/{{lng}}/{{ns}}.json"
        },
        fallbackLng: "de",
        debug: isDev(),
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
