import { Spinner } from "@fluentui/react-components";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import type { IApplicationInsights, IConfig } from "@microsoft/applicationinsights-web";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import type { AppEnvironmentType } from "@weddinggram/common";
import { useTranslation } from "@weddinggram/i18n";
import { AppInsightsService, Logger } from "@weddinggram/telemetry-core";
import { createBrowserHistory } from "history";
import React, { createContext, useEffect, useState } from "react";

interface AppInsightsContextProps {
    appInsights: IApplicationInsights | null;
}

export const AppInsightsContext = createContext<AppInsightsContextProps>({
    appInsights: null
});

type AppInsightsProviderProps = {
    children: React.ReactNode;
    appInsightsApp: ApplicationInsights;
    appVersion: string;
    appEnvironment: AppEnvironmentType;
};

export const AppInsightsProvider: React.FC<AppInsightsProviderProps> = (props) => {
    const [appInsights, setAppInsights] = useState<AppInsightsContextProps>({ appInsights: props.appInsightsApp });
    const { t } = useTranslation("common");

    useEffect(() => {
        Logger.log("AppInsightsProvider: useEffect", { appInsightsAppDefined: Boolean(props.appInsightsApp) });
        let aiWrapper: AppInsightsService | null = null;
        try {
            aiWrapper = new AppInsightsService(props.appInsightsApp, props.appVersion, props.appEnvironment);
            setAppInsights({ appInsights: aiWrapper });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("AppInsightsProvider: useEffect: error", error);
            Logger.error("Could not initialize AppInsightsProvider: useEffect: error", error);
        }

        return () => {
            Logger.log("AppInsightsProvider: useEffect: cleanup");
            aiWrapper?.flush();
        };
    }, [props.appInsightsApp, props.appVersion, props.appEnvironment]);

    return (
        <AppInsightsContext.Provider value={appInsights}>
            {appInsights ? (
                props.children
            ) : (
                <Spinner title={t("loading.title")} label={t("loading.appInsights.label")} />
            )}
        </AppInsightsContext.Provider>
    );
};

const CONSOLE_LOGGING_OFF = 0;
const CONSOLE_LOGGING_ALL = 2;

export const buildAppInsights = (
    connectionString: string,
    disableTelemetry?: boolean,
    config?: IConfig
): ApplicationInsights => {
    const browserHistory = createBrowserHistory({ window: window });
    const isRunningInDevMode = !disableTelemetry;
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            connectionString,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            },
            enableAutoRouteTracking: true,
            disableTelemetry,
            loggingLevelConsole: isRunningInDevMode ? CONSOLE_LOGGING_ALL : CONSOLE_LOGGING_OFF,
            loggingLevelTelemetry: isRunningInDevMode ? CONSOLE_LOGGING_ALL : CONSOLE_LOGGING_OFF,
            enableDebug: isRunningInDevMode,
            // EnableDebugExceptions: isRunningInDevMode,
            ...config
        }
    });

    try {
        appInsights.loadAppInsights();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("buildAppInsights: error", error);
        Logger.error("Could not initialize AppInsightsProvider: error", error);
    }
    return appInsights;
};
