import { Text } from "@fluentui/react-components";
import { DEFAULT_FROM_NOW_THRESHOLD, HOURS_IN_DAY } from "@weddinggram/common";
import { useTranslation } from "@weddinggram/i18n";
import moment from "moment";
import * as React from "react";
import Moment from "react-moment";

export type DynamicTimeProps = {
    /**
     * The date and time to display
     */
    date: Date | string | null | undefined;

    /**
     * The threshold in hours to use "fromNow" instead of "format"
     * Default: 1 hour - (everything that is less than 1 hour away will be displayed as "in 30 mins")
     */
    fromNowThreshold?: number;

    /**
     * The mode to use for the date and time.
     * "dateTime" - display both date and time
     * "date" - display only the date
     * "time" - display only the time
     */
    mode: "dateTime" | "date" | "time";

    /**
     * Additional CSS class name to apply to the Text component.
     */
    className?: string;
};

export const DynamicDateTime: React.FC<DynamicTimeProps> = (props) => {
    const fromNowThreshold = props.fromNowThreshold ?? DEFAULT_FROM_NOW_THRESHOLD;
    const date = props.date === undefined ? null : moment(props.date);
    const {
        i18n: { language }
    } = useTranslation("common");

    if (date === null) {
        return null;
    }

    // Use "fromNow" if it is less than an hour away today
    const now = moment();
    const skipTodayCheck = fromNowThreshold >= HOURS_IN_DAY;
    const isSameDay = now.isSame(date, "day");
    const isToday = skipTodayCheck || isSameDay;
    const useFromNow = isToday && Math.abs(now.diff(date, "hours")) < fromNowThreshold;

    let format: string;
    let fromNowSuffix: React.ReactNode = null;
    switch (props.mode) {
        case "dateTime":
            format = "DD.MM.YYYY HH:mm";
            fromNowSuffix = isSameDay ? (
                <Moment locale={language} format=" (DD.MM.YYYY)">
                    {date}
                </Moment> // If it is today, show the date because it"ll be something like "in 30 mins"
            ) : (
                <Moment locale={language} format=" (HH:mm)">
                    {date}
                </Moment>
            ); // If it is not today, show the time because it"ll be something like "in 2 days"
            break;
        case "date":
            format = "DD.MM.YYYY";
            break;
        case "time":
            format = "HH:mm";
            break;
        default:
            throw new Error(`Unknown mode: ${props.mode}`);
    }

    return useFromNow ? (
        <Text className={props.className}>
            <Moment fromNow>{date}</Moment>
            {fromNowSuffix}
        </Text>
    ) : (
        <Text className={props.className}>
            <Moment format={format}>{date}</Moment>
        </Text>
    );
};
