import type { IExceptionTelemetry } from "@weddinggram/telemetry-core";
import { SeverityLevel } from "@weddinggram/telemetry-core";
import { v4 } from "uuid";
import { z } from "zod";

export const errorSchema = z.object({
    name: z.string(),
    message: z.string(),
    stack: z.string().optional()
});

export abstract class BaseException extends Error implements IExceptionTelemetry {
    public readonly id?: string;
    public readonly innerException?: Error;
    public readonly severityLevel: SeverityLevel | number;

    constructor(
        name: string,
        message: string,
        severity?: SeverityLevel,
        innerException?: unknown,
        id: string | undefined = undefined
    ) {
        super(message);
        this.name = name;
        this.severityLevel = severity ?? SeverityLevel.Error;
        this.id = id ?? v4();

        const innerExceptionError = errorSchema.safeParse(innerException);
        if (innerExceptionError.success) {
            this.innerException = innerExceptionError.data;
            this.message += `${innerExceptionError.data.name} ${innerExceptionError.data.message}`;
        }

        this.message = `${this.message} [Id: ${this.id}]`;
    }

    public serialize(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            message: this.message,
            stack: this.stack,
            severityLevel: this.severityLevel,
            innerExceptionMessage: this.innerException?.message ?? "null"
        };
    }
}
