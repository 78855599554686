/**
 * Rounds a number to a specified number of decimal places.
 * @param value The value to round.
 * @param precision The number of decimal places to round to.
 * @returns The rounded value.
 */
export const roundTo = (value: number, precision: number): number => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const multiplier = 10 ** (precision || 0);
    return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
};
