import { z } from "zod";
import { BaseEntitySchema } from "./BaseEntity";
import { ImageLikeSchema } from "./ImageLike";
import { MediaDefinitionSchema } from "./MediaDefinition";

export const ImagePostSchema = BaseEntitySchema.extend({
    /**
     * The media definitions for this image post.
     * These contain the actual urls to the images and videos.
     */
    mediaDefinitions: z.array(MediaDefinitionSchema),

    /**
     * The user defined description for this image post.
     */
    description: z.string().nullable(),

    /**
     * The user id of the user who created this image post.
     */
    userId: z.string().uuid(),

    /**
     * A list of likes for this image post.
     */
    imageLikes: z.array(ImageLikeSchema)
});
