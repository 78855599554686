import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const usePageStyles = makeStyles({
    hostNamesContainer: {
        marginTop: "3rem"
    },
    hostNames: {
        fontFamily: "Champignon",
        fontSize: tokens.fontSizeHero1000,
        textAlign: "center"
    },
    openInvitationButton: {
        ...shorthands.margin("4rem", 0),
        zIndex: 2
    },
    heartContainer: {
        position: "absolute"
    },
    headerTitleContainer: {
        marginTop: "4rem"
    },
    secondHostName: {
        marginTop: "1rem"
    }
});
