import { ToastFooter as FluentToastFooter, Link, useId } from "@fluentui/react-components";
import * as React from "react";

/**
 * A type describing an action for a toast.
 */
export type ToastAction = {
    /**
     * The label for the action. Displays as a link.
     */
    label: string;

    /**
     * Callback for when the action is clicked.
     * @returns void
     */
    onClick: () => void;
};

type ToastFooterProps = {
    /**
     * The actions to display in the footer.
     * If no actions are provided, the footer will not be rendered.
     */
    actions?: ToastAction[];
};

/**
 * A footer for a toast containing zero or more actions.
 * @param param0 Array of {@link ToastAction|actions} for the ToastFooter component
 * @returns A ToastFooter component
 */
export const ToastFooter: React.FC<ToastFooterProps> = ({ actions }) => {
    const toastActionKey = useId();

    if (!actions || actions.length === 0) {
        return null;
    }

    return (
        <FluentToastFooter>
            {actions.map((action, i) => (
                <Link key={`${toastActionKey}_${i}`} onClick={action.onClick}>
                    {action.label}
                </Link>
            ))}
        </FluentToastFooter>
    );
};
