import { errorSchema } from "../src/BaseException";

/**
 * Transforms an unknown error into an Error object.
 * If the error is already an Error object, it will be returned as is.
 * If the error is a string, it will be wrapped in an Error object.
 * If the error is an object, it will be stringified and wrapped in an Error object.
 * If the error is null or undefined, it will be wrapped in an Error object with the message "Unknown null error".
 * @param error The error to transform into an Error object.
 * @returns The error as an Error object.
 */
export const unknownToError = (error: unknown): Error => {
    if (!error) {
        return new Error("Unknown null error");
    }

    if (error instanceof Error) {
        return error;
    }

    const errorParseResult = errorSchema.safeParse(error);
    if (errorParseResult.success) {
        return error as Error;
    } else if (typeof error === "string") {
        return new Error(error);
    } else if (typeof error === "object") {
        const message = "message" in error ? String(error.message) : JSON.stringify(error);
        return new Error(message);
    }
    return new Error("Unknown error");
};
