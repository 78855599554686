import type { InputOnChangeData } from "@fluentui/react-components";
import {
    Button,
    Field,
    Input,
    fieldClassNames,
    inputClassNames,
    makeStyles,
    shorthands,
    tokens
} from "@fluentui/react-components";
import { MailRegular, PersonAddRegular, PersonRegular } from "@fluentui/react-icons";
import { Flex } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import { useAppInsights } from "@weddinggram/telemetry-react";
import * as React from "react";
import { useInvitationContext } from "../../InvitationContext";
import type { InvitationPageProps } from "../../InvitationPageProps";
import type { CardBookPageProps } from "../../cardBookWrapper";
import { useInvitationStyles } from "../Invitation.styles";
import { InvitationReplyUntil } from "../InvitationReplyUntil";
import {
    INVITATION_CSS_VARIABLE_ACCENT,
    INVITATION_CSS_VARIABLE_BACKGROUND,
    INVITATION_CSS_VARIABLE_FOREGROUND,
    INVITATION_CSS_VARIABLE_FOREGROUND_LIGHTER,
    INVITATION_CSS_VARIABLE_INVERTED_SUFFIX,
    useInvitationStyleVariables
} from "../PageColors";
import { PageNavigation } from "../PageNavigation";
import { InvitationGuestAvatarGroup } from "./InvitationGuestAvatarGroup";
import { isEmail } from "./isEmail";

const usePageStyles = makeStyles({
    openInvitationButton: {
        ...shorthands.margin("2rem", 0)
    },
    fieldLabels: {
        // Access to the label element inside the field
        [`& > .${fieldClassNames.label}`]: {
            fontSize: tokens.fontSizeBase400,
            fontWeight: 100,
            color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`
        },
        [`& > .${fieldClassNames.hint}`]: {
            color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`
        },
        [`& > .${fieldClassNames.validationMessage}`]: {
            color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`
        }
    },
    input: {
        [`:focus-within`]: {
            ["::after"]: {
                ...shorthands.borderBottom(tokens.strokeWidthThick, "solid", `var(${INVITATION_CSS_VARIABLE_ACCENT})`)
            }
        },
        [`& > .${inputClassNames.input}`]: {
            fontWeight: 200,
            color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`,
            ["::selection"]: {
                backgroundColor: `var(${INVITATION_CSS_VARIABLE_BACKGROUND})`,
                color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND})`
            }
        },
        borderBottomColor: `var(${INVITATION_CSS_VARIABLE_FOREGROUND_LIGHTER}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`
    },
    inputContainer: {
        ...shorthands.padding(0, "1rem"),
        marginBottom: "1rem",
        boxSizing: "border-box"
    },
    fieldIcons: {
        color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND_LIGHTER}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`
    }
});

export const InvitationGuestDetails = React.forwardRef(
    (props: CardBookPageProps<InvitationPageProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation("invitation");
        const { trackEvent } = useAppInsights();
        const pageClasses = usePageStyles();
        const invitationClasses = useInvitationStyles();
        const cardStyles = useInvitationStyleVariables(true);

        const { guestDetails, setGuestEmail, setGuestName } = useInvitationContext();
        const [activeGuestIndex, setActiveGuestIndex] = React.useState(0);

        const onNameChange = React.useCallback(
            (_: React.ChangeEvent, data: InputOnChangeData) => {
                setGuestName(activeGuestIndex, data.value);
            },
            [activeGuestIndex, setGuestName]
        );

        const onEmailChange = React.useCallback(
            (_: React.ChangeEvent, data: InputOnChangeData) => {
                setGuestEmail(activeGuestIndex, data.value);
            },
            [activeGuestIndex, setGuestEmail]
        );

        const onNewGuestClick = React.useCallback(() => {
            trackEvent("app/invite/addGuest", { inviteId: props.pageProps.id });
            setActiveGuestIndex((index) => index + 1);
        }, [trackEvent, props.pageProps.id]);

        // We can only add guests if we have a name for the current guest
        const cannotAddGuest = !guestDetails[activeGuestIndex]?.name;

        const nameLabel =
            activeGuestIndex === 0
                ? t("replyUntil.guestName.field.label")
                : t("replyUntil.guestName.plusOne.field.label");

        const emailLabel =
            activeGuestIndex === 0
                ? t("replyUntil.guestEmail.field.label")
                : t("replyUntil.guestEmail.plusOne.field.label");

        const selectedGuestMail = guestDetails[activeGuestIndex]?.email;
        const emailValidationStatus = selectedGuestMail && !isEmail(selectedGuestMail) ? "error" : undefined;
        const emailValidationMessage =
            emailValidationStatus === "error" ? t("replyUntil.guestEmail.validation.error") : "";

        const emailHint =
            emailValidationMessage.length > 0
                ? "" // Do not show hint if there is an error message
                : activeGuestIndex === 0
                  ? t("replyUntil.guestEmail.field.hint") // Show hint for first guest
                  : t("replyUntil.guestEmail.plusOne.field.hint"); // Show hint for additional guests

        return (
            <div ref={ref}>
                <Flex
                    useVertical
                    style={cardStyles.backgroundColor}
                    fill
                    hAlign="center"
                    vAlign="center"
                    space="between"
                    className={props.isOnePageLayout ? undefined : invitationClasses.leftSideFold}
                >
                    <InvitationReplyUntil {...props.pageProps} foregroundColor={cardStyles.foregroundColor} />

                    <InvitationGuestAvatarGroup
                        activeGuestIndex={activeGuestIndex}
                        setActiveGuestIndex={setActiveGuestIndex}
                        guestDetails={guestDetails}
                    />

                    <Flex useVertical gap="gapMedium" hFill className={pageClasses.inputContainer}>
                        <Field label={nameLabel} required className={pageClasses.fieldLabels}>
                            <Input
                                style={{ ...cardStyles.foregroundColor, ...cardStyles.backgroundColor }}
                                className={pageClasses.input}
                                contentBefore={<PersonRegular className={pageClasses.fieldIcons} />}
                                size="large"
                                appearance="underline"
                                onChange={onNameChange}
                                value={guestDetails[activeGuestIndex]?.name || ""}
                            />
                        </Field>

                        <Field
                            label={emailLabel}
                            hint={emailHint}
                            style={cardStyles.foregroundColor}
                            className={pageClasses.fieldLabels}
                            validationState={emailValidationStatus}
                            validationMessage={emailValidationMessage}
                        >
                            <Input
                                type="email"
                                style={{ ...cardStyles.foregroundColor, ...cardStyles.backgroundColor }}
                                className={pageClasses.input}
                                contentBefore={<MailRegular className={pageClasses.fieldIcons} />}
                                size="large"
                                appearance="underline"
                                onChange={onEmailChange}
                                value={guestDetails[activeGuestIndex]?.email || ""}
                            />
                        </Field>

                        <Button
                            size="large"
                            appearance="outline"
                            style={{ ...cardStyles.foregroundColor, ...cardStyles.accentBorderColor }}
                            onClick={onNewGuestClick}
                            disabled={cannotAddGuest}
                            icon={<PersonAddRegular style={cardStyles.accentColor} />}
                        >
                            {t("replyUntil.additionalGuests.button.label")}
                        </Button>
                    </Flex>

                    <PageNavigation
                        {...cardStyles}
                        nextPage={props.nextPage}
                        prevPage={props.prevPage}
                        nextPageButtonLabel={t("replyUntil.next.button.label")}
                        showPreviousPageButton
                        showNextPageButton={props.isOnePageLayout}
                    />
                </Flex>
            </div>
        );
    }
);
InvitationGuestDetails.displayName = "InvitationGuestDetails";
