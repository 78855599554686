/**
 * Checks if a promise is fulfilled and has a value
 * @param promise The promise to check
 * @returns True if the promise is fulfilled and has a value, false otherwise
 */
export const isSuccessPromiseWithValue = <T>(
    promise: PromiseSettledResult<T>
): promise is PromiseFulfilledResult<T> => {
    return promise.status === "fulfilled" && promise.value !== undefined;
};

export const isRejectedPromiseWithValue = <T>(promise: PromiseSettledResult<T>): promise is PromiseRejectedResult => {
    return promise.status === "rejected" && promise.reason !== undefined;
};
