import type { IApplicationInsights } from "@microsoft/applicationinsights-web";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { selectSelectedWedding, useSelector } from "@weddinggram/redux";
import type { EventNames, PerfEventNames, TrackTelemetryMethods } from "@weddinggram/telemetry-core";
import * as React from "react";
import { AppInsightsContext } from "./AppInsightsContextProvider";
import { useLogger } from "./useLogger";

export type AppInsightsHookReturn = TrackTelemetryMethods & {
    /**
     * The Application Insights instance.
     */
    appInsights: IApplicationInsights;
};

/**
 * React hook to get the Application Insights instance.
 * @returns The IApplicationInsights instance.
 */
export const useAppInsights = (): AppInsightsHookReturn => {
    const { appInsights } = React.useContext(AppInsightsContext);
    const selectedWeddingId = useSelector(selectSelectedWedding);
    const logger = useLogger("ai");

    if (!appInsights) {
        throw new Error(
            "Application Insights not found. Make sure you have wrapped your app with AppInsightsContextProvider."
        );
    }

    return React.useMemo(
        () => ({
            appInsights: appInsights,
            trackEvent: (name: EventNames, properties?: { [key: string]: unknown }) => {
                logger.debug("Event: " + name + " " + JSON.stringify(properties));
                appInsights?.trackEvent({
                    name,
                    properties: { weddingId: selectedWeddingId, ...properties }
                });
            },
            trackException: (
                exception: Error,
                severityLevel: SeverityLevel,
                properties?: { [key: string]: unknown }
            ) => {
                logger.error("Exception: " + exception + " " + JSON.stringify(properties));
                appInsights?.trackException({
                    properties,
                    severityLevel,
                    exception
                });
            },
            trackError: (
                origin: string,
                errorString: string,
                severityLevel: SeverityLevel = SeverityLevel.Error,
                properties?: { [key: string]: unknown }
            ) => {
                logger.error(`[${origin}] Error: ${errorString} ${JSON.stringify(properties)}`);
                const e = new Error(errorString);
                e.stack = origin;
                appInsights?.trackException({
                    properties,
                    severityLevel,
                    exception: new Error(errorString)
                });
            },
            trackPerformance: (name: PerfEventNames, value: number, properties?: { [key: string]: unknown }) =>
                appInsights?.trackMetric(
                    {
                        name,
                        average: value
                    },
                    { weddingId: selectedWeddingId, ...properties }
                )
        }),
        [appInsights, selectedWeddingId, logger]
    );
};
