import { isDev } from "@weddinggram/common";
import type { MediaType } from "..";

export const videoFileRegex =
    /.*\.(mp4|mkv|flv|vob|ogv|ogg|avi|mov|qt|wmv|m4p|m4v|mpg|mp2|mpeg|mpe|mpv|mpg|mpeg|m2v|3gp|3g2|rm|webm)(\/.*|\?.*|$)/i;
export const imageFileRegex = /.*\.(jpe?g|png|gif|bmp|webp|tiff?)(\/.*|\?.*|$)/i;
/**
 * Represents the media type of an {@link ImageDefinition}
 * Can be either "Image" or "Video" or "Unknown"
 */
type MediaTypeOrUnknown = MediaType | "Unknown";

/**
 *
 * @param url The url to check the media type for.
 * @returns The {@link MediaType} of the url or "Unknown" if it is not a video or image. Valid media types are "Image" and "Video" or "Unknown".
 */
export const getMediaType = (url: string | null | undefined): MediaTypeOrUnknown => {
    if (!url) {
        return "Unknown";
    }

    if (videoFileRegex.test(url)) {
        return "Video";
    }

    if (imageFileRegex.test(url)) {
        return "Image";
    }

    if (isDev() && url.startsWith(APP_ORIGIN)) {
        return "Image";
    }

    return "Unknown";
};
