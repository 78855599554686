import {
    NETWORK_EXCEPTION_NAME,
    USER_ALREADY_EXISTS_EXCEPTION_NAME,
    VALIDATION_EXCEPTION_NAME
} from "@weddinggram/exceptions";
import { unknownToError } from "@weddinggram/exceptions/utilities";

import type { UserAlreadyExistsException } from "@weddinggram/exceptions";
import i18n from "./i18n";

/**
 * Transforms an error into a string using the i18n package.
 * @param error Error to transform into a string.
 * @returns Object containing the error message and the stack trace as an array of strings.
 */
export const errorToString = (
    error: unknown
): { message: string; nativeMessage: string; stack: string[]; name: string } => {
    const errorObject = unknownToError(error);
    return {
        message: customizeErrorMessage(errorObject),
        nativeMessage: errorObject.message,
        stack: transformStackTraceIntoArray(errorObject.stack),
        name: errorObject.name
    };
};

const customizeErrorMessage = (error: Error): string => {
    if (error.name === NETWORK_EXCEPTION_NAME) {
        return i18n.t("error.known.network");
    }

    if (error.name === USER_ALREADY_EXISTS_EXCEPTION_NAME) {
        const userExistsError = error as UserAlreadyExistsException;
        return i18n.t("error.known.duplicateUser", { email: userExistsError.email });
    }

    if (error.name === VALIDATION_EXCEPTION_NAME) {
        return `${i18n.t("error.known.validation")} ${error.message}`;
    }

    return error.message;
};

const NAME_MATCH_INDEX = 1;
const BUNDLE_LINE_NUMBER_MATCH_INDEX = 3;
const BUNDLE_COLUMN_NUMBER_MATCH_INDEX = 4;

const transformStackTraceIntoArray = (stackTrace?: string) => {
    if (!stackTrace) {
        return [];
    }

    const regEx = /at\s+(.+)\s+\((.+):(\d+):(\d+)\)/g;
    const matches = stackTrace.matchAll(regEx);
    return Array.from(matches).map((match) => {
        const name = match[NAME_MATCH_INDEX];
        const bundleLineNumber = match[BUNDLE_LINE_NUMBER_MATCH_INDEX];
        const bundleColumnNumber = match[BUNDLE_COLUMN_NUMBER_MATCH_INDEX];
        return `${name} (${bundleLineNumber}:${bundleColumnNumber})`;
    });
};
