import { Logger } from "@weddinggram/telemetry-core";
import type { z } from "zod";
import { useQueryParam } from "./useQueryParam";

/**
 * Retrieves a value from a query parameter and decodes it using the provided schema.
 * @param name Name of the query parameter
 * @param schema The schema to use to decode the query parameter
 * @returns The decoded value of the query parameter or `null` if the query parameter is not present or invalid.
 */
export const useDecodedDataFromQuery = <T>(name: string, schema: z.Schema<T>) => {
    const rawData = useQueryParam(name);

    if (!rawData) {
        return null;
    }

    let jsonResult: unknown;
    try {
        jsonResult = JSON.parse(window.atob(rawData));
    } catch (error) {
        Logger.error("[useDecodedDataFromQuery] Failed to parse the query parameter", error, name);
        return null;
    }

    const result = schema.safeParse(jsonResult);
    if (result.success) {
        return result.data;
    }
    Logger.error("[useDecodedDataFromQuery] Failed to validate the query parameter", result.error, name, jsonResult);
    return null;
};
