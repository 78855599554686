import { createSlice } from "@reduxjs/toolkit";
import type { Invitation, InvitationCreationDto, InvitationUpdateDto } from "@weddinggram/model";
import { Logger } from "@weddinggram/telemetry-core";
import type { RootState } from "../store/store";
import { createAsyncThunkWithServiceFactory } from "../thunks";
import {
    IDLE_STATE,
    type RequestStatus,
    type RequestStatusForWedding,
    type UpdateRequestStatus
} from "../utilities/RequestStatus";

export type InvitationState = {
    /**
     * Indicates whether the invitation is currently being retrieved from the server
     */
    retrieveStatus: RequestStatusForWedding;

    /**
     * Indicates whether the invitation is currently being updated on the server.
     */
    updateStatus: UpdateRequestStatus;

    /**
     * Indicates whether the invitation is currently being created on the server.
     */
    createStatus: RequestStatus;

    /**
     * Error object that is set when an error occurs.
     */
    error: unknown | null;

    /**
     * A map of wedding id to the invitation that has been retrieved.
     */
    retrievedInvitation: Record<string, Invitation | null>;
};

const initialState: InvitationState = {
    retrieveStatus: {},
    updateStatus: {},
    createStatus: "idle",
    error: null,
    retrievedInvitation: {}
};

/**
 * Retrieves the invitation with the given id.
 */
export const retrieveInvitationByWeddingId = createAsyncThunkWithServiceFactory(
    "invitation/getByWeddingId",
    async (weddingId: string, { extra, signal }) => {
        return await extra.invitationService.getInvitation(weddingId, signal);
    }
);

type Create = {
    weddingId: string;
    invitation: InvitationCreationDto;
};

/**
 * Creates a new invitation for the given wedding.
 */
export const createInvitation = createAsyncThunkWithServiceFactory(
    "invitation/create",
    async (createData: Create, { extra, signal }) => {
        return await extra.invitationService.create(createData.weddingId, createData.invitation, signal);
    }
);

type Update = {
    weddingId: string;
    invitationId: string;
    invitation: InvitationUpdateDto;
};

/**
 * Updates an existing invitation for the given wedding.
 * @param weddingId The id of the wedding to update the invitation for.
 * @param invitation The update to apply to the invitation.
 */
export const updateInvitation = createAsyncThunkWithServiceFactory(
    "invitation/update",
    async (updateData: Update, { extra, signal }) => {
        return await extra.invitationService.update(
            updateData.weddingId,
            updateData.invitationId,
            updateData.invitation,
            signal
        );
    }
);

const invitationSlice = createSlice({
    name: "invitations",
    initialState,
    reducers: {
        resetInvitationCreateStatus: (state) => {
            state.createStatus = "idle";
            state.error = null;
        },
        resetInvitationRetrievalStatus: (state) => {
            state.retrieveStatus = {};
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // RetrieveInvitationByWeddingId
            .addCase(retrieveInvitationByWeddingId.pending, (state, action) => {
                Logger.log(
                    `[invitationSlice] ${retrieveInvitationByWeddingId.pending.type} for weddingId: ${action.meta.arg}`
                );
                state.retrieveStatus[action.meta.arg] = "loading";
            })
            .addCase(retrieveInvitationByWeddingId.fulfilled, (state, action) => {
                Logger.log(
                    `[invitationSlice] ${retrieveInvitationByWeddingId.fulfilled.type} for weddingId: ${
                        action.meta.arg
                    }. IsDefined: ${Boolean(action.payload)}`
                );
                state.retrieveStatus[action.meta.arg] = "succeeded";
                state.retrievedInvitation[action.meta.arg] = action.payload;
            })
            .addCase(retrieveInvitationByWeddingId.rejected, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "failed";
                state.error = action.error;
                Logger.error(`[invitationSlice] ${retrieveInvitationByWeddingId.rejected.type} failed`, action.error);
            })

            // CreateInvitation
            .addCase(createInvitation.pending, (state) => {
                state.createStatus = "loading";
            })
            .addCase(createInvitation.fulfilled, (state, action) => {
                state.createStatus = "succeeded";
                state.retrievedInvitation[action.payload.weddingId] = action.payload;
            })
            .addCase(createInvitation.rejected, (state, action) => {
                state.createStatus = "failed";
                state.error = action.error;
                Logger.error(`[invitationSlice]${createInvitation.rejected.type} failed`, action.error);
            })

            // UpdateInvitation
            .addCase(updateInvitation.pending, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "loading";
                state.error = null;
            })
            .addCase(updateInvitation.fulfilled, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "succeeded";
                state.retrievedInvitation[action.meta.arg.weddingId] = action.payload;
            })
            .addCase(updateInvitation.rejected, (state, action) => {
                state.updateStatus[action.meta.arg.weddingId] = "failed";
                state.error = action.error;
                Logger.error(`[invitationSlice] ${updateInvitation.rejected.type} failed`, action.error);
            });
    }
});

export const invitationsReducer = invitationSlice.reducer;
export const { resetInvitationRetrievalStatus, resetInvitationCreateStatus } = invitationSlice.actions;

const NO_RESULT = null;

/**
 * Selects the wedding invitation from the state or `null` if no invitation has been retrieved.
 * @param weddingId The id of the wedding to retrieve the invitation for.
 * @returns The wedding invitation that has been retrieved or `null` if no invitation has been retrieved.
 */
export const selectWeddingInvitation = (weddingId: string | undefined) => (state: RootState) => {
    if (weddingId) {
        return state.invitations.retrievedInvitation[weddingId];
    }
    return NO_RESULT;
};

/**
 * Selects the retrieval status of the invitation.
 * @param weddingId Id of the wedding to select the invitation retrieval status for.
 * @returns The status of the invitation retrieval.
 */
export const selectInvitationRetrievalStatus = (weddingId?: string) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.invitations.retrieveStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the create status of the invitation.
 * @param state The root state of the application
 * @returns The current invitation create status
 */
export const selectInvitationCreateStatus = (state: RootState) => state.invitations.createStatus;

/**
 * Selects the update status of the invitation.
 * @param weddingId The id of the wedding to select the invitation update status for.
 * @returns The status of the invitation update.
 */
export const selectInvitationUpdateStatus = (weddingId?: string) => (state: RootState) => {
    if (!weddingId) {
        return IDLE_STATE;
    }
    return state.invitations.updateStatus[weddingId] ?? IDLE_STATE;
};

/**
 * Selects the error state
 * @param state The root state of the application
 * @returns The error state
 */
export const selectInvitationError = (state: RootState) => state.invitations.error;
