import {
    INVITATION_QR_CODE_CHANGE_TOKEN_PARAMETER,
    INVITATION_QR_CODE_NAME_PARAMETER,
    INVITATION_QR_CODE_SCAN_PARAMETER,
    REDEMPTION_CODE_QUERY_PARAMETER
} from "./constants";

export enum RouteConstants {
    // LANDING PAGE ROUTES
    ABOUT = "about",
    HOW_IT_WORKS = "how-it-works",
    PRICING = "pricing",
    PRIVACY_POLICY = "privacy-policy",
    TERMS_OF_SERVICE = "terms-of-service",

    // APP ROUTES
    APP = "app",
    HOME = "home",
    GALLERY = "gallery",
    WEDDINGS = "weddings",
    WEDDINGS_NEW = "new",
    JOIN = "join",
    INVITE = "invite",
    QR_CODE = "q",
    INVITE_PREVIEW = "preview",
    USERS = "users",
    LOGIN = "login",
    LOGOUT = "logout",
    NEW_POST = "add",
    PROFILE = "profile",
    LOGS = "logs"
}

export const getWeddingCreatePath = () =>
    `/${RouteConstants.APP}/${RouteConstants.WEDDINGS}/${RouteConstants.WEDDINGS_NEW}`;
export const getWeddingDetailPath = (id: string, shouldEdit = false) =>
    `/${RouteConstants.APP}/${RouteConstants.WEDDINGS}/${id}?edit=${shouldEdit}`;
export const getUserProfilePath = (id: string) => `/${RouteConstants.APP}/${RouteConstants.USERS}/${id}`;

/**
 * Gets the path to join a wedding.
 * If both id and redemptionCode are defined, the user will be redirected to join the wedding directly.
 * If, either is `undefined`, the user will be redirected to the join page where they can use a QR code to join a wedding.
 * @param id Id of the wedding to join. If undefined, the user will be redirected to the join page where they can use a QR code to join a wedding.
 * @param redemptionCode The redemption code for the wedding. If undefined, the user will be redirected to the join page where they can use a QR code to join a wedding.
 * @returns The path to join a wedding.
 */
export const getWeddingJoinPath = (id: string | undefined, redemptionCode: string | undefined) => {
    if (id && redemptionCode) {
        return `/${RouteConstants.APP}/${RouteConstants.WEDDINGS}/${id}/${RouteConstants.JOIN}?${REDEMPTION_CODE_QUERY_PARAMETER}=${redemptionCode}`;
    }
    return `/${RouteConstants.APP}/${RouteConstants.WEDDINGS}/${RouteConstants.JOIN}`;
};

type InvitationPersonalizationData = {
    /**
     * The name of the guest.
     */
    displayName: string;

    /**
     * The unique identifier for the user.
     * The scan id to use for invitation tracking. Opening a link with a scan id will change the "lastOpened" field of the attendance.
     */
    scanId: string;

    /**
     * The token to change the attendance status.
     */
    attendanceChangeToken: string;

    /**
     * The QR access code. This is used to access the invitation via QR code and results in a much shorter URL making it easier to scan.
     * If the QR access code is not set, the invitation will be accessed via the full URL.
     */
    qrAccessCode: string | null;
};

/**
 * Gets the path to invite to a wedding.
 * @param id The id of the wedding to invite to.
 * @param redemptionCode The redemption code for the wedding to invite to.
 * @param personalization Optional personalization data for the invitation.
 * @returns The path to invite to a wedding.
 */
export const getWeddingInvitationPath = (
    id: string,
    redemptionCode: string,
    personalization?: InvitationPersonalizationData
) => {
    if (personalization?.qrAccessCode) {
        return `/${RouteConstants.QR_CODE}/${personalization.qrAccessCode}`;
    }
    return `/${RouteConstants.APP}/${RouteConstants.INVITE}/${id}?${REDEMPTION_CODE_QUERY_PARAMETER}=${redemptionCode}${getPersonalizationDataQsp(personalization)}`;
};

/**
 * Gets the query string parameters for the invitation QR code scan or empty string if scanId is undefined.
 */
const getPersonalizationDataQsp = (personalization?: InvitationPersonalizationData) =>
    personalization
        ? `&${INVITATION_QR_CODE_SCAN_PARAMETER}=${personalization.scanId}&${INVITATION_QR_CODE_NAME_PARAMETER}=${personalization.displayName}&${INVITATION_QR_CODE_CHANGE_TOKEN_PARAMETER}=${personalization.attendanceChangeToken}`
        : "";

export const getHomePath = () => `/${RouteConstants.APP}/${RouteConstants.HOME}`;

export const getAddPostPath = () => `/${RouteConstants.APP}/${RouteConstants.NEW_POST}`;

export const logsPath = `/${RouteConstants.APP}/${RouteConstants.LOGS}` as const;
