import { ValidationException } from "@weddinggram/exceptions";
import { Logger } from "@weddinggram/telemetry-core";
import * as ls from "local-storage";
import { z } from "zod";
import type { ICacheReadService } from "./ICacheReadService";
import type { ICacheWriteService } from "./ICacheWriteService";

const CacheItemSchema = z.object({
    expiry: z.number(),
    items: z.array(z.unknown())
});
type CacheItem = z.infer<typeof CacheItemSchema>;

const MS_IN_SEC = 1000;
const SEC_IN_MIN = 60;

/**
 * Service to store and retrieve items from the local storage cache.
 */
export class LocalStorageCacheService<TEntity> implements ICacheReadService<TEntity>, ICacheWriteService<TEntity> {
    /**
     *
     * @param keyPrefix Prefix to use for the local storage cache
     * @param expireAfterMins Time in minutes when the cache should expire
     */
    constructor(
        private readonly schema: z.Schema,
        private readonly keyPrefix: string,
        private readonly expireAfterMins: number
    ) {}

    /**
     * Store items in the cache
     * @param key key to store the cache value under
     * @param expiresOn Optional date when the cache should expire. If not provided, the default expiry time will be used. If false, the cache will never expire.
     * @param values Items to store in the cache
     */
    public set(key: string, expiresOn?: Date | false, ...values: TEntity[]): void {
        const cacheValue = {
            expiry: this.getExpiry(expiresOn),
            items: values
        };
        ls.set(this.getCacheKey(key), cacheValue);
    }

    /**
     * Stores a single item in the cache under the given key.
     * If the item is already part of the cache, it will be replaced.
     * If the item is not part of the cache, it will be added.
     * @param key The key to store the cache value under.
     * @param value The value to store in the cache.
     * @param expiresOn Optional date when the cache should expire. If not provided, the default expiry time will be used. If false, the cache will never expire.
     * @returns void
     */
    public setOne(key: string, value: TEntity, expiresOn?: Date | false): void {
        if (!value) {
            return;
        }

        const items = this.get(key);

        // Items is undefined -> first value.
        if (!items) {
            this.set(key, expiresOn, value);
            return;
        }

        // There are already items in the cache
        // Two cases
        // 1. The item is already part of the list -> replace it
        // 2. The item is not part of the list -> add it
        let index: number;

        if (LocalStorageCacheService.hasId(value)) {
            index = items.findIndex((item) => LocalStorageCacheService.hasId(item) && item.id === value.id);
        } else {
            index = items.findIndex((item) => item === value);
        }

        if (index >= 0) {
            items[index] = value;
        } else {
            items.push(value);
        }

        this.set(key, expiresOn, ...items);
    }

    private static hasId(value: unknown): value is { id: string } {
        // eslint-disable-next-line no-implicit-coercion
        return !!value && typeof value === "object" && "id" in value;
    }

    /**
     * Retrieve items from the cache
     * @param key key to retrieve the cache value for.
     * @returns The cached value if it exists and is not expired, otherwise undefined.
     */
    public get(key: string): TEntity[] | undefined {
        const cacheValue = ls.get<CacheItem>(this.getCacheKey(key));
        if (!cacheValue) {
            return undefined;
        }

        const cacheParseResult = CacheItemSchema.safeParse(cacheValue);
        if (cacheParseResult.success) {
            if (cacheValue.expiry > Date.now()) {
                // Validate the cache value against the schema
                const items = cacheValue.items.map((item) => {
                    const parseResult = this.schema.safeParse(item);
                    if (parseResult.success) {
                        return parseResult.data;
                    }
                    const error = new ValidationException(parseResult.error, this.keyPrefix);
                    Logger.warn(`Invalid cache found for item: ${error.message}`, error, item);
                    return undefined;
                });

                // If we have any invalid items, clear the key from the cache
                if (items.some((item) => !item)) {
                    this.remove(key);
                    return undefined;
                }

                return items;
            }
            this.remove(key);
        } else {
            const error = new ValidationException(cacheParseResult.error, this.keyPrefix);
            Logger.warn(`Invalid cache found: ${error.message}`, error, cacheValue);
            this.remove(key);
        }
        return undefined;
    }

    public getOne(key: string): TEntity | undefined {
        const cacheValue = this.get(key);
        if (cacheValue) {
            return cacheValue[0];
        }
        return undefined;
    }

    /**
     * Invalidate the cache
     */
    public invalidate(): void {
        ls.clear();
    }

    /**
     * Remove a key from the cache.
     * @param key key to remove from the cache.
     */
    public remove(key: string): void {
        ls.remove(this.getCacheKey(key));
    }

    /**
     * Returns the expiry time in milliseconds.
     * @param expiresOn Optional date when the cache should expire. If not provided, the default expiry time will be used. If false, the cache will never expire.
     * @returns The expiry time in milliseconds.
     */
    private getExpiry(expiresOn?: Date | false): number {
        if (expiresOn === false) {
            return Number.MAX_SAFE_INTEGER;
        }
        return expiresOn ? expiresOn.getTime() : Date.now() + this.expireAfterMins * MS_IN_SEC * SEC_IN_MIN;
    }

    /**
     * Returns the cache key for the given key.
     * @param key key to get the cache key for.
     * @returns The cache key for the given key.
     */
    private getCacheKey(key: string): string {
        return `${this.keyPrefix}_${key}`;
    }

    /**
     * Static method to clear the cache.
     */
    public static nukeCache() {
        ls.clear();
    }
}
