import type { DependencyList, EffectCallback } from "react";
import { useEffect, useRef } from "react";

/**
 * Executes a callback exactly once, on the first render.
 * @param callback The callback to be executed once.
 * @param deps The dependencies of the callback.
 */
export const useEffectOnce = (callback: EffectCallback, deps: DependencyList | undefined) => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            callback();
            isFirstRender.current = false;
        }
        // It's not possible to have a static list of dependencies here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
