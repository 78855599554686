import { z } from "zod";

const NonRecursiveApiErrorSchema = z.object({
    Id: z.string().uuid(),
    Message: z.string(),
    FullStackTrace: z.string().nullable(),
    SummarizedStackTrace: z.array(z.string()),
    SummarizedInnerStackTrace: z.array(z.string()),
    ErrorType: z.string(),
    StatusCode: z.number(),
    InnerExceptionMessage: z.string().nullable()
});

type RecursiveApiError = z.infer<typeof NonRecursiveApiErrorSchema> & {
    InnerErrorMessage: RecursiveApiError | null;
};

export const ApiErrorSchema: z.ZodType<RecursiveApiError> = NonRecursiveApiErrorSchema.extend({
    InnerErrorMessage: z.lazy(() => ApiErrorSchema).nullable()
});

/**
 * This is the schema for the error response from the API generated by the .NET framework.
 */
export const DotNetApiErrorSchema = z.object({
    type: z.string(),
    title: z.string(),
    status: z.number(),
    traceId: z.string(),
    errors: z.object({
        entityName: z.array(z.string())
    })
});
