import { z } from "zod";
import { BaseEntitySchema } from "./BaseEntity";

/**
 * Represents the base wedding model.
 */
export const BaseWeddingSchema = BaseEntitySchema.extend({
    /**
     * The name of the wedding.
     */
    name: z.string(),

    /**
     * The ids of the owners.
     */
    owners: z.array(z.string().uuid())
});

export const MinimalUserSchema = BaseEntitySchema.extend({
    /**
     * The first name of the user.
     */
    firstName: z.string(),

    /**
     * The last name of the user.
     */
    lastName: z.string(),

    /**
     * The email of the user.
     */
    email: z.string().email(),

    /**
     * The address to the avatar image thumb of the user.
     */
    avatarThumbAddress: z.string().url().optional().nullable(),

    /**
     * The address to the full avatar image of the user.
     */
    avatarAddress: z.string().url().optional().nullable(),

    /**
     * A short bio of the user.
     */
    bio: z.string().optional().nullable()
});

export const UserSchema = MinimalUserSchema.extend({
    /**
     * Indicates whether the user has accepted the terms of service.
     */
    hasAcceptedTerms: z.boolean(),

    /**
     * List of weddings the user is a part of.
     */
    weddings: z
        .array(BaseWeddingSchema)
        .optional()
        .transform((weddings) => weddings ?? [])
});
