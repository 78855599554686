import { mergeClasses } from "@fluentui/react-components";
import { AnimatedHeartImage, Flex } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import * as React from "react";
import type { InvitationPageProps } from "../../InvitationPageProps";
import type { CardBookPageProps } from "../../cardBookWrapper";
import { useInvitationStyles, useStaticInvitationStyles } from "../Invitation.styles";
import { INVITATION_CSS_VARIABLE_ACCENT, useInvitationStyleVariables } from "../PageColors";
import { PageNavigation } from "../PageNavigation";
import { usePageStyles } from "./InvitationCover.styles";
import { InvitationHeaderTitle } from "./InvitationHeaderTitle";

export const InvitationCover = React.forwardRef(
    (props: CardBookPageProps<InvitationPageProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { firstHostName, secondHostName } = props.pageProps;

        const { t } = useTranslation("invitation");
        useStaticInvitationStyles();
        const pageClasses = usePageStyles();
        const invitationClasses = useInvitationStyles();
        const cardStyles = useInvitationStyleVariables();

        const upperCaseText = mergeClasses(
            invitationClasses.regularText,
            invitationClasses.upperCaseText,
            invitationClasses.wideLetterSpacing
        );

        return (
            <div ref={ref}>
                <div className={pageClasses.heartContainer}>
                    <AnimatedHeartImage
                        width={400}
                        height={400}
                        color={`var(${INVITATION_CSS_VARIABLE_ACCENT})`}
                        opacity={0.4}
                        animationDuration={10}
                    />
                </div>
                <Flex
                    useVertical
                    style={cardStyles.backgroundColor}
                    className={props.isOnePageLayout ? undefined : invitationClasses.leftSideFold}
                    fill
                    hAlign="center"
                    vAlign="center"
                >
                    <div
                        style={cardStyles.foregroundColor}
                        className={mergeClasses(invitationClasses.regularText, pageClasses.headerTitleContainer)}
                    >
                        <InvitationHeaderTitle invitation={props.pageProps} />
                    </div>
                    <Flex useVertical gap="gapLarge" className={pageClasses.hostNamesContainer}>
                        <div className={pageClasses.hostNames} style={cardStyles.foregroundColor}>
                            {firstHostName}
                        </div>
                        <div className={upperCaseText} style={cardStyles.foregroundColor}>
                            {t("hostNames.separator")}
                        </div>
                        <div
                            className={mergeClasses(pageClasses.hostNames, pageClasses.secondHostName)}
                            style={cardStyles.foregroundColor}
                        >
                            {secondHostName}
                        </div>
                    </Flex>
                    <PageNavigation
                        className={pageClasses.openInvitationButton}
                        showNextPageButton
                        nextPage={props.nextPage}
                        hideNextButtonIcon
                        prevPage={props.prevPage}
                        nextPageButtonLabel={t("openFistPage.button.label")}
                        {...cardStyles}
                    />
                </Flex>
            </div>
        );
    }
);

InvitationCover.displayName = "InvitationCover";
