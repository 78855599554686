import { unknownToError } from "@weddinggram/exceptions/utilities";
import type { PerfEventNames } from "@weddinggram/telemetry-core";
import { Logger } from "@weddinggram/telemetry-core";
import { useAppInsightsPerformance } from "@weddinggram/telemetry-react";
import { useCallback, useEffect, useRef, useState } from "react";

/**
 * The type for the async function, which supports any return type.
 */
type AsyncFunction<T> = (abort: AbortSignal) => Promise<T>;

/**
 * The state interface representing the output of the useAsync hook.
 */
interface AsyncState<T> {
    data: T | null;
    isLoading: boolean;
    error: Error | null;
    abort: (reason: string) => void;
}

/**
 * UseAsync is a custom React hook that takes an asynchronous function and
 * returns the result once it's resolved. It handles the loading state and
 * any errors that might occur during the execution of the async function.
 *
 * @template T - The type of the data returned by the async function.
 * @param asyncFunction - The async function to execute.
 * @param onError - An optional callback function to handle any errors.
 * @param measureEventName - An optional event name to measure the performance of the async function. If not provided, performance will not be measured.
 * @returns An object containing the data, loading state, and any errors.
 *
 * @example
 * import useAsync from './useAsync';
 *
 * const fetchUsers = async () => {
 *   const response = await fetch('https://jsonplaceholder.typicode.com/users');
 *   return response.json();
 * };
 *
 * const UserList: React.FC = () => {
 *   const { data, isLoading, error } = useAsync(fetchUsers);
 *
 *   if (isLoading) return <div>Loading...</div>;
 *   if (error) return <div>Error: {error.message}</div>;
 *   if (!data) return null;
 *
 *   return (
 *     <ul>
 *       {data.map((user: any) => (
 *         <li key={user.id}>{user.name}</li>
 *       ))}
 *     </ul>
 *   );
 * };
 */
export const useAsync = <T>(
    asyncFunction: AsyncFunction<T>,
    onError?: (error: Error) => void,
    measureEventName?: PerfEventNames
): AsyncState<T> => {
    const [data, setData] = useState<T | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const abortController = useRef<AbortController | undefined>(undefined);
    const { start: startMeasurement } = useAppInsightsPerformance(measureEventName);

    const execute = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        abortController.current = new AbortController();
        try {
            const endMeasurement = measureEventName && startMeasurement();
            const result = await asyncFunction(abortController.current.signal);
            endMeasurement?.();
            setData(result);
        } catch (err) {
            const error = unknownToError(err);
            setError(error);
            onError?.(error);
        } finally {
            setIsLoading(false);
        }
    }, [asyncFunction, onError, measureEventName, startMeasurement]);

    const abort = useCallback((reason: string) => {
        Logger.log("aborting async operation", { reason });
        setIsLoading(false);
        setError(null);
        abortController.current?.abort(reason);
    }, []);

    useEffect(() => {
        execute();
    }, [execute]);

    return { data, isLoading, error, abort };
};
