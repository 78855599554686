import loadable from "@loadable/component";
export const CurrentUserAvatar = loadable(() => import("./CurrentUserAvatar"), {
    resolveComponent: (component) => component.CurrentUserAvatar
});

export const UserAvatarContainer = loadable(() => import("./UserAvatarContainer"), {
    resolveComponent: (component) => component.UserAvatarContainer
});

export const UserAvatarRenderer = loadable(() => import("./UserAvatarRenderer"), {
    resolveComponent: (component) => component.UserAvatarRenderer
});
