import type { ApiError } from "@weddinggram/model";
import { ApiException } from "./ApiException";

export const UNAUTHORIZED_EXCEPTION_NAME = "UnauthorizedException";

export class UnauthorizedException extends ApiException {
    constructor(apiError: ApiError, message: string, innerException?: unknown, properties?: Record<string, unknown>) {
        super(apiError, message, innerException, properties);
        this.name = UNAUTHORIZED_EXCEPTION_NAME;
    }
}
