import { z } from "zod";
import { BaseEntitySchema } from "./BaseEntity";

export const GuestTagSchema = BaseEntitySchema.extend({
    /**
     * The content of the tag.
     */
    tag: z.string().describe("The tag that the user has created."),

    /**
     * Whether the tag is private or not.
     */
    isPrivate: z
        .boolean()
        .describe("Whether the tag is private or not. Private tags are only visible to the user that created them."),

    /**
     * The (optional) icon that represents the tag.
     */
    icon: z.string().nullable().optional().describe("The icon that represents the tag."),

    /**
     * The (optional) color that represents the tag.
     */
    color: z.string().nullable().optional().describe("The color that represents the tag.")
});
