/* eslint-disable @typescript-eslint/no-magic-numbers */
export const BYTES_IN_KB = 1000 as const;
export const KB_IN_MB = 1000 as const;
export const FILE_SIZE_PRECISSION = 2 as const;
export const VIDEO_THUMB_JPEG_QUALITY = 0.9 as const;

export const MS_IN_SEC = 1000 as const;
export const SEC_IN_MIN = 60 as const;
export const MIN_IN_HOUR = 60 as const;
export const HOURS_IN_DAY = 24 as const;
export const DAYS_IN_WEEK = 7 as const;
export const DAYS_IN_MONTH = 30 as const;

export const CAP_BIO_AFTER_CHAR_COUNT = 200 as const;

export const CAP_BIO_AFTER_CHAR_COUNT_SMALL = 40 as const;

export const CAP_BIO_AFTER_CHAR_COUNT_MEDIUM = 100 as const;

export const CAP_BIO_AFTER_CHAR_COUNT_LARGE = 150 as const;

export const CAP_BIO_AFTER_CHAR_COUNT_EXTRA_LARGE = 300 as const;

export const DEFAULT_FROM_NOW_THRESHOLD = 1 as const;

/**
 * Query part of the join wedding path.
 */
export const REDEMPTION_CODE_QUERY_PARAMETER = "redemptionCode" as const;

/**
 * Query part of the invitation URL for the QR code which is printed on the invitation.
 * This is used to identify if the QR code on the invitation was scanned.
 */
export const INVITATION_QR_CODE_SCAN_PARAMETER = "sc" as const;

/**
 * Query part of the invitation URL for the QR code which is printed on the invitation.
 * This is used to prefill the name of the guest.
 */
export const INVITATION_QR_CODE_NAME_PARAMETER = "n" as const;

/**
 * Query part of the invitation URL for the QR code which is printed on the invitation.
 * This is used to supply the change token for the attendance.
 */
export const INVITATION_QR_CODE_CHANGE_TOKEN_PARAMETER = "t" as const;

/**
 * Minimum amount of characters that a search query has to be
 */
export const MINIMUM_SEARCH_QUERY_LENGTH = 3 as const;

/**
 * Default debounce time for the people picker
 */
export const DEFAULT_PEOPLE_PICKER_DEBOUNCE_TIME = 300 as const;

/**
 * Minimum length of the input title.
 */
export const MINIMUM_TITLE_LENGTH = 3 as const;

/**
 * The maximum length of the event in the event selector in characters.
 */
export const MAXIMUM_TITLE_LENGTH_IN_SELECTOR = 20 as const;

/**
 * Time in milliseconds until the toast is automatically dismissed.
 */
export const DEFAULT_TOAST_DURATION = 20000 as const;

/**
 * Maximum number of toasts that can be displayed at the same time.
 */
export const DEFAULT_TOAST_LIMIT = 3 as const;

/**
 * How many likes can be displayed before the overflow is shown.
 */
export const LIKE_OVERFLOW_THRESHOLD = 2 as const;

/**
 * Default timeout for image retrieval in ms.
 */
export const IMAGE_RETRIEVAL_TIMEOUT = 15000 as const;

/**
 * Precision of the performance duration in the telemetry.
 */
export const PERFORMANCE_DURATION_PRECISION = 2 as const;

/**
 * After how many performance events should the telemetry be flushed.
 */
export const PERFORMANCE_FLUSH_THRESHOLD = 10 as const;

/**
 * Default blur animation duration in seconds.
 */
export const DEFAULT_BLUR_ANIMATION_DURATION = 0.5 as const;

/**
 * The maximum number of images that can be added in a post.
 */
export const MAX_NUMBER_OF_IMAGES_PER_POST = 20 as const;

/**
 * Size in pixels of the default small avatar.
 */
export const DEFAULT_SMALL_AVATAR_SIZE = 32 as const;

/**
 * Viewport width in pixels for the small screen size.
 */
export const SMALL_SCREEN_SIZE = 576 as const;

/**
 * Viewport width in pixels for the medium screen size.
 */
export const MEDIUM_SCREEN_SIZE = 768 as const;

/**
 * Viewport width in pixels for the large screen size.
 */
export const LARGE_SCREEN_SIZE = 992 as const;

/**
 * Viewport width in pixels for the extra large screen size.
 */
export const EXTRA_LARGE_SCREEN_SIZE = 1200 as const;

/**
 * Default number of screenshots per slider for larger screens.
 */
export const DEFAULT_NUMBER_OF_SCREENSHOTS_PER_SLIDER_LARGE = 3 as const;

/**
 * The number of characters that are visible for the file name in the ThumbnailImage component.
 */
export const NUMBER_OF_CHARS_VISIBLE_FOR_FILE_NAME = 15 as const;

/**
 * The number of characters that are visible for the invite guest name.
 */
export const NUMBER_OF_CHARS_VISIBLE_FOR_INVITE_GUEST_NAME = 10 as const;

/**
 * The default animation duration for the card book.
 */
export const CARD_BOOK_DEFAULT_TRANSITION_DURATION = 1000 as const;

/**
 * When should guests be rendered in the popover instead of the avatar group.
 */
export const INVITATION_AVATAR_GROUP_POPOVER = 2 as const;

/**
 * Time in milliseconds after component mount before spinner is visible.
 */
export const DEFAULT_SPINNER_DELAY = 500 as const;

/**
 * Represents empty UUID.
 */
export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000" as const;

export const AVATAR_SIZE_LARGE = 64 as const;

export const AVATAR_SIZE_MEDIUM = 48 as const;

export const AVATAR_SIZE_SMALL = 32 as const;

/**
 * CSS setting when the content is fully transparent (not visible).
 */
export const OPACITY_TRANSPARENT = 0;

/**
 * CSS setting when the content is fully opaque (visible).
 */
export const OPACITY_OPAQUE = 1;

/**
 * The placeholder value that is used to personalize the invitation with the first name of the guest
 */
export const INVITATION_FIRST_NAME_PLACEHOLDER = "first_name";

/**
 * The placeholder value that is used to personalize the invitation with the last name of the guest
 */
export const INVITATION_LAST_NAME_PLACEHOLDER = "last_name";

/**
 * The regular expression to match the placeholders in the editor.
 * Matches any text that is between two curly braces.
 * E.g. {{first_name}}
 */
export const EDITOR_PLACEHOLDER_REGEX = /\{\{(\w+)\}\}/g;
