import { SeverityLevel } from "@weddinggram/telemetry-core";
import { BaseException } from "./BaseException";

export const CANCELLED_EXCEPTION_NAME = "CancelledException";

export class CancelledException extends BaseException {
    constructor(cancelMessage: string | undefined) {
        super(CANCELLED_EXCEPTION_NAME, "Operation cancelled. Reason: " + cancelMessage, SeverityLevel.Information);
    }
}
