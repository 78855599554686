/**
 * Describes a language the user can pick from a list of languages.
 */
export type Language = {
    /**
     * The emoji flag of the language.
     */
    flag: string;

    /**
     * The (localized) name of the language.
     * @example "English"
     * @example "Deutsch"
     */
    name: string;

    /**
     * The two-letter code of the language.
     */
    code: string;
};

/**
 * The list of available languages the user can pick from.
 */
export const availableLanguages: Language[] = [
    { code: "de", flag: "🇩🇪", name: "Deutsch" },
    { code: "en", flag: "🇬🇧", name: "English" },
    { code: "es", flag: "🇪🇸", name: "Español" },
    { code: "fr", flag: "🇫🇷", name: "Français" },
    { code: "it", flag: "🇮🇹", name: "Italiano" },
    { code: "nl", flag: "🇳🇱", name: "Nederlands" },
    { code: "pl", flag: "🇵🇱", name: "Polski" },
    { code: "pt", flag: "🇵🇹", name: "Português" },
    { code: "ru", flag: "🇷🇺", name: "Русский" },
    { code: "tr", flag: "🇹🇷", name: "Türkçe" },
    { code: "zh", flag: "🇨🇳", name: "中文" },
    { code: "ja", flag: "🇯🇵", name: "日本語" },
    { code: "ko", flag: "🇰🇷", name: "한국어" },
    { code: "ar", flag: "🇸🇦", name: "العربية" },
    { code: "hi", flag: "🇮🇳", name: "हिन्दी" },
    { code: "id", flag: "🇮🇩", name: "Bahasa Indonesia" },
    { code: "ms", flag: "🇲🇾", name: "Bahasa Melayu" },
    { code: "th", flag: "🇹🇭", name: "ภาษาไทย" },
    { code: "vi", flag: "🇻🇳", name: "Tiếng Việt" },
    { code: "cs", flag: "🇨🇿", name: "Čeština" },
    { code: "da", flag: "🇩🇰", name: "Dansk" },
    { code: "fi", flag: "🇫🇮", name: "Suomi" },
    { code: "el", flag: "🇬🇷", name: "Ελληνικά" },
    { code: "hu", flag: "🇭🇺", name: "Magyar" },
    { code: "no", flag: "🇳🇴", name: "Norsk" },
    { code: "ro", flag: "🇷🇴", name: "Română" },
    { code: "sv", flag: "🇸🇪", name: "Svenska" },
    { code: "uk", flag: "🇺🇦", name: "Українська" },
    { code: "bg", flag: "🇧🇬", name: "Български" },
    { code: "hr", flag: "🇭🇷", name: "Hrvatski" },
    { code: "sr", flag: "🇷🇸", name: "Српски" },
    { code: "sk", flag: "🇸🇰", name: "Slovenčina" },
    { code: "sl", flag: "🇸🇮", name: "Slovenščina" },
    { code: "et", flag: "🇪🇪", name: "Eesti" },
    { code: "lv", flag: "🇱🇻", name: "Latviešu" },
    { code: "lt", flag: "🇱🇹", name: "Lietuvių" },
    { code: "is", flag: "🇮🇸", name: "Íslenska" },
    { code: "mt", flag: "🇲🇹", name: "Malti" }
];

/**
 * A map of available languages by their two-letter code.
 */
export const availableLanguageMap: Record<string, Language> = availableLanguages.reduce(
    (acc, language) => {
        acc[language.code] = language;
        return acc;
    },
    {} as Record<string, Language>
);
