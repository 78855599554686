import moment, { ISO_8601 } from "moment";
import { z } from "zod";

export const DateTimeOffsetSchema = z
    .string()
    .refine(
        (value) => {
            return moment(value, ISO_8601, true).isValid();
        },
        {
            message: "Invalid date format. Expected format: 'YYYY-MM-DDTHH:mm:ss.SSSSSSS+HH:mm'"
        }
    )
    .transform((value) => {
        return moment(value).toISOString();
    });
