import type { TextareaOnChangeData } from "@fluentui/react-components";
import {
    Field,
    Textarea,
    fieldClassNames,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens
} from "@fluentui/react-components";
import { Flex, FlexBlur } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import * as React from "react";
import {
    INVITATION_CSS_VARIABLE_ACCENT,
    INVITATION_CSS_VARIABLE_ACCENT_LIGHTER,
    INVITATION_CSS_VARIABLE_BACKGROUND,
    INVITATION_CSS_VARIABLE_FOREGROUND,
    INVITATION_CSS_VARIABLE_FOREGROUND_LIGHTER,
    INVITATION_CSS_VARIABLE_INVERTED_SUFFIX,
    useInvitationStyleVariables
} from "../PageColors";

type InvitationCommentProps = {
    /**
     * Flag indicating whether the reply is being sent.
     */
    isLoading: boolean;

    /**
     * The number of guests that replying to the invitation.
     */
    numberOfGuests: number;

    /**
     * The comment of the guest.
     */
    comment: string | undefined;

    /**
     * Callback invoked when the user changes the comment.
     * @returns void
     */
    setGuestComment: (comment: string) => void;
};

const useStyles = makeStyles({
    commentsTextArea: {
        height: "5rem",
        color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`,
        ["::selection"]: {
            backgroundColor: `var(${INVITATION_CSS_VARIABLE_BACKGROUND})`,
            color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND})`
        },
        ["::placeholder"]: {
            color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND_LIGHTER}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`
        }
    },
    textAreaInput: {
        [":focus-within"]: {
            ["::after"]: {
                ...shorthands.borderBottom(tokens.strokeWidthThick, "solid", `var(${INVITATION_CSS_VARIABLE_ACCENT})`)
            }
        },
        [":hover"]: {
            borderTopColor: `var(${INVITATION_CSS_VARIABLE_ACCENT})`,
            borderLeftColor: `var(${INVITATION_CSS_VARIABLE_ACCENT})`,
            borderRightColor: `var(${INVITATION_CSS_VARIABLE_ACCENT})`
        },
        [":disabled"]: {
            color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`
        },

        fontWeight: 200,
        ...shorthands.borderColor(`var(${INVITATION_CSS_VARIABLE_ACCENT_LIGHTER})`)
    },
    inputContainer: {
        ...shorthands.padding(0, "1rem"),
        marginBottom: "1rem",
        boxSizing: "border-box"
    },
    fieldLabels: {
        borderTopColor: `var(${INVITATION_CSS_VARIABLE_ACCENT_LIGHTER})`,
        // Access to the label element inside the field
        [`& > .${fieldClassNames.label}`]: {
            fontSize: tokens.fontSizeBase400,
            fontWeight: 100,
            color: `var(${INVITATION_CSS_VARIABLE_FOREGROUND}${INVITATION_CSS_VARIABLE_INVERTED_SUFFIX})`
        }
    },
    container: {
        ...shorthands.margin(0, "1rem")
    }
});

/**
 * Renders the buttons to confirm or decline the invitation.
 * @param props props for the component.
 * @returns A component rendering two buttons to confirm or decline the invitation.
 */
export const InvitationComment: React.FC<InvitationCommentProps> = (props) => {
    const { setGuestComment, comment, numberOfGuests, isLoading } = props;

    const { t } = useTranslation("invitation");
    const cardStyles = useInvitationStyleVariables(true);
    const pageClasses = useStyles();

    const onCommentChange = React.useCallback(
        (_: unknown, data: TextareaOnChangeData) => {
            setGuestComment(data.value);
        },
        [setGuestComment]
    );

    return (
        <Flex useVertical hFill>
            {numberOfGuests > 0 ? (
                <FlexBlur animateIn useVertical hFill>
                    <Field
                        size="large"
                        label={t("confirm.comments.label")}
                        className={mergeClasses(pageClasses.fieldLabels, pageClasses.container)}
                    >
                        <Textarea
                            style={{ ...cardStyles.foregroundColor, ...cardStyles.backgroundColor }}
                            className={pageClasses.textAreaInput}
                            size="large"
                            disabled={isLoading}
                            placeholder={t("confirm.comments.placeholder")}
                            textarea={{ className: pageClasses.commentsTextArea }}
                            onChange={onCommentChange}
                            value={comment ?? ""}
                        />
                    </Field>
                </FlexBlur>
            ) : null}
        </Flex>
    );
};
