import loadable from "@loadable/component";

/**
 * A wrapper around the {@link Button} component that animates the icon when toggled.
 * When the button is clicked, the icon will rotate 180 degrees.
 * @param props Uses the same {@link ButtonProps|props} as the {@link Button} component but makes the {@link ButtonProps.icon|icon slot} mandatory.
 * @returns A button that animates the icon when toggled.
 */
export const AnimatedToggleButton = loadable(
    () => import(/* webpackChunkName: "AnimatedToggleButton" */ "./AnimatedToggleButton"),
    {
        resolveComponent: (component) => component.AnimatedToggleButton
    }
);
