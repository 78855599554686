import * as React from "react";
import { useLocalStorage } from "./useLocalStorage";

type CoachmarkInfo = {
    /**
     * Whether the coachmark is visible or not.
     */
    coachmarkVisible: boolean;

    /**
     * Dismisses the coachmark.
     * @returns {void} Dismisses the coachmark.
     */
    dismissCoachmark: () => void;

    /**
     * Handles the change event for the coachmark.
     * @param event The event that triggered the change.
     * @param data The data that was changed.
     * @returns {void} The change event.
     */
    onOpenChange: (event: unknown, data: { open: boolean }) => void;
};

/**
 * Hook to show or hide a coachmark.
 * @param coachmarkId The id of the coachmark to show or hide.
 * @returns Information about the coachmark. See {@link CoachmarkInfo}.
 */
export const useCoachmark = (coachmarkId: string): CoachmarkInfo => {
    const [coachmarkVisible, setCoachmarkVisible] = useLocalStorage(coachmarkId, true);

    const onOpenChange = React.useCallback(
        (_: unknown, data: { open: boolean }) => {
            if (!data.open) {
                setCoachmarkVisible(false);
            }
        },
        [setCoachmarkVisible]
    );

    return React.useMemo(() => {
        const dismissCoachmark = () => {
            setCoachmarkVisible(false);
        };
        return {
            coachmarkVisible,
            dismissCoachmark,
            onOpenChange
        };
    }, [setCoachmarkVisible, coachmarkVisible, onOpenChange]);
};
