import type { ApiError } from "@weddinggram/model";
import { ApiException } from "./ApiException";

export const BAD_REQUEST_EXCEPTION_NAME = "BadRequestException";

export class BadRequestException extends ApiException {
    constructor(apiError: ApiError, message: string, innerException?: unknown, properties?: Record<string, unknown>) {
        super(apiError, message, innerException, properties);
        this.name = BAD_REQUEST_EXCEPTION_NAME;
    }
}
