import type { FullUser } from "@weddinggram/model";
import {
    retrieveUser,
    selectCurrentUser,
    selectUserRetrievalStatus,
    useAppDispatch,
    useSelector
} from "@weddinggram/redux";
import * as React from "react";

/**
 * The return type of the useCurrentUser hook.
 */
type UseCurrentUserReturnType = {
    /**
     * The current user object or null.
     */
    currentUser: FullUser | null;

    /**
     * A boolean indicating whether the current user is being retrieved.
     */
    isLoading: boolean;
};

/**
 * A hook that returns the current user object and a loading status.
 * Retrieves the current user if it is not already in the store.
 * @returns {object} currentUser - The current user object
 */
export const useCurrentUser = (): UseCurrentUserReturnType => {
    const currentUser = useSelector(selectCurrentUser);
    const userRetrievalStatus = useSelector(selectUserRetrievalStatus);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (!currentUser && userRetrievalStatus === "idle") {
            dispatch(retrieveUser());
        }
    }, [dispatch, currentUser, userRetrievalStatus]);

    return {
        currentUser,
        isLoading: userRetrievalStatus === "loading"
    };
};
