import { createSlice } from "@reduxjs/toolkit";
import type { ImagePost, MediaDefinition } from "@weddinggram/model";
import type { DateOrderBy } from "@weddinggram/model/requests";
import type { UploadProgress } from "@weddinggram/service";
import type { RootState } from "../store/store";
import { createAsyncThunkWithServiceFactory } from "../thunks";
import { IDLE_STATE, type RequestStatus, type RequestStatusForWedding } from "../utilities/RequestStatus";

/**
 * The state of the image post slice.
 */
export type ImagePostState = {
    /**
     * The status of the posts retrieval.
     */
    retrieveStatus: RequestStatusForWedding;

    /**
     * The status of the posts creation.
     */
    createStatus: RequestStatus;

    /**
     * The status of the posts update.
     */
    updateStatus: RequestStatus;

    /**
     * The error if it exists.
     */
    error: unknown | null;

    /**
     * List of posts by wedding id
     */
    posts: Record<string, ImagePost[]>;

    /**
     * List of image definitions by wedding id
     */
    definitions: Record<string, MediaDefinition[]>;
};

const initialState: ImagePostState = {
    retrieveStatus: {},
    createStatus: "idle",
    updateStatus: "idle",
    error: null,
    posts: {},
    definitions: {}
};

export const retrievePostsByWeddingId = createAsyncThunkWithServiceFactory(
    "imagePost/get",
    async (weddingId: string, { extra, signal }) => {
        return await extra.imagePostService.getAll(weddingId, signal);
    }
);

/**
 * Retrieves the image definitions for the given wedding id.
 */
export const retrieveImageDefinitionsByWeddingId = createAsyncThunkWithServiceFactory(
    "imagePost/getDefinitions",
    async (retrieveOptions: { weddingId: string; sortOrder: DateOrderBy }, { extra, signal }) => {
        return await extra.imagePostService.getAllDefinitions(
            retrieveOptions.weddingId,
            retrieveOptions.sortOrder,
            signal
        );
    }
);

type CreateImagePostPayload = {
    /**
     * The id of the wedding to add the post to
     */
    weddingId: string;

    /**
     * The post to upload
     */
    images: File[];

    /**
     * The description to add to the post
     */
    description?: string;

    /**
     * The callback to call when the upload progress changes.
     * @param e The upload progress event.
     * @returns void
     */
    onUploadProgress?: (e: UploadProgress) => void;
};
export const createImagePost = createAsyncThunkWithServiceFactory(
    "imagePost/create",
    async (imagePost: CreateImagePostPayload, { extra, signal }) => {
        return await extra.imagePostService.create(
            imagePost.weddingId,
            imagePost.images,
            imagePost.description,
            signal,
            imagePost.onUploadProgress
        );
    }
);

type ModifyImagePostPayload = {
    postId: string;
    weddingId: string;
};
export const likeImagePost = createAsyncThunkWithServiceFactory(
    "imagePost/like",
    async (payload: ModifyImagePostPayload, { extra, signal }) => {
        return await extra.imageLikeService.like(payload.weddingId, payload.postId, signal);
    }
);

export const unlikeImagePost = createAsyncThunkWithServiceFactory(
    "imagePost/unlike",
    async (payload: ModifyImagePostPayload, { extra, signal }) => {
        await extra.imageLikeService.unlike(payload.weddingId, payload.postId, signal);
        return payload;
    }
);

type UpdateImagePostPayload = {
    postId: string;
    description: string;
    weddingId: string;
};
export const updateImagePostDescription = createAsyncThunkWithServiceFactory(
    "imagePost/update",
    async (update: UpdateImagePostPayload, { extra, signal }) => {
        return await extra.imagePostService.updateDescription(
            update.weddingId,
            update.postId,
            update.description,
            signal
        );
    }
);

export const deleteImagePost = createAsyncThunkWithServiceFactory(
    "imagePost/delete",
    async (payload: ModifyImagePostPayload, { extra, signal }) => {
        await extra.imagePostService.deletePost(payload.weddingId, payload.postId, signal);
        return payload;
    }
);

export const imagePostSlice = createSlice({
    name: "imagePost",
    initialState,
    reducers: {
        resetRetrieveState: (state) => {
            state.retrieveStatus = {};
            state.error = null;
        },
        resetUpdateState: (state) => {
            state.updateStatus = "idle";
            state.createStatus = "idle";
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // RetrievePostsByWeddingId
            .addCase(retrievePostsByWeddingId.pending, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "loading";
            })
            .addCase(retrievePostsByWeddingId.fulfilled, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "succeeded";
                if (action.payload.length > 0) {
                    state.posts[action.meta.arg] = action.payload;
                }
            })
            .addCase(retrievePostsByWeddingId.rejected, (state, action) => {
                state.retrieveStatus[action.meta.arg] = "failed";
                state.error = action.error;
            })

            // RetrieveImageDefinitionsByWeddingId
            .addCase(retrieveImageDefinitionsByWeddingId.pending, (state, action) => {
                state.retrieveStatus[action.meta.arg.weddingId] = "loading";
            })
            .addCase(retrieveImageDefinitionsByWeddingId.fulfilled, (state, action) => {
                state.retrieveStatus[action.meta.arg.weddingId] = "succeeded";
                if (action.payload.length > 0) {
                    state.definitions[action.meta.arg.weddingId] = action.payload;
                }
            })
            .addCase(retrieveImageDefinitionsByWeddingId.rejected, (state, action) => {
                state.retrieveStatus[action.meta.arg.weddingId] = "failed";
                state.error = action.error;
            })

            // CreateImagePost
            .addCase(createImagePost.pending, (state) => {
                state.createStatus = "loading";
            })
            .addCase(createImagePost.fulfilled, (state, action) => {
                state.createStatus = "succeeded";
                state.posts[action.meta.arg.weddingId] = [
                    action.payload,
                    ...(state.posts[action.meta.arg.weddingId] ?? [])
                ];
                state.definitions[action.meta.arg.weddingId] = [
                    ...action.payload.mediaDefinitions,
                    ...(state.definitions[action.meta.arg.weddingId] ?? [])
                ];
            })
            .addCase(createImagePost.rejected, (state, action) => {
                state.createStatus = "failed";
                state.error = action.error;
            })

            // LikeImagePost
            .addCase(likeImagePost.pending, (state) => {
                state.updateStatus = "loading";
            })
            .addCase(likeImagePost.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                const weddingId = action.meta.arg.weddingId;
                const postIndex = state.posts[weddingId].findIndex((post) => post.id === action.payload.imagePostId);
                state.posts[weddingId][postIndex].imageLikes.push(action.payload);
            })
            .addCase(likeImagePost.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
            })

            // UnlikeImagePost
            .addCase(unlikeImagePost.pending, (state) => {
                state.updateStatus = "loading";
            })
            .addCase(unlikeImagePost.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                const weddingId = action.meta.arg.weddingId;
                const postIndex = state.posts[weddingId].findIndex((post) => post.id === action.payload.postId);
                const likeIndex = state.posts[weddingId][postIndex].imageLikes.findIndex(
                    (like) => like.id === action.meta.arg.postId
                );
                state.posts[weddingId][postIndex].imageLikes.splice(likeIndex, 1);
            })
            .addCase(unlikeImagePost.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
            })

            // UpdateImagePostDescription
            .addCase(updateImagePostDescription.pending, (state) => {
                state.updateStatus = "loading";
            })
            .addCase(updateImagePostDescription.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                const weddingId = action.meta.arg.weddingId;
                const postIndex = state.posts[weddingId].findIndex((post) => post.id === action.meta.arg.postId);
                state.posts[weddingId][postIndex] = action.payload;
            })
            .addCase(updateImagePostDescription.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
            })

            // DeleteImagePost
            .addCase(deleteImagePost.pending, (state) => {
                state.updateStatus = "loading";
            })
            .addCase(deleteImagePost.fulfilled, (state, action) => {
                state.updateStatus = "succeeded";
                const weddingId = action.meta.arg.weddingId;
                const postIndex = state.posts[weddingId].findIndex((post) => post.id === action.meta.arg.postId);
                state.posts[weddingId].splice(postIndex, 1);
            })
            .addCase(deleteImagePost.rejected, (state, action) => {
                state.updateStatus = "failed";
                state.error = action.error;
            });
    }
});

export const postsReducer = imagePostSlice.reducer;
export const { resetRetrieveState, resetUpdateState } = imagePostSlice.actions;

/**
 * A stable empty array to return when there are no posts.
 */
const EMPTY_ARRAY: ImagePost[] = [] as const;

/**
 * Selects the posts for the given wedding id
 * @param weddingId The wedding id to retrieve posts for
 * @returns The posts for the given wedding id
 */
export const selectPostsForWedding = (weddingId: string) => (state: RootState) =>
    state.posts.posts[weddingId] || EMPTY_ARRAY;

/**
 * Selects the image definitions for the given wedding id
 * @param weddingId The wedding id to retrieve image definitions for
 * @returns The image definitions for the given wedding id
 */
export const selectImageDefinitionsForWedding = (weddingId: string) => (state: RootState) =>
    state.posts.definitions[weddingId] || EMPTY_ARRAY;

/**
 * Selects the status of the posts retrieval.
 * @param state The redux state.
 * @returns The status of the posts retrieval.
 */
export const selectPostsRetrieveStatus =
    (weddingId: string) =>
    (state: RootState): RequestStatus =>
        state.posts.retrieveStatus[weddingId] || IDLE_STATE;

/**
 * Selects the status of the posts creation.
 * @param state The redux state.
 * @returns The status of the posts creation.
 */
export const selectPostsCreateStatus = (state: RootState) => state.posts.createStatus;

/**
 * Selects the status of the posts update.
 * @param state The redux state.
 * @returns The status of the posts update.
 */
export const selectPostUpdateStatus = (state: RootState) => state.posts.updateStatus;

/**
 * Selects errors that appear during operations
 * @param state The redux state.
 * @returns The error if it exists.
 */
export const selectPostError = (state: RootState) => state.posts.error;
