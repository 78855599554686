import { makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { Flex } from "@weddinggram/common-ui";
import { useTranslation } from "@weddinggram/i18n";
import type { Invitation } from "@weddinggram/model";
import moment from "moment";
import * as React from "react";
import { useInvitationStyles } from "./Invitation.styles";
import type { InvitationStyles } from "./PageColors";

type InvitationReplyUntilProps = {
    /**
     * Until when the guests can reply to the invitation
     */
    replyUntil: Invitation["replyUntil"];

    /**
     * Wether the component should render as a "change reply until" component.
     * "If you change your mind, please contact us directly until..."
     */
    asChangeReplyUntil?: boolean;
} & Pick<InvitationStyles, "foregroundColor">;

const useStyles = makeStyles({
    container: {
        ...shorthands.margin("1rem")
    }
});
/**
 * A component that displays until when the guests can reply to the invitation
 * @param props Props for the InvitationReplyUntil component
 * @returns An InvitationReplyUntil component
 */
export const InvitationReplyUntil: React.FC<InvitationReplyUntilProps> = (props) => {
    const invitationClasses = useInvitationStyles();
    const classes = useStyles();
    const { t } = useTranslation("invitation");

    let replyUntilString: string;
    if (props.replyUntil === null) {
        replyUntilString = props.asChangeReplyUntil ? t("replyUntil.change.fallback") : t("replyUntil.fallback");
    } else {
        const replyUntilDate = moment(props.replyUntil);
        const translationKey = props.asChangeReplyUntil ? "replyUntil.change" : "replyUntil";
        replyUntilString = t(translationKey, {
            date: replyUntilDate.format("L")
        });
    }

    return (
        <Flex
            useVertical
            style={props.foregroundColor}
            gap="gapSmall"
            className={mergeClasses(invitationClasses.regularText, invitationClasses.italicText, classes.container)}
        >
            <div>{replyUntilString}</div>
            {props.asChangeReplyUntil ? null : <div>{t("replyUntil.subtitle")}</div>}
        </Flex>
    );
};
