import type { PerfEventNames } from "@weddinggram/telemetry-core";
import { useCallback } from "react";
import { useAppInsights } from "./useAppInsights";

interface PerformanceMeasurement {
    /**
     * Starts a performance measurement.
     * @returns A function that should be called when the performance measurement is complete.
     */
    start: () => () => void;
}

export const useAppInsightsPerformance = (
    eventName: PerfEventNames | undefined,
    eventProperties?: Record<string, unknown>
): PerformanceMeasurement => {
    const { trackPerformance } = useAppInsights();

    const start = useCallback(() => {
        if (!eventName) {
            throw new Error("Event name is required.");
        }

        const startTime = performance.now();

        return () => {
            const endTime = performance.now();
            const duration = endTime - startTime;

            trackPerformance(eventName, duration, eventProperties);
        };
    }, [eventName, eventProperties, trackPerformance]);

    return { start };
};
