import { z } from "zod";
import { BaseEntitySchema } from "./BaseEntity";
import { DateTimeOffsetSchema } from "./DateTimeOffset";

export const AgendaItemSchema = BaseEntitySchema.extend({
    name: z.string(),
    description: z.string().nullable().optional(),
    location: z.string().nullable().optional(),
    date: DateTimeOffsetSchema.nullable().optional()
});
