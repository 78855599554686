import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { FluentProvider, makeStyles } from "@fluentui/react-components";
import { ServiceFactoryProvider } from "@weddinggram/hooks";
import { buildStore } from "@weddinggram/redux";
import { ServiceFactory } from "@weddinggram/service-factory";
import { defaultScopes, msalConfig } from "@weddinggram/service/auth";
import { AppInsightsProvider, buildAppInsights } from "@weddinggram/telemetry-react";
import * as React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { AppRouter } from "./routing";

import { isDev } from "@weddinggram/common";
import { PageLoader } from "@weddinggram/common-ui";
import i18n from "@weddinggram/i18n/src/i18n";
import { Logger } from "@weddinggram/telemetry-core";
import { useTheme } from "@weddinggram/ui-theme";
import "./app.css";

const isDevMode = isDev();
Logger.init();
Logger.log(`[App] App is running in ${APP_ENV} mode`, { isDevMode });
const msalInstance = new PublicClientApplication(msalConfig);
const appInsights = buildAppInsights(AI_KEY, isDevMode);
const serviceFactory = new ServiceFactory(API_ORIGIN, defaultScopes, msalInstance, appInsights);
const store = buildStore(serviceFactory, isDevMode);

const useStyles = makeStyles({
    fluentProvider: {
        width: "100%"
    }
});

export const App: React.FC = () => {
    const { theme } = useTheme();
    const classes = useStyles();
    Logger.log("[App] App is rendering");
    return (
        <AppInsightsProvider appInsightsApp={appInsights} appEnvironment={APP_ENV} appVersion={APP_VERSION}>
            <MsalProvider instance={msalInstance}>
                <Provider store={store}>
                    <FluentProvider theme={theme} className={classes.fluentProvider}>
                        <ServiceFactoryProvider factory={serviceFactory}>
                            {i18n.isInitialized ? <RouterProvider router={AppRouter} /> : <PageLoader />}
                        </ServiceFactoryProvider>
                    </FluentProvider>
                </Provider>
            </MsalProvider>
        </AppInsightsProvider>
    );
};
