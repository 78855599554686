import { useAppInsights } from "@weddinggram/telemetry-react";
import * as React from "react";

/**
 * Hook to share content from the browser share menu.
 */
export const useShare = (urlFragment: string, title: string) => {
    const { appInsights, trackEvent } = useAppInsights();
    let url = window.location.origin;

    // Remove trailing slash
    if (url.endsWith("/")) {
        url = url.slice(0, -1);
    }

    url += urlFragment;

    return React.useMemo(() => {
        const share = async () => {
            if (navigator.share) {
                try {
                    await navigator.share({
                        url,
                        title
                    });
                    trackEvent("wedding/edit/invite/share", { url, title });
                } catch (error: unknown) {
                    appInsights.trackException({
                        exception: new Error("Could not share content"),
                        properties: { innerError: JSON.stringify(error) }
                    });
                }
            }
        };

        return {
            share,
            shareLink: url
        };
    }, [appInsights, trackEvent, title, url]);
};
