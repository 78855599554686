export { errorToString } from "@weddinggram/i18n/src/errorToString";
export { useErrorHandler } from "./src/useErrorHandler";

import loadable from "@loadable/component";

export const MsalErrorComponent = loadable(() => import("./src/MsalErrorComponent"), {
    resolveComponent: (components) => components.MsalErrorComponent
});

export const ErrorComponent = loadable(() => import("./src/ErrorComponent"), {
    resolveComponent: (components) => components.ErrorComponent
});

export const ErrorBoundary = loadable(() => import("./src/ErrorBoundary"), {
    resolveComponent: (components) => components.ErrorBoundary
});
