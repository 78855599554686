import { unknownToError } from "@weddinggram/exceptions/utilities";
import { useTranslation } from "@weddinggram/i18n";
import { Logger } from "@weddinggram/telemetry-core";
import { locale } from "moment";
import * as React from "react";

/**
 * A React hook that asynchronously loads and sets the Moment.js locale based on the detected language using react-i18next.
 * When the language changes, it updates the Moment.js locale accordingly.
 *
 * @example
 * // In a functional component:
 * import { useMomentLocale } from './path-to-your-hook-file';
 *
 * function MyComponent() {
 *   useMomentLocale();
 *   // Your component logic and JSX here
 * }
 */
export const useMomentLocale = (): void => {
    const {
        i18n: { language }
    } = useTranslation();

    React.useEffect(() => {
        /**
         * Asynchronously loads the Moment.js locale file based on the provided language,
         * then updates the Moment.js locale with the imported locale data.
         */
        const loadAndSetLocale = async () => {
            // Convert xx-XX (e.g. en-US) to xx (e.g. en)
            const genericLanguage = language.split("-")[0];

            Logger.log(`Loading Moment.js locale for language ${genericLanguage}`);

            if (genericLanguage === "en") {
                locale("en");
            } else {
                try {
                    const momentLocale = await import(`moment/locale/${genericLanguage}`);
                    locale(genericLanguage, momentLocale);
                } catch (error) {
                    const errorObj = unknownToError(error);
                    Logger.error(`Failed to load Moment.js locale for language ${genericLanguage}`, errorObj);
                }
            }
        };
        loadAndSetLocale();
    }, [language]);
};
