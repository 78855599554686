import { Toast as FluentToast, Link, Text, ToastBody, ToastTitle, ToastTrigger } from "@fluentui/react-components";
import { useTranslation } from "@weddinggram/i18n";
import * as React from "react";
import type { ToastAction } from "./ToastFooter";
import { ToastFooter } from "./ToastFooter";

/**
 * A type describing either a React element or a string.
 */
export type ElementOrTextProp = TextProp | ElementProp;

/**
 * A type describing a React element.
 */
type ElementProp = {
    /**
     * The React element to display.
     */
    element: React.ReactNode;

    /**
     * To use `text` use {@link TextProp} instead.
     * The type is inferred from the {@link ElementOrTextProp.type} type.
     */
    text?: never;

    /**
     * The type of the prop.
     * @default "element"
     */
    type: "element";
};

/**
 * A type describing a string.
 */
type TextProp = {
    /**
     * Cannot use a React element with {@link TextProp}. To use a React element, use {@link ElementProp} instead.
     * The type is inferred from the {@link ElementOrTextProp.type} type.
     */
    element?: never;

    /**
     * The string to display.
     */
    text: string | null;

    /**
     * The type of the prop.
     * @default "text"
     */
    type: "text";
};

type ToastProps = {
    /**
     * The translated title for the toast.
     */
    title?: string;

    /**
     * The body for the toast.
     * Can be either a React element or a string.
     */
    body: ElementOrTextProp;

    /**
     * The actions to display in the footer.
     * If no actions are provided, the footer will not be rendered.
     */
    actions?: ToastAction[];
};

/**
 * A toast component.
 */
export const Toast: React.FC<ToastProps> = (props) => {
    const { t } = useTranslation("common");

    const body = props.body.type === "text" ? <Text>{props.body.text}</Text> : props.body.element;
    const title = props.title ? (
        <ToastTitle
            action={
                <ToastTrigger>
                    <Link>{t("label.dismiss")}</Link>
                </ToastTrigger>
            }
        >
            {props.title}
        </ToastTitle>
    ) : undefined;
    return (
        <FluentToast>
            {title}
            <ToastBody>{body}</ToastBody>
            <ToastFooter actions={props.actions} />
        </FluentToast>
    );
};
