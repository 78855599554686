/**
 * The status of a request.
 * Idle: The request has not been made.
 * Loading: The request is in progress.
 * Succeeded: The request has completed successfully.
 * Failed: The request has failed. See the error property for more information.
 */
export type RequestStatus = "idle" | "loading" | "succeeded" | "failed";

/**
 * A map of wedding ids to their status where the wedding id is the key.
 */
export type RequestStatusForWedding = Record<string, RequestStatus | undefined>;

/**
 * Default `undefined` state for a request.
 */
export const IDLE_STATE: RequestStatus = "idle" as const;

type RequestId = string;

/**
 * A map of request ids to their status where the request id is the key.
 */
export type UpdateRequestStatus = Record<RequestId, RequestStatus>;

/**
 * Checks if the result of a request is ready to be displayed.
 * Status can be `succeeded` or `failed`.
 * @param status The status of a request.
 * @returns True if the page is ready to be displayed, false otherwise.
 */
export const isReady = (status: RequestStatus) => status === "succeeded" || status === "failed";

/**
 * Checks if the result of a request is pending which means it is in progress or has not been made yet.
 * Status can be `loading` or `idle`.
 * @param status The status of a request.
 * @returns True if the request is pending, false otherwise.
 */
export const isPending = (status: RequestStatus) => status === "loading" || status === "idle";

/**
 * Checks if the result of a request is in progress.
 * Status can be `loading`.
 * @param status The status of a request.
 * @returns True if the request is in progress, false otherwise.
 */
export const isLoading = (status: RequestStatus) => status === "loading";

/**
 * Checks if a given {@link RequestStatus|state} are pending.
 * @param states A collection of states to check.
 * @returns True if all are idle, False otherwise.
 */
export const allIdle = (...states: RequestStatus[]) => {
    return states.every((s) => s === undefined || s === "idle");
};

/**
 * Checks if the result of a request has completed successfully.
 * @param status The status of a request.
 * @returns True if the request has completed successfully, false otherwise.
 */
export const isError = (status: RequestStatus) => status === "failed";

/**
 * Checks if any of the given {@link RequestStatus|states} are still in loading state.
 * @param states A collection of states to check.
 * @returns True if none are loading, False otherwise.
 */
export const allFinished = (...states: RequestStatus[]) => {
    // We are done if no request is still in loading state
    const stillPending = states.some((s) => s === "loading");
    return !stillPending;
};
