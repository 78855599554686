import { useEffect, useState } from "react";

const QUERY = "(prefers-reduced-motion: no-preference)" as const;

const getInitialState = () => !window.matchMedia(QUERY).matches;

/**
 * React hook that returns the user's preference for reduced motion.
 * @returns Whether the user prefers reduced motion
 */
export const usePrefersReducedMotion = () => {
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(getInitialState);
    useEffect(() => {
        const mediaQuery = window.matchMedia(QUERY);
        const listener = (event: MediaQueryListEvent) => {
            setPrefersReducedMotion(!event.matches);
        };

        mediaQuery.addEventListener("change", listener);

        return () => {
            mediaQuery.removeEventListener("change", listener);
        };
    }, []);
    return prefersReducedMotion;
};
