import { useServiceFactory } from "@weddinggram/hooks";
import type { AttendanceStatus } from "@weddinggram/model/schemas";
import { Logger } from "@weddinggram/telemetry-core";
import * as React from "react";

export interface GuestDetails {
    /**
     * The name of the guest.
     */
    name: string;

    /**
     * The email of the guest.
     */
    email?: string | null;

    /**
     * Wether the guest is attending.
     */
    status: AttendanceStatus;

    /**
     * Optional comment from the guest.
     */
    comment?: string;
}

interface InvitationContextProps {
    /**
     * The details of the guests.
     * Each guest is represented by an object with a name and an email.
     */
    guestDetails: GuestDetails[];

    /**
     * Wether the reply has been sent.
     */
    invitationReplySent: boolean;

    /**
     * Confirms that the invitation reply has been sent.
     * @returns void
     */
    setInvitationReplySent: () => void;

    /**
     * Sets the name of the guest at the given index.
     * @param index The index of the guest
     * @param name The name of the guest
     * @returns void
     */
    setGuestName: (index: number, name: string) => void;

    /**
     * Sets the email of the guest at the given index.
     * @param index The index of the guest
     * @param email The email of the guest
     * @returns void
     */
    setGuestEmail: (index: number, email: string) => void;

    /**
     * Sets the comment of the guests.
     * @param comment The comment of the guests
     */
    setGuestComment: (comment: string) => void;

    /**
     * Changes the attendance status of the guests.
     * @param attendanceStatus True if guests are attending, false otherwise
     * @returns void
     */
    setGuestAttendance: (attendanceStatus: AttendanceStatus) => void;

    /**
     * The id of the attendance.
     */
    attendanceId: string | null;

    /**
     * The token to change the attendance status.
     */
    changeToken: string | null;
}

export const InvitationContext = React.createContext<InvitationContextProps>({
    guestDetails: [],
    invitationReplySent: false,
    setGuestName: () => undefined,
    setGuestEmail: () => undefined,
    setGuestAttendance: () => undefined,
    setInvitationReplySent: () => undefined,
    setGuestComment: () => undefined,
    attendanceId: null,
    changeToken: null
});

type ContextProviderProps = {
    children: React.ReactNode;

    /**
     * The id of the wedding.
     */
    weddingId?: string;

    /**
     * Optional scan id to use for invitation tracking. Opening a link with a scan id will change the "lastOpened" field of the attendance.
     */
    scanId?: string | null;

    /**
     * Optional name to prefill the guest display name.
     */
    guestDisplayName?: string | null;

    /**
     * Optional token to use for changing the attendance status.
     */
    attendanceChangeToken?: string | null;
};

const getInitialGuestDetailsFromProps = (props: ContextProviderProps): GuestDetails[] => {
    if (!props.guestDisplayName) {
        return [];
    }
    return [{ name: props.guestDisplayName, email: null, status: "Unknown" }];
};

/**
 * A component that provides the {@link InvitationContext} to its children.
 * @param props Props for the InvitationContextProvider component
 * @returns An InvitationContextProvider component
 */
export const InvitationContextProvider: React.FC<ContextProviderProps> = (props) => {
    const serviceFactory = useServiceFactory();
    const [guestDetails, setGuestDetails] = React.useState<GuestDetails[]>(getInitialGuestDetailsFromProps(props));
    const [invitationReplySent, setInvitationReplySent] = React.useState(false);
    const { weddingId, attendanceChangeToken, scanId } = props;
    React.useEffect(() => {
        if (!weddingId) {
            return;
        }
        const cachedAttendanceReplies = serviceFactory.attendanceService.getCachedAttendance(weddingId);
        if (cachedAttendanceReplies.length === 0) {
            Logger.log("[InvitationContext] No cached attendance replies found for id " + weddingId);
            return;
        }
        Logger.log("[InvitationContext] Cached attendance replies found, setting invitation reply sent to true");
        setInvitationReplySent(true);
        const guestDetails: GuestDetails[] = cachedAttendanceReplies.map((reply) => ({
            name: reply.userDisplayName,
            email: reply.userEmail, // TODO: Remove this when the email is available in the attendance reply
            status: reply.status,
            comment: reply.comment ?? undefined
        }));
        setGuestDetails(guestDetails);
    }, [weddingId, serviceFactory.attendanceService]);

    const setGuestName = React.useCallback((index: number, name: string) => {
        setInvitationReplySent(false);
        setGuestDetails((guestDetails) => {
            const newGuestDetails = [...guestDetails];

            // If the name is empty, remove the guest
            if (!name) {
                newGuestDetails.splice(index, 1);
                return newGuestDetails;
            }

            if (index >= newGuestDetails.length) {
                newGuestDetails.push({ name, email: undefined, status: "Unknown" });
                return newGuestDetails;
            }

            newGuestDetails[index].name = name;
            return newGuestDetails;
        });
    }, []);

    const setGuestEmail = React.useCallback((index: number, email: string) => {
        setInvitationReplySent(false);
        setGuestDetails((guestDetails) => {
            const newGuestDetails = [...guestDetails];

            if (index >= newGuestDetails.length) {
                newGuestDetails.push({ name: "", email, status: "Unknown" });
                return newGuestDetails;
            }
            newGuestDetails[index].email = email;
            return newGuestDetails;
        });
    }, []);

    const setGuestComment = React.useCallback((comment: string) => {
        setInvitationReplySent(false);
        setGuestDetails((guestDetails) => {
            return guestDetails.map((g) => ({
                ...g,
                comment
            }));
        });
    }, []);

    const setGuestAttendance = React.useCallback((newStatus: AttendanceStatus) => {
        setGuestDetails((guestDetails) => {
            return guestDetails.map((g) => ({
                ...g,
                status: newStatus
            }));
        });
    }, []);

    const providerProps = React.useMemo(() => {
        const confirmInvitationReplySent = () => {
            setInvitationReplySent(true);
        };
        return {
            attendanceId: scanId ?? null,
            changeToken: attendanceChangeToken ?? null,
            guestDetails,
            setGuestEmail,
            setGuestName,
            setGuestComment,
            setGuestAttendance,
            invitationReplySent,
            setInvitationReplySent: confirmInvitationReplySent
        };
    }, [
        guestDetails,
        setGuestName,
        setGuestComment,
        setGuestEmail,
        setGuestAttendance,
        invitationReplySent,
        attendanceChangeToken,
        scanId
    ]);

    return <InvitationContext.Provider value={providerProps}>{props.children}</InvitationContext.Provider>;
};

/**
 * A hook that returns the {@link InvitationContext}.
 * @returns The {@link InvitationContext}
 */
export const useInvitationContext = (): InvitationContextProps => {
    const context = React.useContext(InvitationContext);

    if (!context) {
        throw new Error("useInvitationContext must be used within an InvitationContextProvider");
    }

    return context;
};
