import { getWeddingInvitationPath } from "@weddinggram/common";
import { useTranslation } from "@weddinggram/i18n";
import type { Invitation, Wedding } from "@weddinggram/model";
import {
    retrieveInvitationByWeddingId,
    retrieveWeddingById,
    selectInvitationError,
    selectInvitationRetrievalStatus,
    selectWedding,
    selectWeddingInvitation,
    selectWeddingRetrieveStatus,
    useAppDispatch,
    useSelector
} from "@weddinggram/redux";
import { useErrorHandler } from "@weddinggram/ui-error-handling";
import * as React from "react";

type InvitationPersonalization = {
    /**
     * The user's name
     */
    displayName: string;

    /**
     *  Optional scan id to use for invitation tracking. Opening a link with a scan id will change the "lastOpened" field of the attendance.
     */
    scanId: string;

    /**
     * Token to change the attendance status
     */
    attendanceChangeToken: string;

    /**
     * The QR access code. This is used to access the invitation via QR code and results in a much shorter URL making it easier to scan.
     * If the QR access code is not set, the invitation will be accessed via the full URL.
     */
    qrAccessCode: string | null;
};

const getInvitationLink = (
    wedding?: Wedding,
    invitation?: Invitation | null,
    personalization?: InvitationPersonalization
) => {
    let invitationLinkPath: string | null;
    if (personalization?.qrAccessCode) {
        invitationLinkPath = getWeddingInvitationPath("", "", personalization);
    } else {
        invitationLinkPath =
            wedding && invitation
                ? getWeddingInvitationPath(wedding.id, wedding.redemptionCode, personalization)
                : null;
    }
    return invitationLinkPath ? window.location.origin + invitationLinkPath : null;
};

/**
 * This hook is used to generate an invitation link for a wedding.
 * @param weddingId The id of the wedding for which the invitation link should be generated.
 * @param canEdit Flag indicating if the user can edit the invitation. If this is `false` we will not load the invitation and generate the link.
 * @param personalization Optional user personalization to use for the invitation link.
 * @param userDetails Optional user details to use for the invitation link.
 */
export const useInvitationLink = (
    weddingId: string | undefined | null,
    canEdit?: boolean,
    personalization?: InvitationPersonalization
) => {
    const invitationStatus = useSelector(selectInvitationRetrievalStatus(weddingId ?? undefined));
    const invitationError = useSelector(selectInvitationError);
    const invitation = useSelector(selectWeddingInvitation(weddingId ?? undefined));
    const wedding = useSelector(selectWedding(weddingId ?? null));
    const weddingStatus = useSelector(selectWeddingRetrieveStatus(weddingId));

    const dispatch = useAppDispatch();
    const reportError = useErrorHandler();
    const { t } = useTranslation("common");
    const shouldLoadInvitation = canEdit && weddingId && invitationStatus === "idle";
    const shouldLoadWedding = canEdit && weddingId && weddingStatus === "idle";

    const fetchInvitation = React.useCallback(() => {
        if (weddingId) {
            dispatch(retrieveInvitationByWeddingId(weddingId));
        }
    }, [dispatch, weddingId]);

    React.useEffect(() => {
        if (shouldLoadInvitation) {
            fetchInvitation();
        }
        if (shouldLoadWedding) {
            dispatch(retrieveWeddingById(weddingId));
        }
    }, [shouldLoadInvitation, dispatch, weddingId, fetchInvitation, shouldLoadWedding]);

    React.useEffect(() => {
        if (invitationError) {
            reportError(t("errorLoading"), invitationError, "error", undefined, fetchInvitation);
        }
    }, [invitationError, reportError, t, fetchInvitation]);

    const invitationLink = getInvitationLink(wedding, invitation, personalization);

    return React.useMemo(() => {
        return {
            invitationLink,
            invitation,
            wedding,
            isLoading: invitationStatus === "loading" || weddingStatus === "loading",
            hasInvitation: invitation !== null
        };
    }, [invitationLink, invitation, wedding, invitationStatus, weddingStatus]);
};
