import loadable from "@loadable/component";

/**
 * A skeleton that can be used to show loading states. This is a wrapper around the SkeletonItem component that utilized the {@link FlexProps} to allow for easy configuration of the skeleton layout
 * @param props The props for the component. This includes the number of lines to show, the height of the lines, the width of the lines, and the class names to apply
 * @returns A skeleton that can be used to show loading states
 */
export const Skeleton = loadable(() => import(/* webpackChunkName: "Skeleton" */ "./Skeleton"), {
    resolveComponent: (component) => component.Skeleton
});
