import { Logger } from "@weddinggram/telemetry-core";
import * as React from "react";

/**
 * Hook for creating a logger instance for a service.
 * @param serviceName The name of the service to use for logging. E.g. "AttendanceService"
 * @returns A logger instance for the given service
 */
export const useLogger = (serviceName: string) => {
    return React.useMemo(() => {
        return Logger.create(serviceName);
    }, [serviceName]);
};
